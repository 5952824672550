.apocalypse__icon,
.content-icon {
  font-size: 40px;
  display: inline-block;
  margin-right: 20px;
}

.apocalypse__icon.isoi-bullet-w,
.content-icon.isoi-bullet-w {
  width: 5px;
  height: 5px;
  background: #fff;
}

.apocalypse__icon.isoi-bullet-blue,
.content-icon.isoi-bullet-blue {
  width: 5px;
  height: 5px;
  background: #1476f2;
}

.h1,
h1 {
  font-size: 40px;
  line-height: 1.25;
}

.h2,
h2 {
  font-size: 30px;
}

.h2,
.h3,
h2,
h3 {
  line-height: 1.33;
}

.h3,
h3 {
  font-size: 15px;
}

p {
  font-size: 18px;
}

p.small-text {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.54;
}

.white-text {
  color: #fff;
}

.blue-color {
  color: #1476f2;
}

.gray-color {
  color: #555;
}

.h1 ~ p,
h1 ~ p {
  margin-top: 25px;
}

.h2 ~ p,
h2 ~ p {
  margin-top: 15px;
}

.h3 ~ p,
h3 ~ p {
  margin-top: 5px;
}

.about {
  background-color: #f2f2f2;
  padding: 70px 0;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.about ul {
  padding-left: 30px;
  list-style-type: none;
}

.about ul li {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 30px;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}

.about ul li:before {
  font-size: 12px;
  content: "â– ";
  color: #0072f6;
  position: relative;
  bottom: 3px;
  padding-right: 10px;
}

.about ul li:first-child {
  margin-top: 30px;
}

.about ul li:last-child {
  margin-bottom: 0;
}

.about hr {
  border: none;
  height: 30px;
  margin: 0;
}

.about h4 {
  margin: 15px 0;
}

.about h3 {
  margin-top: 15px;
  font-size: 24px;
}

.about a {
  color: #0072f6;
}

.about .box-left {
  max-width: 25%;
  display: none;
}

.about .box-left,
.about .box-right {
  float: left;
  width: 100%;
}

@media (max-width: 767px) {
  .about .box-left,
  .about .box-right {
    float: none;
    max-width: 100%;
  }

  .about .box-right {
    padding: 0;
  }
}

.about__title {
  line-height: 34px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 28px;
}

.about__title span {
  color: #0072f6;
}

.about__testimonial {
  font-size: 14px;
}

@media (max-width: 767px) {
  .about__testimonial {
    font-size: inherit;
  }
}

.about-img {
  margin: 70px 0;
}

.about-img__wrap {
  max-width: 1050px;
  margin: 0 auto;
}

.about-img__wrap img {
  width: 33.33333333333%;
  float: left;
  display: block;
}

.about-img__wrap img:first-child {
  margin-left: 33.3%;
}

.about-text {
  margin: 70px 0 0;
  position: relative;
}

.about-text:last-child {
  margin-bottom: 0;
}

.about-text__sidebar {
  float: left;
  width: 203px;
  position: relative;
}

@media (max-width: 1220px) {
  .about-text__sidebar {
    width: 33%;
  }
}

@media (max-width: 767px) {
  .about-text__sidebar {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }
}

.about-text__sidebar:before {
  content: "";
  display: block;
  position: absolute;
  top: -55px;
  left: 0;
  height: 8px;
  width: 100px;
  background: #000;
}

@media screen and (min-width: 768px) {
  .about-text__sidebar:before {
    display: none;
  }
}

.about-text__sidebar h3 {
  font-weight: 700;
  line-height: 30px;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 28px;
}

@media (max-width: 1075px) {
  .about-text__sidebar h3 {
    margin-left: 0;
    width: 200px;
  }
}

@media (max-width: 767px) {
  .about-text__sidebar h3 {
    width: auto;
    line-height: 25px;
    margin-bottom: 15px;
  }
}

.about-text__sidebar p {
  display: block;
  margin: 0 0 47px;
  font-size: 15px;
  line-height: 25px;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  .about-text__sidebar p {
    margin-bottom: 15px;
  }
}

.about-text__content {
  width: 801px;
  float: right;
}

@media (max-width: 767px) {
  .about-text__content {
    width: 100%;
    margin-bottom: 20px;
    float: none;
  }
}

@media (max-width: 1220px) {
  .about-text__content {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .about-text__content {
    float: none;
    width: 100%;
  }
}

.about-text__content p {
  color: #707070;
  font-size: 14px;
  line-height: 25px;
  min-height: 110px;
  font-weight: 300;
}

@media (max-width: 1075px) {
  .about-text__content p {
    margin-bottom: 25px;
    min-height: 0;
  }
}

@media (max-width: 767px) {
  .about-text__content p {
    margin: 0 0 15px;
    line-height: 20px;
    min-height: 0;
  }
}

.about-text__content p.second-line {
  min-height: 110px;
}

.about-text__content p span {
  font-weight: 600;
  color: #171717;
}

.about-text__left {
  float: left;
  width: 350px;
  padding-right: 53px;
}

@media (max-width: 1075px) {
  .about-text__left {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .about-text__left {
    float: none;
    width: 100%;
    padding: 0;
  }
}

.about-text__left:first-child {
  position: relative;
}

.about-text__left:first-child:before {
  content: "";
  display: block;
  position: absolute;
  top: -55px;
  left: 0;
  height: 8px;
  width: 100px;
  background: #000;
}

@media (max-width: 767px) {
  .about-text__left:first-child:before {
    display: none;
  }
}

.about-text__right {
  float: left;
  width: 310px;
  padding-right: 0;
}

@media (max-width: 767px) {
  .about-text__right {
    float: none;
    width: 100%;
    padding: 0;
  }
}

html {
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body,
html {
  -webkit-backface-visibility: hidden;
}

body {
  margin: 0;
  position: relative;
  background-color: #fafafa;
  width: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

body,
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

iframe {
  border: transparent;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a:active,
a:focus,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 40px;
  margin: 0;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 15px;
}

h2,
h3,
h4,
h5,
p {
  margin: 0;
}

p {
  font-size: 15px;
  line-height: 1.67;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
  display: block;
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: auto;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button:focus,
input:focus,
optgroup:focus,
select:focus,
textarea:focus {
  outline: none;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: 700;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

blockquote {
  padding-left: 15px;
  border-left: 5px solid #0072f6;
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
}

blockquote em {
  display: block;
  font-size: 80%;
}

.container.no {
  padding: 50px 0;
}

.container.no h3 {
  font-size: 32px;
  line-height: 1.4;
  margin-bottom: 15px;
}

.container.no p {
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 25px;
  color: #555;
}

.no-wrp {
  white-space: nowrap;
}

.block-chart {
  padding: 25px 0;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 1023px) {
  .block-chart {
    display: block;
  }
}

.block-chart__content {
  width: 40%;
}

@media screen and (max-width: 1279px) {
  .block-chart__content {
    width: 45%;
  }
}

@media screen and (max-width: 1023px) {
  .block-chart__content {
    width: 100%;
  }
}

.block-chart__title {
  margin-bottom: 30px;
  font-size: 24px;
  text-transform: uppercase;
}

.block-chart__text {
  font-weight: 300;
}

.block-chart__media {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 1023px) {
  .block-chart__media {
    margin-top: 50px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .block-chart__media {
    display: block;
    text-align: center;
  }
}

.block-chart__media img {
  margin-right: 125px;
}

@media screen and (max-width: 767px) {
  .block-chart__media img {
    margin-right: 0;
    margin-bottom: 50px;
  }
}

.block-chart__list li {
  line-height: 30px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 700;
}

.block-chart__list li span {
  padding-left: 30px;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .block-chart__list li {
    width: 150px;
    margin: 0 auto;
  }
}

.block-features {
  padding: 25px 0;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.block-features__title {
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 50px;
}

.block-features__item {
  width: 50%;
  padding-right: 100px;
}

.block-features__item:nth-child(-n + 4) {
  margin-bottom: 50px;
}

@media screen and (max-width: 1279px) {
  .block-features__item {
    padding-right: 60px;
  }
}

@media screen and (max-width: 1023px) {
  .block-features__item {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .block-features__item {
    width: 100%;
    padding-right: 0;
  }
}

.block-features__item a {
  color: #000;
}

.block-features__item a:hover {
  color: #0072f6;
}

.block-features__item a:active {
  color: #0061d1;
}

.block-features__heading {
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: uppercase;
  padding-left: 25px;
  border-left: 5px solid #0072f6;
}

.block-features__list li {
  font-weight: 300;
  line-height: 30px;
}

.block-features__list li:last-child {
  margin-bottom: 0;
}

.block-features__text {
  font-weight: 300;
}

.block-features--main .block-features__heading {
  font-size: 24px;
  line-height: 1;
}

.block-features--main .block-features__item {
  padding: 20px 0;
  margin-bottom: 30px;
  position: relative;
  margin-right: 135px;
  width: -webkit-calc(100% / 2 - 135px);
  width: calc(100% / 2 - 135px);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .block-features--main .block-features__item {
    margin-right: 80px;
    width: -webkit-calc(100% / 2 - 60px);
    width: calc(100% / 2 - 60px);
  }

  .block-features--main .block-features__item:nth-child(2n) {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .block-features--main .block-features__item {
    margin-right: 50px;
    width: 100%;
  }
}

.block-features--no-padding {
  padding: 0;
}

.block-features--disc-style {
  list-style-type: disc;
  padding-left: 30px;
}

.block-features__item--link {
  cursor: pointer;
}

.block-features__item--link:after {
  content: "";
  background-color: #0072f6;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}

.block-features__item--link:focus:after,
.block-features__item--link:hover:after {
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
}

.block-features__item--link:focus .block-features__text,
.block-features__item--link:hover .block-features__text {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
  color: #fff;
}

.block-features__item--link:focus .block-features__heading,
.block-features__item--link:hover .block-features__heading {
  border-left-color: #fff;
  color: #fff;
}

.block-features__item--link .block-features__heading,
.block-features__item--link .block-features__text {
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.block-features--col-3 .block-features__item {
  width: 33.33333%;
}

.block-features--col-3 .block-features__item:nth-child(-n + 3) {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .block-features--col-3 .block-features__item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 50px;
  }

  .block-features--col-3 .block-features__item:last-child {
    margin-bottom: 0;
  }
}

.block-features--col-4 .block-features__item {
  width: 25%;
  padding-right: 50px;
}

@media screen and (max-width: 1279px) {
  .block-features--col-4 .block-features__item {
    padding-right: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .block-features--col-4 .block-features__item {
    width: 33.33333%;
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .block-features--col-4 .block-features__item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 50px;
  }

  .block-features--col-4 .block-features__item:last-child {
    margin-bottom: 0;
  }
}

.categories .block-features .block-features__item {
  margin: 0 0 14px;
}

.categories .block-features .block-features__item a {
  position: relative;
}

.categories .block-features .block-features__item a:after {
  content: "";
  height: 2px;
  -webkit-transition: right 0.1s ease;
  transition: right 0.1s ease;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 100%;
  background: #0072f6;
}

.categories .block-features .block-features__item a:hover {
  color: #0072f6;
}

.categories .block-features .block-features__item a:hover:after {
  right: -5px;
}

.block-markdown {
  margin: 70px 0 100px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
}

@media screen and (max-width: 1279px) {
  .block-markdown {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .block-markdown {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.block-markdown__sidebar {
  -ms-flex-negative: 1;
  flex-shrink: 1;
  width: 100%;
  padding-right: 179px;
  max-width: 394px;
}

@media screen and (max-width: 767px) {
  .block-markdown__sidebar {
    padding-right: 0;
  }
}

@media screen and (max-width: 1279px) {
  .block-markdown__sidebar {
    width: 100%;
  }
}

.block-markdown__sidebar h3 {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 28px;
}

.block-markdown__sidebar p {
  font-size: 15px;
  font-weight: 300;
  line-height: 25px;
  margin: 0 0 47px;
}

.block-markdown.col-3 > ul > li {
  width: 33.33333%;
}

@media screen and (max-width: 1023px) {
  .block-markdown.col-3 > ul > li {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .block-markdown.col-3 > ul > li {
    width: 100%;
    margin-right: 0;
  }
}

.block-markdown.col-2 > ul > li {
  width: 50%;
  padding-right: 80px;
}

@media screen and (max-width: 1023px) {
  .block-markdown.col-2 > ul > li {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .block-markdown.col-2 > ul > li {
    width: 100%;
    margin-right: 0;
  }
}

.block-markdown > h3 {
  padding-right: 70px;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  max-width: 294px;
  width: 100%;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 28px;
}

@media screen and (max-width: 1279px) {
  .block-markdown > h3 {
    width: 100%;
    max-width: none;
  }
}

.block-markdown > ul {
  position: relative;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-negative: 3;
  flex-shrink: 3;
}

.block-markdown > ul:before {
  content: "";
  position: absolute;
  display: block;
  width: 100px;
  height: 8px;
  top: -55px;
  left: 0;
  background: #000;
}

@media screen and (max-width: 1279px) {
  .block-markdown > ul {
    position: static;
    max-width: 100%;
  }
}

.block-markdown > ul > li {
  font-size: 15px;
  line-height: 25px;
  font-weight: 300;
  margin-bottom: 25px;
  display: block;
  width: 100%;
  min-height: 1px;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.block-markdown > ul > li:last-child {
  margin-bottom: 0;
}

.block-markdown > ul > li a {
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
  color: #155592;
  text-decoration: none;
}

.block-markdown > ul > li em {
  font-size: 15px;
  line-height: 25px;
  font-style: normal;
}

.block-markdown--privacy .privacy-inner-list {
  list-style-type: disc;
  padding-left: 30px;
  font-size: 15px;
}

.block-markdown--privacy .privacy-inner-list li {
  font-size: 15px;
}

.block-markdown--privacy ul {
  max-width: -webkit-calc(100% - 294px);
  max-width: calc(100% - 294px);
}

@media screen and (max-width: 1279px) {
  .block-markdown--privacy ul {
    max-width: 100%;
  }
}

.block-markdown--privacy .privacy-numbered-list-item {
  margin-bottom: 5px;
  font-weight: 300;
  line-height: 25px;
}

.block-markdown--privacy .privacy-strong-text {
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .block-markdown--contacts > ul {
    margin-right: 0;
  }
}

.block-markdown--contacts > ul > li {
  color: #797979;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}

.block-markdown--contacts > ul > li:nth-last-child(2) {
  margin-bottom: 0;
}

.block-markdown--contacts > ul > li h4 {
  color: #000;
  margin: 0 0 23px;
}

.block-markdown--contacts > ul > li a {
  color: #0072f6;
  line-height: 1.3;
  font-size: 14px;
}

.block-markdown--contacts > ul > li em {
  margin: 20px 0 10px;
  display: block;
  font-style: normal;
}

.block-markdown--contacts > ul > li ul {
  margin-right: 80px;
  margin-bottom: 35px;
}

.block-markdown--about li {
  color: #707070;
  min-height: 110px;
}

.block-markdown--about li:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .block-markdown--about li {
    margin-right: 0;
  }
}

.block-markdown--about li strong {
  color: #171717;
}

.block-video__item {
  margin-bottom: 30px;
}

.block-video__item:last-child {
  margin-bottom: auto;
}

@media screen and (max-width: 1279px) {
  .block-video__item {
    float: left;
    margin-right: 48px;
    margin-bottom: 0;
    width: -webkit-calc(100% / 2 - 24px);
    width: calc(100% / 2 - 24px);
  }

  .block-video__item:last-child {
    margin-right: auto;
  }
}

@media screen and (max-width: 1023px) {
  .block-video__item {
    float: none;
    width: 100%;
    margin-bottom: 50px;
    margin-right: auto;
  }
}

.block-video__wrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.block-video__wrapper iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.block-white {
  background-color: #fff;
  padding: 70px 0;
}

.block-white__flexbox {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 767px) {
  .block-white__flexbox {
    display: block;
  }
}

.block-white__title {
  text-transform: uppercase;
  max-width: 195px;
  margin-right: 78px;
}

@media screen and (max-width: 1279px) {
  .block-white__title {
    margin-right: 62px;
  }
}

@media screen and (max-width: 767px) {
  .block-white__title {
    margin-bottom: 50px;
  }
}

.block-white__title h2 {
  font-size: 24px;
  line-height: 32px;
}

.block-white__content {
  max-width: 796px;
}

.block-white__content p {
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
}

@media screen and (max-width: 1279px) {
  .block-white__content {
    max-width: 640px;
  }
}

@media screen and (max-width: 1023px) {
  .block-white__content {
    max-width: 432px;
  }
}

.blog-feed {
  margin-bottom: 40px;
}

.blog-feed__item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  padding: 20px;
}

@media screen and (max-width: 1023px) {
  .blog-feed__item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
    padding: 20px 0;
  }
}

@media screen and (max-width: 1023px) {
  .blog-feed__item.blog-feed__item--news {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    padding: 20px;
  }
}

@media screen and (max-width: 950px) {
  .blog-feed__item.blog-feed__item--news {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
    padding: 20px 0;
  }
}

.blog-feed__item.blog-feed__item--news:hover {
  color: #323232;
}

.blog-feed__item.blog-feed__item--news:hover .blog-feed__content {
  -webkit-box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.1);
}

.blog-feed__item.blog-feed__item--news:hover .blog-feed__title--news {
  color: #1476f2;
}

.blog-feed__content {
  background-color: #fff;
  position: relative;
  padding: 40px;
  height: 460px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

@media screen and (max-width: 767px) {
  .blog-feed__content {
    padding: 30px;
  }
}

.blog-feed__content__wrapper {
  padding: 40px 20px;
}

.blog-feed__content.blog-feed__content--news {
  display: block;
  padding: 0;
  height: 100%;
}

.blog-feed__image {
  height: 200px;
}

.blog-feed__link {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 200px;
  margin: -40px -40px 35px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .blog-feed__link {
    margin: -30px -30px 35px;
  }
}

.blog-feed__link.blog-feed__link--news {
  height: 160px;
  margin: 0;
  padding: 0;
  display: block;
}

.blog-feed__tag {
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px;
  position: absolute;
  left: 40px;
  top: 185px;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .blog-feed__tag {
    left: 30px;
  }
}

.blog-feed__tag.blog-feed__tag--news {
  margin: 0 5px;
  position: static;
  top: 210px;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: rgba(50, 50, 50, 0.3);
  background-color: unset;
  border: 0.5px solid rgba(50, 50, 50, 0.3);
}

.blog-feed__preheader {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.blog-feed__title {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.14;
  margin-bottom: 10px;
}

.blog-feed__title.blog-feed__title--news {
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #323232;
}

.blog-feed__date {
  color: #b8b8b8;
  font-size: 14px;
  font-weight: 300;
}

.blog-feed__date.blog-feed__date--news {
  height: 12px;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #323232;
}

.blog-feed__title-link {
  padding-top: 24px;
}

.blog-feed__lead {
  line-height: 1.43;
  font-size: 14px;
  width: 100%;
}

.blog-feed__lead.blog-feed__lead--news {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #555;
}

@media screen and (max-width: 767px) {
  .blog-feed__read-more {
    display: none;
  }
}

.posts-container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .posts-container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1024px) {
  .posts-container.posts-container--news {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 950px) {
  .posts-container.posts-container--news {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.blog-grid {
  padding: 70px 0;
}

@media screen and (max-width: 1279px) {
  .blog-grid {
    padding: 80px 0;
  }
}

@media screen and (max-width: 767px) {
  .blog-grid {
    padding: 70px 0 80px;
  }
}

.blog-grid__title {
  font-size: 36px;
  font-weight: 900;
}

.blog-grid__left {
  width: -webkit-calc(69% - 60px);
  width: calc(69% - 60px);
  float: left;
  margin-right: 60px;
}

@media screen and (max-width: 1279px) {
  .blog-grid__left {
    float: none;
    width: 100%;
    margin-bottom: 70px;
  }
}

.blog-grid__right {
  width: 31%;
  float: left;
}

@media screen and (max-width: 1279px) {
  .blog-grid__right {
    float: none;
    width: 100%;
  }
}

.blog-single__item {
  position: relative;
  overflow: hidden;
  height: 448px;
  cursor: pointer;
}

.blog-single__item:hover .blog-single__image {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: 1279px) {
  .blog-single__item {
    height: 530px;
  }
}

@media screen and (max-width: 1023px) {
  .blog-single__item {
    height: 397px;
  }
}

.blog-single__image {
  background-image: url(img/image-4.png);
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 1279px) {
  .blog-single__image {
    width: 100%;
  }
}

.blog-single__heading {
  position: absolute;
  bottom: 30px;
  font-size: 26px;
  color: #fff;
  left: 23px;
  right: 90px;
}

@media screen and (max-width: 1279px) {
  .blog-single__heading {
    right: 307px;
    bottom: 48px;
  }
}

@media screen and (max-width: 767px) {
  .blog-single__heading {
    right: 0;
  }
}

.blog-single__tag {
  position: absolute;
  top: 40px;
  left: 25px;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.blog {
  padding: 0 0 70px;
  position: relative;
}

.blog h2.title {
  padding: 70px 0 10px;
  font-size: 32px;
  font-weight: 600;
  line-height: 0.94;
  text-align: center;
  color: #323232;
}

@media screen and (max-width: 767px) {
  .blog .container {
    padding: 0 15px;
  }
}

.blog__grid {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  margin: 80px 0 0;
}

@media screen and (max-width: 980px) {
  .blog__grid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
}

.featured-post__wrapper,
.posts__wrapper {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

@media screen and (max-width: 767px) {
  .featured-post__wrapper,
  .posts__wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

.featured-post__wrapper .blog__item .blog__content {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 980px) {
  .featured-post__wrapper .blog__item .blog__content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}

@media screen and (max-width: 767px) {
  .featured-post__wrapper .blog__item .blog__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
  }
}

.featured-post__wrapper .blog__item .blog__media {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 46%;
  flex: 1 1 46%;
  width: 100%;
}

@media screen and (max-width: 980px) {
  .featured-post__wrapper .blog__item .blog__media {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
  }
}

@media screen and (max-width: 767px) {
  .featured-post__wrapper .blog__item .blog__media {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    height: 140px;
  }
}

.featured-post__wrapper .blog__item .blog__text {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 54%;
  flex: 1 1 54%;
  width: 100%;
}

@media screen and (max-width: 980px) {
  .featured-post__wrapper .blog__item .blog__text {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
  }
}

@media screen and (max-width: 767px) {
  .featured-post__wrapper .blog__item .blog__text {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    height: 260px;
    margin-top: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: #fff;
    z-index: 2;
  }
}

.featured-post__wrapper .blog__item .blog__title {
  color: #323232;
  padding: 13px 0 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
}

@media screen and (max-width: 980px) {
  .featured-post__wrapper .blog__item .blog__title {
    padding: 11px 0;
    font-size: 16px;
  }
}

.featured-post__wrapper .blog__item .blog__description {
  font-size: 15px;
  line-height: 1.67;
  color: #555;
}

@media screen and (max-width: 980px) {
  .featured-post__wrapper .blog__item .blog__description {
    font-size: 14px;
    line-height: 1.43;
    color: rgba(50, 50, 50, 0.8);
  }
}

.blog__item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  width: 100%;
}

.blog__item .blog__title {
  color: #323232;
}

.blog__item:hover .blog__media-inner {
  -webkit-transform: scale(1.16);
  -ms-transform: scale(1.16);
  transform: scale(1.16);
}

.blog__item:hover .blog__tag:not(.blog__tag--medium) {
  margin-top: -15px;
  opacity: 0;
}

.blog__item:hover .blog__link--more {
  opacity: 1;
  bottom: 40px;
}

.blog__item:hover .blog__link--more i {
  margin-left: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.blog__content {
  background-color: #fff;
  background-size: cover;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  color: #323232;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
}

.blog__content:hover {
  -webkit-box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.1);
}

.blog__media {
  height: 230px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  width: 35%;
  overflow: hidden;
}

.blog__media-inner {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog__logo {
  position: relative;
  z-index: 1;
}

.blog__text {
  padding: 33px;
  width: 65%;
}

@media screen and (max-width: 767px) {
  .blog__text {
    height: 260px;
    margin-top: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: #fff;
    z-index: 2;
  }
}

.blog__tag {
  font-size: 12px;
  font-weight: 600;
  color: rgba(50, 50, 50, 0.3);
  display: block;
  text-transform: uppercase;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog__title {
  padding: 11px 0;
  font-size: 16px;
  line-height: 1.25;
  text-transform: none;
  font-weight: 600;
}

.blog__description {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  width: 100%;
  color: rgba(50, 50, 50, 0.8);
}

.blog__description--medium {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .blog__description--medium {
    margin-bottom: 80px;
  }
}

.blog__link {
  color: #a7a7a7;
  font-size: 14px;
  bottom: 30px;
}

.blog__link,
.blog__link--more {
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
}

.blog__link--more {
  font-size: 13px;
  line-height: 1;
  color: #1476f2;
  bottom: 10px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

.blog__link--more i {
  font-size: 11px;
  margin-left: -3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.blog__link--more:hover {
  color: #005ed7;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .blog__text--blue {
    min-height: 390px;
  }
}

@media screen and (max-width: 1023px) {
  .blog__item {
    width: 100%;
  }

  .blog__text {
    padding: 30px;
  }

  .blog__item--three {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .blog__media {
    width: 100%;
    height: 140px;
  }

  .blog__text {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .blog__content--block {
    display: block;
  }

  .blog__media--fluid {
    width: 100%;
    height: 130px;
  }

  .blog__text--fluid {
    width: 100%;
    height: 280px;
    background: #fff;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .blog__text--blue {
    height: 100%;
    background-color: rgba(0, 114, 246, 0.9);
  }
}

.blue-box {
  background-color: rgba(0, 114, 246, 0.9);
  margin: 36px auto;
  padding: 0;
  max-width: 840px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.bg-fluid--blured {
  background-position: 50%;
  background-repeat: no-repeat;
  max-height: 465px;
}

@media screen and (max-width: 375px) {
  .bg-fluid--blured {
    max-height: 660px;
    background-repeat: repeat;
  }
}

.blue-box__bg {
  position: absolute;
  max-width: 840px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  -webkit-filter: blur(2.3px);
  filter: blur(2.3px);
  background-repeat: repeat;
  background-size: 2560px auto;
  background-position: 50%;
}

.blue-box__content {
  color: #fff;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 60px;
  z-index: 10;
  position: relative;
  background-color: rgba(0, 114, 246, 0.9);
}

@media screen and (max-width: 767px) {
  .blue-box__content {
    padding: 30px;
  }
}

.blue-box__title {
  color: inherit;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .blue-box__title {
    font-size: 24px;
  }
}

.blue-box__lead {
  color: inherit;
  line-height: 2;
  font-weight: 400;
  text-align: center;
  padding: 0 40px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 665px;
}

@media screen and (max-width: 960px) {
  .blue-box__lead {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .blue-box__lead {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.blue-circle {
  background-image: url(img/blue-circle.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  height: 1200px;
  max-width: 1200px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transform: translateY(-83%);
  -ms-transform: translateY(-83%);
  transform: translateY(-83%);
  z-index: -1;
}

.button {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-width: 140px;
  font-size: 13px;
  padding: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
  background: #1476f2;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  white-space: nowrap;
}

.button [class*=" isoi-"],
.button [class^="isoi-"] {
  font-size: 12px;
  color: #1476f2;
  padding-left: 12px;
  display: block;
}

.button:hover {
  background: #005ed7;
  border-color: #005ed7;
  color: #fff;
}

.button:hover [class*=" isoi-"],
.button:hover [class^="isoi-"] {
  color: #fff;
}

.button:active {
  background: #323232;
  border-color: #323232;
  color: #fff;
}

.button:active [class*=" isoi-"],
.button:active [class^="isoi-"] {
  color: #fff;
}

.button--clear {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-width: 140px;
  font-size: 13px;
  padding: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
  background: #1476f2;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  white-space: nowrap;
  background: transparent;
  border: 2px solid #1476f2;
}

.button--clear [class*=" isoi-"],
.button--clear [class^="isoi-"] {
  font-size: 12px;
  color: #1476f2;
  padding-left: 12px;
  display: block;
}

.button--clear:hover {
  background: #005ed7;
  border-color: #005ed7;
  color: #fff;
}

.button--clear:hover [class*=" isoi-"],
.button--clear:hover [class^="isoi-"] {
  color: #fff;
}

.button--clear:active {
  background: #323232;
  border-color: #323232;
  color: #fff;
}

.button--clear:active [class*=" isoi-"],
.button--clear:active [class^="isoi-"] {
  color: #fff;
}

.button--220 {
  max-width: 220px;
}

.icon-arrow {
  padding-left: 15px;
  width: 8px;
  height: 14px;
}

.icon-arrow:before {
  content: "";
  /*background-image: url(/img/icons/arrow-small-white.svg);*/
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  height: 12px;
  width: 8px;
}

.icon-arrow--blue:before {
  /*background-image: url(/img/icons/arrow-small-blue.svg)*/
}

.button--blue {
  color: #0072f6;
  border: 2px solid #0072f6;
}

.button--blue:hover .icon-arrow:before {
  /*background-image: url(/img/icons/arrow-small-white.svg)*/
}

.button--white {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-width: 140px;
  font-size: 13px;
  padding: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
  background: #1476f2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  white-space: nowrap;
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
}

.button--white [class*=" isoi-"],
.button--white [class^="isoi-"] {
  font-size: 12px;
  color: #1476f2;
  padding-left: 12px;
  display: block;
}

.button--white:hover {
  background: #005ed7;
  border-color: #005ed7;
  color: #fff;
}

.button--white:hover [class*=" isoi-"],
.button--white:hover [class^="isoi-"] {
  color: #fff;
}

.button--white:active {
  background: #323232;
  border-color: #323232;
  color: #fff;
}

.button--white:active [class*=" isoi-"],
.button--white:active [class^="isoi-"] {
  color: #fff;
}

.button--white:hover {
  color: #323232;
  background: #fff;
  border: 2px solid #fff;
}

.button--white:active {
  color: #323232;
  background: hsla(0, 0%, 88%, 0.8);
  border: 2px solid hsla(0, 0%, 88%, 0.1);
}

.button--white i {
  color: #fff;
}

.button--white:active,
.button--white:hover,
.button--white:hover i {
  color: #1476f2;
}

.button--white i[class^="isoi-"] {
  color: #fff;
}

.button--white:active i[class^="isoi-"],
.button--white:hover i[class^="isoi-"] {
  color: #1476f2;
}

.card__button.button i[class^="isoi-"] {
  color: #fff;
}

.button--white-gray {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-width: 140px;
  font-size: 13px;
  padding: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
  background: #1476f2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  white-space: nowrap;
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
}

.button--white-gray [class*=" isoi-"],
.button--white-gray [class^="isoi-"] {
  font-size: 12px;
  color: #1476f2;
  padding-left: 12px;
  display: block;
}

.button--white-gray:hover {
  background: #005ed7;
  border-color: #005ed7;
  color: #fff;
}

.button--white-gray:hover [class*=" isoi-"],
.button--white-gray:hover [class^="isoi-"] {
  color: #fff;
}

.button--white-gray:active {
  background: #323232;
  border-color: #323232;
  color: #fff;
}

.button--white-gray:active [class*=" isoi-"],
.button--white-gray:active [class^="isoi-"] {
  color: #fff;
}

.button--white-gray:hover {
  color: #323232;
  background: #fff;
  border: 2px solid #fff;
}

.button--white-gray:active {
  color: #323232;
  background: hsla(0, 0%, 88%, 0.8);
  border: 2px solid hsla(0, 0%, 88%, 0.1);
}

.button--white-gray i[class^="isoi-"] {
  color: #fff;
}

.button--white-gray:active i[class^="isoi-"],
.button--white-gray:hover i[class^="isoi-"] {
  color: #323232;
}

.button--white-green {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-width: 140px;
  font-size: 13px;
  padding: 0 20px;
  text-transform: uppercase;
  background: #1476f2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  white-space: nowrap;
  background: transparent;
  border: 2px solid #fff;
  height: 50px;
  min-width: 170px;
  font-weight: 600;
  color: #fff;
  background: #17ca8e;
  border: 2px solid #17ca8e;
  font-size: 15px;
}

.button--white-green [class*=" isoi-"],
.button--white-green [class^="isoi-"] {
  font-size: 12px;
  color: #1476f2;
  padding-left: 12px;
  display: block;
}

.button--white-green:hover {
  background: #005ed7;
  border-color: #005ed7;
}

.button--white-green:hover [class*=" isoi-"],
.button--white-green:hover [class^="isoi-"] {
  color: #fff;
}

.button--white-green:active {
  background: #323232;
  border-color: #323232;
  color: #fff;
}

.button--white-green:active [class*=" isoi-"],
.button--white-green:active [class^="isoi-"] {
  color: #fff;
}

.button--white-green:hover {
  color: #323232;
  background: #fff;
  border: 2px solid #fff;
  color: #fff;
  background: #15da98;
  border: 2px solid #15da98;
}

.button--white-green:active {
  color: #323232;
  background: hsla(0, 0%, 88%, 0.8);
  border: 2px solid hsla(0, 0%, 88%, 0.1);
}

.button--white-green i[class^="isoi-"] {
  color: #17ca8e;
}

.button--white-green:active i[class^="isoi-"],
.button--white-green:hover i[class^="isoi-"] {
  color: #323232;
}

.button--video-play {
  margin-top: 60px;
}

.button--video-play i[class^="isoi-"] {
  padding-left: 0;
  padding-right: 12px;
  font-size: 13px;
}

.button--primary {
  background-color: #0072f6;
  color: #fff;
  font-weight: 400;
  padding: 15px 35px;
  max-width: 200px;
  min-height: 45px;
}

.button--primary:hover {
  color: #fff;
  background-color: #06d;
}

.button--clients {
  background-color: #0072f6;
  font-weight: 600;
  max-width: 100%;
}

.button--blue-not-filled {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-width: 140px;
  font-size: 13px;
  padding: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
  background: #1476f2;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  white-space: nowrap;
  background: transparent;
  border: 2px solid #1476f2;
}

.button--blue-not-filled [class*=" isoi-"],
.button--blue-not-filled [class^="isoi-"] {
  font-size: 12px;
  color: #1476f2;
  padding-left: 12px;
  display: block;
}

.button--blue-not-filled:hover {
  background: #005ed7;
  border-color: #005ed7;
  color: #fff;
}

.button--blue-not-filled:hover [class*=" isoi-"],
.button--blue-not-filled:hover [class^="isoi-"] {
  color: #fff;
}

.button--blue-not-filled:active {
  background: #323232;
  border-color: #323232;
  color: #fff;
}

.button--blue-not-filled:active [class*=" isoi-"],
.button--blue-not-filled:active [class^="isoi-"] {
  color: #fff;
}

.card {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 10px;
  height: 240px;
  width: 400px;
}

.card__content {
  background-position: 50%;
  background-size: cover;
  color: #fff;
  position: relative;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
  padding: 40px;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.card__content:hover .button--more {
  position: absolute;
  height: 100%;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

@media screen and (max-width: 320px) {
  .card__content {
    padding: 20px;
  }
}

.card__hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card__title {
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

.card__lead {
  color: #fff;
}

.card__link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.card__link:hover ~ .card__button {
  margin-top: 20px;
  opacity: 1;
  background: transparent;
  padding: 0;
  min-width: 0;
}

.card__link:hover ~ .card__button,
.card__link:hover ~ .card__button i:before {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.card__link:hover ~ .card__hover {
  background-color: rgba(0, 114, 246, 0.8);
}

.card__button {
  -webkit-transform: translateX(-150%);
  -ms-transform: translateX(-150%);
  transform: translateX(-150%);
  opacity: 0;
  -webkit-transition: margin 0.2s ease-in-out, opacity 0.1s ease,
  -webkit-transform 0.3s ease;
  transition: margin 0.2s ease-in-out, opacity 0.1s ease,
  -webkit-transform 0.3s ease;
  transition: margin 0.2s ease-in-out, transform 0.3s ease, opacity 0.1s ease;
  transition: margin 0.2s ease-in-out, transform 0.3s ease, opacity 0.1s ease,
  -webkit-transform 0.3s ease;
  background: transparent;
}

.card__button i[class^="isoi-"] {
  color: #fff;
}

.card__button i:before {
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  -webkit-transform: translateX(-150%);
  -ms-transform: translateX(-150%);
  transform: translateX(-150%);
}

.case-studies__items {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.case-studies__items .item {
  margin-top: 30px;
  width: 100%;
  height: 480px;
  -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  cursor: pointer;
}

.case-studies__items .item:first-child {
  margin-top: 0;
}

.case-studies__items .item .item__media {
  position: relative;
  height: 60%;
}

.case-studies__items .item .item__media .item__media-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.case-studies__items .item .item__media .item__media-wrap .item__media-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  background-size: cover;
  background-position: 50%;
  -webkit-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.case-studies__items .item .item__media .item__media-logo {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  opacity: 1;
  z-index: 1;
}

.case-studies__items .item .item__media .item__media-logo img {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 2;
}

.case-studies__items .item .item__text {
  height: 40%;
  position: relative;
  padding: 38px 16px 30px;
  -webkit-transition: all 0.45s ease-out;
  transition: all 0.45s ease-out;
}

.case-studies__items .item .item__text h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-transform: unset;
  color: #323232;
}

.case-studies__items .item .item__text p {
  opacity: 0.7;
  font-size: 13px;
  line-height: 1.2;
  text-align: center;
  color: #323232;
}

.case-studies__items .item .item__text a {
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  color: #1476f2;
  text-transform: uppercase;
  bottom: 10px;
  position: absolute;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.case-studies__items .item .item__text a i {
  font-size: 11px;
  margin-left: 5px;
}

.case-studies__items .item--wide .item {
  width: 100%;
}

.case-studies__items .item:hover {
  -webkit-box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.case-studies__items
.item:hover
.item__media
.item__media-wrap
.item__media-inner {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.case-studies__items .item:hover .item__media .item__media-logo {
  width: 101%;
  height: 100%;
  bottom: 0;
  opacity: 0.6;
  border-radius: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  -webkit-animation: animLogo 0.3s ease;
  animation: animLogo 0.3s ease;
}

.case-studies__items .item:hover .item__media .item__media-logo img {
  min-width: 33%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.case-studies__items .item:hover .item__text {
  padding: 30px 16px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.case-studies__items .item:hover .item__text a {
  opacity: 1;
  bottom: 23px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

@media (min-width: 900px) {
  .case-studies__items {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row;
    flex-flow: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .case-studies__items .item {
    width: -webkit-calc((100% - 40px) / 3);
    width: calc((100% - 40px) / 3);
  }

  .case-studies__items .item:not(:nth-child(n + 4)) {
    margin-top: 0;
  }

  .case-studies__items .item:not(:nth-child(3n + 3)) {
    margin-right: 19.9999px;
  }

  .case-studies__items .item:last-child {
    margin-right: 0;
  }

  .case-studies__items .item .item__text {
    padding: 40px 20px 35px;
  }

  .case-studies__items .item .item__text h2 {
    font-size: 18px;
    font-weight: 600;
  }

  .case-studies__items .item .item__text p {
    opacity: 0.7;
    font-size: 13px;
    line-height: 1.23;
  }

  .case-studies__items .item .item__text a {
    font-size: 13px;
    line-height: 1;
  }

  .case-studies__items .item:hover .item__text {
    padding: 30px 20px 35px;
  }

  .case-studies__items .item:hover a {
    color: #005ed7;
  }
}

@media (min-width: 1280px) {
  .case-studies__items .item .item__text {
    padding: 50px 20px 45px;
  }

  .case-studies__items .item .item__text h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 0.85;
  }

  .case-studies__items .item .item__text p {
    opacity: 0.7;
    font-size: 14px;
    line-height: 1.33;
  }

  .case-studies__items .item .item__text a {
    font-size: 13px;
    line-height: 1;
  }

  .case-studies__items .item:hover .item__text {
    padding: 35px 20px 45px;
  }
}

@-webkit-keyframes animLogo {
  0% {
    width: 80px;
    height: 80px;
    bottom: -20px;
    opacity: 1;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  }
  40% {
    width: 30%;
    height: 30%;
    bottom: 5%;
    opacity: 0.8;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  }
  50% {
    width: 40%;
    height: 40%;
    border-radius: 5px;
    opacity: 0.6;
    bottom: 10%;
  }
  70% {
    width: 70%;
    height: 70%;
    bottom: 15%;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  }
  99% {
    width: 101%;
    height: 100%;
    border-radius: 0;
    bottom: 0;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  }
  to {
    -webkit-box-shadow: unset;
    box-shadow: unset;
  }
}

@keyframes animLogo {
  0% {
    width: 80px;
    height: 80px;
    bottom: -20px;
    opacity: 1;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  }
  40% {
    width: 30%;
    height: 30%;
    bottom: 5%;
    opacity: 0.8;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  }
  50% {
    width: 40%;
    height: 40%;
    border-radius: 5px;
    opacity: 0.6;
    bottom: 10%;
  }
  70% {
    width: 70%;
    height: 70%;
    bottom: 15%;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  }
  99% {
    width: 101%;
    height: 100%;
    border-radius: 0;
    bottom: 0;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  }
  to {
    -webkit-box-shadow: unset;
    box-shadow: unset;
  }
}

.case-intro {
  height: 630px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.case-intro__cover {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 600px;
  letter-spacing: -20px;
  color: #e6e6e6;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.case-intro__flexbox {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 2;
}

.case-intro__content {
  max-width: 796px;
  text-align: center;
}

@media screen and (max-width: 1023px) {
  .case-intro__content {
    max-width: 576px;
  }
}

.case-intro__content a {
  color: #0072f6;
}

.case-intro__content p {
  font-size: 36px;
  font-weight: 300;
  line-height: 46px;
}

@media screen and (max-width: 767px) {
  .case-intro__content p {
    font-size: 24px;
    line-height: 1.5;
  }
}

.case-nav {
  position: relative;
}

.case-nav__flexbox {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 300px;
}

@media screen and (max-width: 1023px) {
  .case-nav__flexbox {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .case-nav__flexbox {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.case-nav__cover {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.4s ease-in-out, -webkit-transform 5s ease;
  transition: opacity 0.4s ease-in-out, -webkit-transform 5s ease;
  transition: transform 5s ease, opacity 0.4s ease-in-out;
  transition: transform 5s ease, opacity 0.4s ease-in-out,
  -webkit-transform 5s ease;
  opacity: 0;
  background-image: url(./img/big-data-analytics.jpg);
  background-size: cover;
  top: 0;
  left: 0;
}

.case-nav__item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 50%;
}

.case-nav__item--white {
  background-color: #f0f0f0;
}

.case-nav__item--black {
  background-color: #000;
}

.case-nav__item a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 80px;
  min-height: 300px;
}

@media screen and (min-width: 1600px) {
  .case-nav__item a {
    padding: 80px 160px;
  }
}

@media screen and (max-width: 1023px) {
  .case-nav__item a {
    padding: 80px 50px;
  }
}

@media screen and (max-width: 767px) {
  .case-nav__item a {
    padding: 40px 20px;
    min-height: 230px;
  }
}

@media screen and (max-width: 767px) {
  .case-nav__item {
    width: 100%;
  }
}

.case-nav__item:hover .case-nav__cover {
  opacity: 0.5;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.case-nav__direction {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: #27aeff;
  text-transform: uppercase;
}

@media screen and (max-width: 1023px) {
  .case-nav__direction {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .case-nav__direction {
    line-height: 36px;
  }
}

.case-nav__title {
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  text-align: left;
  color: #000;
  text-transform: capitalize;
}

@media screen and (max-width: 1279px) {
  .case-nav__title {
    line-height: 60px;
    font-size: 47px;
  }
}

@media screen and (max-width: 1023px) {
  .case-nav__title {
    font-size: 36px;
    line-height: 44px;
  }
}

.case-nav__title--white {
  color: #fff;
}

.case-nav__arrow {
  background-image: url(img/arrow-back-icons-prev.svg);
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: block;
  margin: 0;
  object-fit: contain;
}

.case-nav__arrow--next {
  background-image: url(img/arrow-back-icons-next.svg);
  background-repeat: no-repeat;
}

.slider {
  position: relative;
  height: 600px;
}

.slider__item {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50%;
}

.slider .carousel,
.slider .carousel-cell {
  height: 100%;
}

.case-slider--creating-app {
  width: 100%;
  height: 800px;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .case-slider--creating-app {
    padding: 40px 0;
  }
}

.case-slider--creating-app .swiper-container {
  width: 50%;
  height: 50%;
  margin: auto;
  padding-left: 50px;
}

@media screen and (max-width: 800px) {
  .case-slider--creating-app .swiper-container {
    width: 100%;
    padding-left: 0;
  }
}

@media screen and (max-width: 425px) {
  .case-slider--creating-app .swiper-container {
    height: 100%;
    padding-top: 100%;
    position: absolute;
    top: 0;
  }
}

.case-slider--creating-app .swiper-container .swiper-slide {
  height: 100%;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.case-slider--creating-app .creating-app {
  width: 100%;
  height: 100%;
  position: relative;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 768px) {
  .case-slider--creating-app .creating-app {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.case-slider--creating-app .creating-app .picture,
.picture {
  position: absolute;
}

.picture {
  opacity: 0;
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s;
}

.creating-app__pictures-container {
  width: 50%;
  position: relative;
  height: 400px;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .creating-app__pictures-container {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .creating-app__pictures-container {
    height: 40%;
    margin: 0;
  }
}

.picture.animated {
  opacity: 1;
}

.case-slider__slide-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  color: #323232;
  text-align: left;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.case-slider__slide-text {
  font-size: 18px;
  line-height: 1.67;
  color: #555;
  text-align: left;
  width: 100%;
  max-width: 600px;
}

.case-slider__slide-text p {
  font-size: 18px;
}

@media screen and (max-width: 1024px) {
  .case-slider__slide-text {
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .case-slider__slide-text {
    max-width: none;
  }
}

.swiper-container > .swiper-pagination-bullets {
  position: absolute;
  bottom: 80px !important;
  left: 50px;
  right: auto !important;
  top: auto !important;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  width: auto !important;
}

@media screen and (max-width: 768px) {
  .swiper-container > .swiper-pagination-bullets {
    left: 0 !important;
    right: 0 !important;
    margin: auto;
  }
}

.swiper-container > .swiper-pagination-bullets .swiper-pagination-bullet {
  border-radius: 0;
  width: 50px;
  height: 2px;
  display: inline-block !important;
  margin-right: 20px !important;
}

@media screen and (max-width: 768px) and (max-width: 425px) {
  .swiper-container > .swiper-pagination-bullets {
    bottom: 0 !important;
  }
}

.case-studies .carousel {
  width: 100%;
  height: 334px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  .case-studies .carousel {
    height: 371px;
  }
}

.case-studies__item {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1023px) {
  .case-studies__item {
    width: 100%;
  }
}

.case-studies__image {
  background-image: url(img/bitmap.png);
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
}

.case-studies__content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

.case-studies__heading {
  color: #fff;
}

.case-studies__lead {
  color: #fff;
  max-width: 370px;
  margin: 0 auto 47px;
}

@media screen and (max-width: 1023px) {
  .case-studies__lead {
    max-width: 390px;
  }
}

@media screen and (max-width: 470px) {
  .case-studies__lead {
    max-width: 230px;
  }
}

@media screen and (min-width: 1024px) {
  .js-hide-navs .flickity-page-dots,
  .js-hide-navs .flickity-prev-next-button {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .flickity-page-dots {
    display: none;
  }
}

.case-tabs {
  background-color: #303030;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .case-tabs {
    padding-bottom: 0;
  }
}

.case-tabs__background {
  background-color: #000;
}

.case-tabs .tabbed-pane__body {
  display: none;
  max-width: 880px;
  padding: 0 40px;
  margin: 100px auto 0;
}

@media screen and (max-width: 767px) {
  .case-tabs .tabbed-pane__body {
    margin: 50px auto;
  }
}

.case-tabs .tabbed-pane__body p {
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  font-weight: 300;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .case-tabs .tabbed-pane__body p {
    font-size: 18px;
    line-height: 1.5;
  }
}

.case-tabs .tabbed-pane__tabs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 650px;
  margin: 0 auto;
  height: 120px;
}

.case-tabs .tabbed-pane__tabs,
.case-tabs .tabbed-pane__tabs li {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.case-tabs .tabbed-pane__tabs li {
  border-bottom: 4px solid #000;
  color: #fff;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
}

.case-tabs .tabbed-pane__tabs li span {
  pointer-events: none;
  position: relative;
  padding: 0 50px;
}

@media screen and (max-width: 767px) {
  .case-tabs .tabbed-pane__tabs li span {
    padding: 0 15px;
    font-size: 14px;
  }
}

.case-tabs .tabbed-pane--tab-1 .tabbed-pane__body-1 {
  display: block;
}

.case-tabs .tabbed-pane--tab-1 .tabbed-pane__tab-1 {
  border-color: #0072f6;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.case-tabs .tabbed-pane--tab-2 .tabbed-pane__body-2 {
  display: block;
}

.case-tabs .tabbed-pane--tab-2 .tabbed-pane__tab-2 {
  border-color: #0072f6;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.case-tabs .tabbed-pane--tab-3 .tabbed-pane__body-3 {
  display: block;
}

.case-tabs .tabbed-pane--tab-3 .tabbed-pane__tab-3 {
  border-color: #0072f6;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.cases {
  background-color: #fff;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 1023px) {
  .cases {
    background-color: transparent;
  }
}

@media screen and (max-width: 1023px) {
  .cases__carousel {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}

.cases__item {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 1023px) {
  .cases__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.cases__content {
  background-color: #fff;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 60px;
}

@media screen and (max-width: 1023px) {
  .cases__content {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    padding: 30px;
  }
}

.cases__lead {
  margin-bottom: 25px;
}

.cases__image {
  position: relative;
  height: 400px;
}

@media screen and (max-width: 767px) {
  .cases__image {
    height: 200px;
  }
}

.cases__bg {
  background-position: top;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cases__link img {
  opacity: 0.4;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: auto !important;
}

@media screen and (max-width: 1023px) {
  .cases__link img {
    width: 50px !important;
  }
}

.cases__link {
  background-color: #fff;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #f5f5f5;
  border-bottom: 3px solid #eaeaea;
  border-right: 1px solid #f5f5f5;
  position: relative;
  width: 100%;
  height: 200px;
}

@media screen and (max-width: 1023px) {
  .cases__link {
    height: 100px;
  }
}

.cases__link:after {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 3px;
  background-color: #0072f6;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.cases__link:focus img,
.cases__link:hover img {
  opacity: 1;
}

.cases__link:focus:after,
.cases__link:hover:after {
  width: 100%;
}

.categories {
  padding: 50px 0 25px;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.categories__title {
  text-transform: uppercase;
  font-weight: 700;
}

.categories__list {
  list-style: none;
}

.categories__list .icon {
  background-position: 0;
  background-repeat: no-repeat;
}

.categories__list .icon--business {
  background-image: url(img/services/icon-business.svg)
}

.categories__list .icon--education {
  background-image: url(img/services/icon-education.svg)
}

.categories__list .icon--finance {
  background-image: url(img/services/icon-finance.svg)
}

.categories__list .icon--prod {
  background-image: url(img/services/icon-prod.svg)
}

.categories__list .icon--enter {
  background-image: url(img/services/icon-enter.svg)
}

.categories__list .icon--shopping {
  background-image: url(img/services/icon-shopping.svg)
}

.categories__list .icon--transport {
  background-image: url(img/services/icon-transport.svg)
}

.categories__list .icon--retail {
  background-image: url(img/services/icon-retail.svg)
}

.categories__list .icon--security {
  background-image: url(img/services/icon-security.svg)
}

.categories__list .icon--tools {
  background-image: url(img/services/icon-tools.svg)
}

.categories__list .icon--medical {
  background-image: url(img/services/icon-medical.svg)
}

.categories__list ul {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.categories__list li {
  position: relative;
  width: -webkit-calc(100% / 3 - 1px);
  width: calc(100% / 3 - 1px);
  margin-top: 37px;
  text-transform: uppercase;
  line-height: 23px;
  font-weight: 600;
  display: block;
  float: left;
}

@media (max-width: 1279px) {
  .categories__list li {
    width: -webkit-calc(100% / 2 - 1px);
    width: calc(100% / 2 - 1px);
  }
}

@media (max-width: 767px) {
  .categories__list li {
    width: 100%;
  }
}

.categories__list li a {
  color: #000;
  position: relative;
  margin-left: 50px;
}

.categories__list li a:before {
  content: "";
  display: block;
  position: absolute;
  left: -50px;
  top: 50%;
  width: 30px;
  height: 30px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.categories__list li a:after {
  content: "";
  height: 2px;
  -webkit-transition: right 0.1s ease;
  transition: right 0.1s ease;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 100%;
  background: #0072f6;
}

.categories__list li a:hover {
  color: #0072f6;
}

.categories__list li a:hover:after {
  right: -5px;
}

.categories__list .icon-team a:before {
  background-image: url(img/services/icon-team.svg)
}

.categories__list .icon-soft a:before {
  background-image: url(img/services/icon-soft.svg)
}

.categories__list .icon-mobile a:before {
  background-image: url(img/services/icon-mobile.svg)
}

.categories__list .icon-ent a:before {
  background-image: url(img/services/icon-ent.svg)
}

.categories__list .icon-star a:before {
  background-image: url(img/services/icon-star.svg)
}

.categories__list .icon-ui a:before {
  background-image: url(img/services/icon-ui.svg)
}

.categories__list .icon-qa a:before {
  background-image: url(img/services/icon-qa.svg)
}

.categories__list .icon-maint a:before {
  background-image: url(img/services/icon-maint.svg)
}

.categories__list .icon-transport a:before {
  background-image: url(img/services/icon-prod.svg)
}

.categories__list .icon-finance a:before {
  background-image: url(img/services/icon-finance.svg)
}

.categories__list .icon-health a:before {
  background-image: url(img/services/icon-medical.svg)
}

.categories__list .icon-retail a:before {
  background-image: url(img/services/icon-retail.svg)
}

.categories__list .icon-game a:before {
  background-image: url(img/services/icon-enter.svg)
}

.categories__list .icon-travel a:before {
  background-image: url(img/services/icon-transport.svg)
}

@media (max-width: 1023px) {
  .categories__list .icon--tools {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .categories__list .icon--tools {
    display: block;
  }
}

@media (max-width: 767px) {
  .categories__list .icon--medical {
    display: block;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .categories__list .icon {
    background-image: none;
  }
}

@media (max-width: 767px) {
  .categories__list li {
    padding: 0;
    font-weight: 700;
  }
}

@media (max-width: 1023px) {
  .categories .col-4 {
    width: 50%;
  }

  .categories li:first-child {
    margin-top: 37px;
  }
}

.main-block__bg-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  max-width: 1200px;
  height: 1200px;
  -webkit-transform: translateY(-25%);
  -ms-transform: translateY(-25%);
  transform: translateY(-25%);
  width: 100%;
  z-index: 0;
}

@media (max-width: 1024px) {
  .main-block__bg-block {
    -webkit-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    transform: translateY(-10%);
  }
}

.main-block__bg-block img {
  width: 100%;
  height: 100%;
  -webkit-animation-name: circleAnim;
  animation-name: circleAnim;
  -webkit-animation-duration: 350s;
  animation-duration: 350s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@media (max-width: 1024px) {
  .main-block__bg-block img {
    -webkit-animation: none;
    animation: none;
  }
}

@-webkit-keyframes circleAnim {
  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

@keyframes circleAnim {
  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

.cemetery {
  padding: 80px 0 60px;
  background-color: #fff;
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.cemetery-about,
.cemetery.mobile-development {
  background-color: unset;
}

.cemetery .container {
  oopacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  .cemetery .button--clear {
    font-size: 12px;
  }
}

.cemetery__title {
  color: #323232;
  margin-bottom: 30px;
}

.cemetery__title h2 {
  color: #323232;
  text-transform: uppercase;
  text-align: center;
}

.cemetery__title p {
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: #555;
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 20px;
}

@media screen and (max-width: 1023px) {
  .cemetery__title p {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .cemetery__title p {
    font-size: 14px;
  }
}

.cemetery__image {
  background-position: 50%;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  background-image: url(./img/clients-logo/universal.png);
}
.item1 {
  background-position: 50%;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  background-image: url(./img/clients-logo/nhs.png);
}

.cemetery__title--center {
  text-align: center;
}

.cemetery__title--center h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
}

.cemetery__title--center p {
  font-size: 16px;
  font-weight: 400;
}

.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  width: 50px;
  height: 2px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #0072f6;
}

.community-block {
  padding-top: 40px;
  position: relative;
}

@media (max-width: 1024px) {
  .community-block {
    padding: 0;
  }
}

.community-block__wrapper {
  position: absolute;
  width: 300px;
}

@media (min-width: 1024px) {
  .community-block__wrapper {
    display: none;
  }
}

@media (max-width: 767px) {
  .community-block__wrapper {
    position: static;
    width: auto;
    display: block;
  }
}

.community-block__news-block {
  display: block;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.community-block__news-block:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  background-color: #171717;
  height: 4px;
}

.community-block__news-block:last-child:after {
  display: none;
}

.community-block__news-block__title {
  color: #0072f6;
  font-size: 11px;
  line-height: 12px;
  font-weight: 300;
  font-style: italic;
}

.community-block__news-block__description {
  color: #0072f6;
  font-size: 21px;
  line-height: 26px;
  font-weight: 700;
  padding-top: 30px;
}

.community-block__block-title-marker {
  position: relative;
  color: #0072f6;
  font-size: 12px;
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 2.1px;
  display: block;
  margin-left: 28px;
  font-weight: 300;
}

.community-block__block-title-marker:after {
  position: absolute;
  width: 55px;
  height: 1px;
  content: "";
  display: block;
  background: #0072f6;
  top: 6px;
  left: 80px;
}

@media (max-width: 1325px) {
  .community-block__block-title-marker:after {
    display: none;
  }
}

@media (max-width: 1325px) {
  .community-block__block-title-marker {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    margin: 0;
  }
}

@media (max-width: 1024px) {
  .community-block__block-title-marker {
    font-size: 10px;
  }
}

.community-block__block-title-marker__wrap {
  width: 0;
  height: 0;
  position: absolute;
  left: 5%;
  top: 331px;
  display: block;
}

@media (max-width: 1325px) {
  .community-block__block-title-marker__wrap {
    position: static;
    width: 1000px;
    margin: 63px auto -78px;
    height: auto;
  }
}

@media (max-width: 1075px) {
  .community-block__block-title-marker__wrap {
    width: 900px;
  }
}

@media (max-width: 1024px) {
  .community-block__block-title-marker__wrap {
    width: 100%;
    padding: 0 37px;
  }
}

@media (max-width: 767px) {
  .community-block__block-title-marker__wrap {
    padding-left: 30px;
    padding-right: 30px;
    margin: 43px 0 -59px;
  }
}

.community-block__news-wrap {
  width: 50%;
  float: left;
  background: #fff;
  text-align: right;
}

@media (max-width: 1024px) {
  .community-block__news-wrap {
    width: auto;
    float: none;
    padding: 0 37px 45px;
  }
}

@media (max-width: 767px) {
  .community-block__news-wrap {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.community-block__news-inner {
  width: 700px;
  display: inline-block;
  text-align: left;
  background: #fff;
  margin-right: -200px;
}

@media (min-width: 1076px) and (max-width: 1325px) {
  .community-block__news-inner {
    padding-bottom: 57px;
  }
}

@media (min-width: 1025px) and (max-width: 1075px) {
  .community-block__news-inner {
    padding-bottom: 23px;
  }
}

@media (max-width: 1075px) {
  .community-block__news-inner {
    width: 600px;
    margin-right: -150px;
  }
}

@media (max-width: 1024px) {
  .community-block__news-inner {
    margin: 0;
    width: auto;
    display: block;
  }
}

.community-block__events-wrap {
  width: 50%;
  float: left;
}

@media (max-width: 1024px) {
  .community-block__events-wrap {
    float: none;
    width: auto;
    padding: 0 37px;
  }
}

@media (max-width: 767px) {
  .community-block__events-wrap {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.community-block__events-inner {
  width: 400px;
  display: inline-block;
  margin-left: 200px;
  padding-left: 100px;
}

@media (max-width: 1325px) {
  .community-block__events-inner {
    padding-left: 50px;
    width: 300px;
  }
}

@media (max-width: 1075px) {
  .community-block__events-inner {
    padding-left: 0;
    width: 250px;
  }
}

@media (max-width: 1024px) {
  .community-block__events-inner {
    padding: 0;
    margin: 0;
    width: auto;
    display: block;
  }
}

.community-block__link {
  position: absolute;
  right: 0;
  font-size: 11px;
  padding: 5px 15px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  top: 114px;
}

.community-block__link,
.community-block__link:hover {
  background: url(img/link-arrow-black.png) right 7px no-repeat;
  background-size: 5px 7px;
  color: #171717;
}

@media (max-width: 1024px) {
  .community-block__link {
    padding-right: 18px;
    top: 105px;
  }
}

.community-block__link--right-side {
  right: 48px;
}

.community-block__link--right-side,
.community-block__link--right-side:hover {
  background: url(img/link-arrow-black.png) right 7px no-repeat;
  background-size: 5px 7px;
  color: #171717;
}

@media (max-width: 1024px) {
  .community-block__link--right-side {
    right: 0;
  }
}

@media (max-width: 767px) {
  .community-block__link--right-side {
    top: 41px;
    right: 13px;
  }
}

.community-block__content-title {
  font-size: 37px;
  line-height: 1;
  color: #171717;
  letter-spacing: -0.8px;
  font-weight: 900;
}

@media (max-width: 1024px) {
  .community-block__content-title {
    font-size: 37px;
  }
}

@media (max-width: 767px) {
  .community-block__content-title {
    font-size: 37px;
  }
}

.community-block__content-title--blue-col {
  color: #0072f6;
}

.community-block__head {
  text-align: center;
  background: #fff;
  padding: 86px 0 91px;
}

.community-block__title {
  margin-bottom: 29px;
  font-size: 54px;
}

.community-block__subtitle {
  font-size: 19px;
  margin: 0;
  line-height: 1;
}

.community-block__content__head {
  height: 204px;
  position: relative;
  padding: 100px 0 0;
}

.community-block__content__head.news_head {
  margin-top: 70px;
}

@media (max-width: 1024px) {
  .community-block__content__head.news_head {
    margin-top: 50px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__head {
    padding-top: 97px;
    height: 194px;
  }
}

.community-block__content__head__tel {
  padding: 78px 0 0;
  height: 139px;
}

.community-block__content__events__list {
  padding: 0;
  margin: -1px 0 0;
  list-style-type: none;
  color: #0072f6;
}

.community-block__content__events__list__item {
  border-top: 1px solid #7bb4f6;
  position: relative;
  padding: 11px 3px 11px 0;
  min-height: 135px;
}

.community-block__content__events__list__item > a {
  display: block;
}

.community-block__content__events__list__item:last-child {
  min-height: 131px;
}

@media (max-width: 1024px) {
  .community-block__content__events__list__item:last-child {
    min-height: 101px;
  }
}

.community-block__content__events__list__item:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  height: 3px;
  width: 65px;
  background: #338ef8;
  left: 0;
}

@media (max-width: 1325px) {
  .community-block__content__events__list__item {
    padding-right: 0;
    min-height: 155px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__events__list__item {
    min-height: 101px;
  }
}

.community-block__content__events__date-wrap {
  display: block;
  float: left;
  color: #0072f6;
}

.community-block__content__events__date {
  font-size: 51px;
  float: left;
  width: 65px;
  font-weight: 600;
  text-align: center;
  color: #0072f6;
  letter-spacing: -1px;
}

.community-block__content__events__year {
  font-size: 12px;
  float: left;
  padding: 27px 0 0 4px;
  text-transform: uppercase;
  line-height: 12px;
  letter-spacing: 0.5px;
  font-weight: 300;
  color: #0072f6;
}

@media (max-width: 1024px) {
  .community-block__content__events__year {
    padding-left: 11px;
  }
}

.community-block__content__events__text {
  padding: 10px 0 0 120px;
  margin: 0;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.2px;
  word-spacing: 1px;
  color: #0072f6;
}

@media (max-width: 1024px) {
  .community-block__content__events__text {
    padding-left: 136px;
  }
}

@media (max-width: 950px) {
  .community-block__content__events__text {
    padding-left: 0;
    float: left;
    padding-top: 5px;
  }
}

@media (max-width: 600px) {
  .community-block__content__events__text {
    padding-top: 10px;
    float: none;
    padding-left: 136px;
  }
}

.community-block__content__news {
  background: #fff;
  padding-bottom: 90px;
}

@media (max-width: 1325px) {
  .community-block__content__news {
    padding-bottom: 0;
  }
}

.community-block__content__news__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.community-block__content__news__list__item {
  position: relative;
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item {
    margin-bottom: 89px;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item {
    margin: 0 0 28px;
    padding-bottom: 53px;
  }
}

.community-block__content__news__list__item__img {
  width: 350px;
  height: 350px;
  display: block;
  float: left;
}

.community-block__content__news__list__item__img img {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item__img img {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__news__list__item__img img {
    width: 347px;
    height: 347px;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item__img img {
    width: 260px;
    height: 260px;
  }
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item__img {
    width: 300px;
    height: 200px;
    overflow: hidden;
  }
}

@media (max-width: 1024px) {
  .community-block__content__news__list__item__img {
    width: 347px;
    height: 230px;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item__img {
    width: 100%;
    float: none;
    height: auto;
    text-align: center;
  }
}

.community-block__content__news__list__item__type {
  padding: 44px 0 0 400px;
  color: #bababa;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 0 18px;
  letter-spacing: 2.2px;
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item__type {
    padding-top: 9px;
    padding-left: 350px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__news__list__item__type {
    padding-left: 395px;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item__type {
    font-size: 10px;
    margin: 0 0 7px;
    padding: 26px 0 0;
  }
}

.community-block__content__news__list__item__text {
  padding: 0 48px 50px 400px;
  font-size: 14px;
  margin: 0;
  color: #171717;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 300;
}

.community-block__content__news__list__item__text p {
  margin-bottom: 0;
  max-height: 80px;
  overflow-y: hidden;
}

@media (min-width: 1075px) {
  .community-block__content__news__list__item__text p {
    max-height: 115px;
  }
}

@media (min-width: 768px) and (max-width: 1076px) {
  .community-block__content__news__list__item__text p {
    margin-bottom: 25px;
  }
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item__text {
    padding-left: 350px;
    padding-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .community-block__content__news__list__item__text {
    padding-left: 395px;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item__text {
    padding: 0;
  }
}

.community-block__content__news__list__item__title {
  color: #171717;
  font-size: 22px;
  line-height: 25px;
  font-weight: 700;
  margin: 0 0 30px -1px;
  padding-left: 400px;
  display: block;
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item__title {
    padding-left: 350px;
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__news__list__item__title {
    padding-left: 395px;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item__title {
    margin-bottom: 16px;
    padding: 0;
  }
}

.community-block__content__news__list__item__date {
  position: absolute;
  color: #171717;
  font-size: 12px;
  font-weight: 300;
  margin-left: 400px;
  letter-spacing: 0;
  font-style: italic;
  bottom: 20px;
  left: 0;
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item__date {
    margin-left: 350px;
    bottom: 2px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__news__list__item__date {
    margin-left: 395px;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item__date {
    margin-left: 0;
    bottom: 19px;
  }
}

.community-block__content__news__list__item--right-side-img__img {
  float: right;
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item--right-side-img__img {
    float: left;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item--right-side-img__img {
    float: none;
    width: 100%;
    height: auto;
  }
}

.community-block__content__news__list__item--right-side-img__type {
  padding-left: 0;
  padding-right: 370px;
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item--right-side-img__type {
    padding-left: 350px;
    padding-right: 0;
  }
}

@media (max-width: 1024px) {
  .community-block__content__news__list__item--right-side-img__type {
    padding-left: 395px;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item--right-side-img__type {
    padding: 26px 0 0;
  }
}

.community-block__content__news__list__item--right-side-img__title {
  padding: 0 431px 0 0;
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item--right-side-img__title {
    padding: 0 55px 0 350px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__news__list__item--right-side-img__title {
    padding: 0 0 0 395px;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item--right-side-img__title {
    padding: 0;
  }
}

.community-block__content__news__list__item--right-side-img__text {
  padding-left: 0;
  padding-right: 455px;
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item--right-side-img__text {
    padding-left: 350px;
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__news__list__item--right-side-img__text {
    padding-left: 395px;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item--right-side-img__text {
    padding: 0;
  }
}

.community-block__content__news__list__item--right-side-img__date {
  padding-left: 0;
  margin-left: 0;
  right: auto;
}

@media (max-width: 1075px) {
  .community-block__content__news__list__item--right-side-img__date {
    margin-left: 350px;
    right: auto;
  }
}

@media (max-width: 1024px) {
  .community-block__content__news__list__item--right-side-img__date {
    margin-left: 395px;
  }
}

@media (max-width: 767px) {
  .community-block__content__news__list__item--right-side-img__date {
    margin-left: 0;
  }
}

.community-block__content__events-links {
  padding-bottom: 70px;
  position: relative;
}

@media (max-width: 1325px) {
  .community-block__content__events-links {
    padding-bottom: 40px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__events-links {
    padding-top: 70px;
  }
}

@media (max-width: 600px) {
  .community-block__content__events-links {
    padding-top: 80px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__events-links .fill-field {
    width: 100%;
    float: left;
  }
}

@media (max-width: 767px) {
  .community-block__content__events-links .fill-field {
    width: 100%;
    float: none;
    margin: 0 0 40px;
  }
}

@media (max-width: 600px) {
  .community-block__content__events-links .fill-field {
    font-size: 15px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__events-links .button-blue {
    width: 100%;
    float: left;
  }
}

@media (max-width: 767px) {
  .community-block__content__events-links .button-blue {
    width: 100%;
    float: none;
    margin: 0;
  }
}

.community-block__content__events-links__wrap {
  margin-top: 110px;
}

@media (max-width: 1024px) {
  .community-block__content__events-links__wrap {
    margin-top: 30px;
  }
}

.community-block__content__events-links__last {
  padding-bottom: 0;
}

@media (max-width: 1024px) {
  .community-block__content__events-links__last {
    padding-top: 20px;
  }
}

.community-block__content__events-links__alert {
  position: absolute;
  top: 50px;
  left: 0;
  color: #f04b3c;
  font-size: 13px;
  font-weight: 300;
  display: none;
  padding-bottom: 20px;
}

.community-block__content__events-links__alert.show {
  display: block;
}

.community-block__content__events-links__title {
  font-size: 26px;
  line-height: 26px;
  font-weight: 600;
  margin: 0 0 10px -2px;
  letter-spacing: -1px;
  position: relative;
}

.community-block__content__events-links__title:after {
  display: block;
  content: "";
  position: absolute;
  top: -20px;
  left: 2px;
  height: 0;
  width: 190px;
  background: #171717;
}

@media (max-width: 1024px) {
  .community-block__content__events-links__title:after {
    top: -57px;
  }
}

@media (max-width: 1024px) {
  .community-block__content__events-links__title {
    line-height: 30px;
    margin: 4px 0 27px -2px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .community-block__content__events-links__title {
    width: 100%;
    font-size: 22.5px;
    line-height: 27.5px;
    margin: 0 0 32px;
    padding: 0 20px 0 0;
  }
}

@media (max-width: 600px) {
  .community-block__content__events-links__title {
    font-size: 29px;
    line-height: 40px;
  }
}

.community-block__content__events-links__subtitle {
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  margin: 0 0 30px -2px;
  letter-spacing: -1px;
  position: relative;
}

@media (max-width: 1024px) {
  .community-block__content__events-links__subtitle {
    margin-bottom: 10px;
  }
}

.community-block__content__events-links__subtext {
  font-size: 13px;
  color: #171717;
  font-weight: 300;
  display: inline-block;
  height: 120px;
}

.community-block__content__events-links__form-wrap {
  position: relative;
}

.community-block__events {
  width: 400px;
  float: left;
  padding: 0;
}

.community-block__events__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.community-block__news {
  width: 600px;
  float: left;
}

.construction {
  background-color: #fff;
}

.construction .construction__item {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.construction .construction__item:nth-child(2n + 2) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.construction
.construction__item:nth-child(2n + 2)
.construction__content
.construction__content-bg-layer {
  right: -17%;
  -webkit-transform: skew(17deg, 0deg);
  -ms-transform: skew(17deg, 0deg);
  transform: skew(17deg, 0deg);
}

.construction
.construction__item:nth-child(2n + 2)
.construction__content
.construction__content-bg-layer--second {
  right: -34%;
  -webkit-transform: skew(17deg, 0deg);
  -ms-transform: skew(17deg, 0deg);
  transform: skew(17deg, 0deg);
}

.construction
.construction__item:nth-child(2n + 2)
.construction__content
.construction__content-bg-layer--third {
  right: -68%;
  -webkit-transform: skew(17deg, 0deg);
  -ms-transform: skew(17deg, 0deg);
  transform: skew(17deg, 0deg);
}

.construction .construction__item .construction__media {
  width: 50%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  background-color: #333;
  margin: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.construction .construction__item .construction__content {
  position: relative;
  height: 100%;
  width: 50%;
}

.construction
.construction__item
.construction__content
.construction__content-bg-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 17%;
  background-color: #fff;
  -webkit-transform: skew(-17deg, 0deg);
  -ms-transform: skew(-17deg, 0deg);
  transform: skew(-17deg, 0deg);
}

.construction
.construction__item
.construction__content
.construction__content-bg-layer--second {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 17%;
  background-color: #fff;
  -webkit-transform: skew(-17deg, 0deg);
  -ms-transform: skew(-17deg, 0deg);
  transform: skew(-17deg, 0deg);
  top: 15%;
  right: 34%;
  opacity: 0.4;
}

.construction
.construction__item
.construction__content
.construction__content-bg-layer--third {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 17%;
  background-color: #fff;
  -webkit-transform: skew(-17deg, 0deg);
  -ms-transform: skew(-17deg, 0deg);
  transform: skew(-17deg, 0deg);
  top: 60%;
  right: 68%;
  opacity: 0.4;
}

.construction .construction__item .construction__content .construction__text {
  margin: 0 auto;
  width: 80%;
  position: relative;
  top: 50%;
  -webkit-transform: perspective(1px) translateY(-50%);
  transform: perspective(1px) translateY(-50%);
}

.construction
.construction__item
.construction__content
.construction__text
h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.25;
  color: #323232;
}

.construction .construction__item .construction__content .construction__text p {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
}

@media screen and (max-width: 1027px) {
  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer {
    right: -23%;
  }

  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer--second {
    right: -46%;
  }

  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer--third {
    right: -92%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer {
    right: 23%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer--second {
    right: 46%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer--third {
    right: 92%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__text
  h2 {
    font-size: 24px;
    line-height: 1.2;
  }

  .construction
  .construction__item
  .construction__content
  .construction__text
  p {
    font-size: 14px;
    line-height: 1.35;
  }
}

@media screen and (max-width: 768px) {
  .construction .construction__item {
    height: auto;
  }

  .construction .construction__item,
  .construction .construction__item:nth-child(2n + 2) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .construction .construction__item:nth-child(2n + 2) .construction__content {
    width: 100%;
  }

  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer {
    top: 28%;
    right: 0;
    -webkit-transform: skew(0deg, -12deg);
    -ms-transform: skew(0deg, -12deg);
    transform: skew(0deg, -12deg);
  }

  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer--second {
    top: 56%;
    right: 28%;
    -webkit-transform: skew(0deg, -12deg);
    -ms-transform: skew(0deg, -12deg);
    transform: skew(0deg, -12deg);
  }

  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer--third {
    top: 84%;
    right: 56%;
    -webkit-transform: skew(0deg, -12deg);
    -ms-transform: skew(0deg, -12deg);
    transform: skew(0deg, -12deg);
  }

  .construction .construction__item .construction__media {
    width: 100%;
    min-height: 360px;
  }

  .construction .construction__item .construction__content {
    width: 100%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer {
    top: 28%;
    right: 0;
    -webkit-transform: skew(0deg, -12deg);
    -ms-transform: skew(0deg, -12deg);
    transform: skew(0deg, -12deg);
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer--second {
    top: 56%;
    right: 28%;
    -webkit-transform: skew(0deg, -12deg);
    -ms-transform: skew(0deg, -12deg);
    transform: skew(0deg, -12deg);
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer--third {
    top: 84%;
    right: 56%;
    -webkit-transform: skew(0deg, -12deg);
    -ms-transform: skew(0deg, -12deg);
    transform: skew(0deg, -12deg);
  }

  .construction .construction__item .construction__content .construction__text {
    margin: 50px auto 0;
    top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  .construction
  .construction__item
  .construction__content
  .construction__text
  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.6px;
  }

  .construction
  .construction__item
  .construction__content
  .construction__text
  p {
    font-size: 15px;
    line-height: 1.67;
  }
}

@media screen and (max-width: 425px) {
  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer {
    top: 10%;
  }

  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer--second {
    top: 30%;
  }

  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer--third {
    top: 50%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer {
    top: 10%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer--second {
    top: 30%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer--third {
    top: 50%;
  }
}

@media screen and (max-width: 380px) {
  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer {
    top: 10%;
  }

  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer--second {
    top: 25%;
  }

  .construction
  .construction__item:nth-child(2n + 2)
  .construction__content
  .construction__content-bg-layer--third {
    top: 40%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer {
    top: 10%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer--second {
    top: 25%;
  }

  .construction
  .construction__item
  .construction__content
  .construction__content-bg-layer--third {
    top: 40%;
  }
}

.contact-btn {
  padding: 70px 0;
  background-color: #fff;
}

.contact-btn .container--flex {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-btn .box-left {
  width: 50%;
}

@media (max-width: 767px) {
  .contact-btn .box-left {
    width: 100%;
    text-align: center;
  }
}

.contact-btn .box-right {
  width: 50%;
  text-align: center;
}

@media (max-width: 767px) {
  .contact-btn .box-right {
    width: 100%;
    margin-top: 50px;
  }
}

.contact-btn__title {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 35px;
  font-size: 24px;
}

.contact-btn__title span {
  color: #0072f6;
  display: block;
}

@media screen and (max-width: 767px) {
  .contact-btn__title {
    font-size: 22px;
  }
}

.contact-btn__lead {
  font-size: 14px;
  line-height: 1;
  margin-top: 16px;
}

.contact-form {
  background-color: #f2f2f2;
  /*background-image: url(/img/bg-contact-form.jpg);*/
  background-position: 50%;
  background-size: cover;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .contact-form {
    padding: 70px 0 80px;
    height: auto !important;
  }
}

.contact-form--services-main {
  height: 80vh;
}

.contact-form .col-2 {
  float: left;
}

.contact-form .js-message {
  text-align: center;
  font-size: 18px;
  padding: 15px 0;
  color: #aede76;
  display: none;
}

.contact-form .container {
  max-width: 910px;
}

.contact-form .btn {
  padding: 0 16px;
  line-height: 45px;
}

.contact-form .form-row {
  width: 100%;
  display: block;
  margin-top: 10px;
  position: relative;
}

.contact-form .form-row .error:not(label) {
  -webkit-box-shadow: inset 0 0 0 4px #ffa8a1;
  box-shadow: inset 0 0 0 4px #ffa8a1;
  background-color: #fff1f2;
  margin-bottom: 7px;
}

.contact-form .form-row.mt-26 {
  margin-top: 52px;
}

.contact-form .form-row.mt-68 {
  margin-top: 68px;
  text-align: center;
}

.contact-form .form-row:first-child {
  margin-top: 0;
}

.contact-form .form-row:after {
  content: "";
  display: table;
  clear: both;
}

.contact-form .form-row .col-2 {
  width: -webkit-calc(50% - 5px);
  width: calc(50% - 5px);
  margin-right: 10px;
}

.contact-form .form-row .col-2:last-child {
  margin-right: 0;
}

.contact-form .form-row select {
  background-color: #fff;
  padding: 0 0 0 12px;
}

.contact-form .form-row select option {
  color: #000;
}

.contact-form .form-row select option:first-child {
  display: none;
}

.contact-form .form-row textarea {
  max-width: 100%;
  width: 100%;
  border: none;
  padding: 15px 0 0 17px;
  min-height: 120px;
}

.contact-form .form-row input[type="checkbox"] {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  outline: 0;
  z-index: -1;
  width: 17px;
  height: 17px;
}

.contact-form .form-row .is-required {
  color: hsla(0, 0%, 100%, 0.5);
  display: inline-block;
  float: right;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  .contact-form .form-row .is-required {
    display: none;
  }
}

.contact-form .form-row .forCheckbox {
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  padding-left: 30px;
  color: #fff;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.contact-form .form-row .forCheckbox:before {
  content: "";
  background: #fff;
}

.contact-form .form-row .forCheckbox:after,
.contact-form .form-row .forCheckbox:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 17px;
  -webkit-transition: border 0.1s ease, opacity 0.1s ease,
  -webkit-transform 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: border 0.1s ease, opacity 0.1s ease, -webkit-transform 0.1s ease,
  -webkit-box-shadow 0.1s ease;
  transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease,
  box-shadow 0.1s ease;
  transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease,
  box-shadow 0.1s ease, -webkit-transform 0.1s ease,
  -webkit-box-shadow 0.1s ease;
}

.contact-form .form-row .forCheckbox:after {
  content: "âœ”";
  font-size: 14px;
  text-align: center;
  opacity: 0;
  color: rgba(0, 0, 0, 0.87);
}

.contact-form .form-row input:checked ~ label:after {
  opacity: 1;
  color: rgba(0, 0, 0, 0.95);
}

.contact-form .form-row ul {
  padding-left: 17px;
  margin-top: 25px;
  display: none;
}

.contact-form .form-row ul span {
  color: #0072f6;
}

.contact-form .form-row ul li {
  margin-top: 10px;
}

.contact-form .form-row ul li:first-child {
  margin-top: 0;
}

.contact-form__title {
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
}

.contact-form__title span {
  color: #0072f6;
}

@media (max-width: 767px) {
  .contact-form__title {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .contact-form .form-row .col-2 {
    width: 100%;
    float: none;
    margin-top: 10px;
  }
}

.contact-form .form-row input:not([type="checkbox"]),
.contact-form .form-row select {
  width: 100%;
}

.contact-form .form-row input,
.contact-form .form-row select {
  height: 45px;
  border: none;
  padding-left: 17px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

@media (max-width: 767px) {
  .contact-form .form-row ul {
    display: block;
  }
}

.contacts-text {
  padding: 70px 0;
}

.contacts-text .container--flex {
  position: relative;
}

@media screen and (max-width: 1279px) {
  .contacts-text .container--flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.contacts-text__flexbox {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 3;
  flex-shrink: 3;
  position: relative;
}

.contacts-text__flexbox:before {
  content: "";
  position: absolute;
  display: block;
  width: 100px;
  height: 8px;
  top: -55px;
  left: 0;
  background: #000;
}

@media screen and (max-width: 1279px) {
  .contacts-text__flexbox:before {
    left: 5%;
  }
}

@media screen and (max-width: 1279px) {
  .contacts-text__flexbox {
    position: static;
  }
}

.contacts-text__title {
  width: 30%;
  padding-right: 70px;
  position: relative;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

@media screen and (max-width: 1279px) {
  .contacts-text__title {
    width: 100%;
    margin-bottom: 50px;
  }
}

.contacts-text__title h3 {
  font-size: 22px;
  font-weight: 900;
  line-height: 30px;
}

.contacts-text__item {
  width: 33%;
  margin-bottom: 50px;
}

.contacts-text__item--col-1 {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .contacts-text__item {
    width: 50%;
  }
}

@media screen and (max-width: 560px) {
  .contacts-text__item {
    width: 100%;
  }
}

.contacts-text__content {
  color: #797979;
  font-size: 14px;
}

.contacts-text__content:nth-child(2) {
  margin-top: 25px;
}

.contacts-text__content h4 {
  margin-bottom: 25px;
}

.contacts-text__content p,
.contacts-text__content span {
  line-height: 1.5;
  color: inherit;
  font-size: inherit;
}

.contacts-text__content span {
  display: block;
  margin-top: 15px;
}

.contacts-text__content a {
  display: block;
  color: #0072f6;
  line-height: 1.3;
}

.docs__title {
  margin-bottom: 70px;
}

.docs__item {
  display: block;
  float: left;
  margin-right: 50px;
  margin-bottom: 50px;
  height: 410px;
  width: -webkit-calc(100% / 3 - 34px);
  width: calc(100% / 3 - 34px);
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.docs__item:hover .docs__image {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (min-width: 1280px) {
  .docs__item:nth-child(3n + 1) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1279px) {
  .docs__item {
    width: -webkit-calc(100% / 2 - 25px);
    width: calc(100% / 2 - 25px);
  }

  .docs__item:nth-child(odd) {
    margin-right: auto;
  }
}

@media screen and (max-width: 1023px) {
  .docs__item {
    width: 100%;
  }
}

.docs__coming {
  cursor: auto;
}

@media screen and (max-width: 1279px) {
  .docs__coming {
    width: 100%;
    height: 200px;
  }
}

.docs__coming:hover .docs__image {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.docs__coming .docs__image {
  background-image: url(./img/blk-bg.jpg)
}

@media screen and (max-width: 1279px) {
  .docs__coming .docs__image {
    background-image: url(img/blk-bg@1024.jpg)
  }
}

.docs__coming .docs__heading {
  font-size: 32px;
  line-height: 30px;
  bottom: 86px;
  color: #fff;
}

@media screen and (max-width: 1279px) {
  .docs__coming .docs__heading {
    left: 31px;
  }
}

.docs__coming .docs__stripe {
  background-color: #fff;
}

@media screen and (max-width: 1279px) {
  .docs__coming .docs__stripe {
    left: 31px;
  }
}

.docs__image {
  background-image: url(img/image-5.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.docs__heading {
  position: absolute;
  bottom: 93px;
  font-size: 18px;
  line-height: 24px;
  left: 17px;
  right: 28px;
  font-weight: 600;
  color: #e0e0e0;
}

@media screen and (max-width: 1023px) {
  .docs__heading {
    font-size: 20px;
  }
}

.docs__stripe {
  position: absolute;
  bottom: 53px;
  left: 17px;
  width: 60px;
  height: 6px;
  background-color: #0072f6;
}

.e-book-second-block {
  position: relative;
  padding: 50px 0;
  margin-bottom: 0;
  background: url(img/docs/second-bg.jpg);
  background-size: cover;
  background-position: 50%;
  color: #fff;
  z-index: 23;
}

.e-book-second-block .e-book-content {
  -ms-flex-negative: 1;
  flex-shrink: 1;
  margin-bottom: 30px;
}

.e-book-second-block .e-book-content h2,
.e-book-second-block .e-book-content h3 {
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 1.25;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.e-book-second-block .e-book-content h3 {
  font-size: 20px;
}

.e-book-second-block .e-book-content p {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.56;
  font-weight: 400;
  color: #fff;
}

.e-book-second-block .e-book-content ul {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
  margin: 0 0 30px;
  list-style: none;
}

.e-book-second-block .e-book-content ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #fff;
}

.e-book-second-block .e-book-content ul li:before {
  position: absolute;
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  background: #fff;
  left: 0;
  top: 7px;
}

.e-book-second-block .e-book-content .duration,
.e-book-second-block .e-book-content .webinar-about {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 15% 85%;
  grid-template-columns: 15% 85%;
  padding: 20px 20px 0;
}

.e-book-second-block .e-book-content .duration ul,
.e-book-second-block .e-book-content .webinar-about ul {
  margin-bottom: 0;
}

.e-book-second-block .e-book-content .duration .icon-wrapper,
.e-book-second-block .e-book-content .webinar-about .icon-wrapper {
  width: 40px;
  height: 40px;
}

.e-book-second-block .e-book-content .duration .icon-wrapper .webinar-icon,
.e-book-second-block
.e-book-content
.webinar-about
.icon-wrapper
.webinar-icon {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 768px) {
  .e-book-second-block .e-book-content .duration,
  .e-book-second-block .e-book-content .webinar-about {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }

  .e-book-second-block .e-book-content .duration .icon-wrapper,
  .e-book-second-block .e-book-content .webinar-about .icon-wrapper {
    justify-self: center;
    margin-bottom: 20px;
  }
}

.e-book-second-block .e-book-content .duration {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.e-book-second-block .e-book-content .webinar-about {
  border-bottom: 1px solid #fff;
}

@media (min-width: 768px) {
  .e-book-second-block {
    height: auto;
    padding-top: 80px;
  }

  .e-book-second-block .container {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .e-book-second-block .e-book-content {
    width: 50%;
    padding-right: 30px;
    margin-bottom: 0;
  }

  .e-book-second-block .ebook-form__container {
    width: 405px;
  }

  .e-book-second-block .ebook-form__container .fixed-ebook-form {
    width: 95%;
    padding: 30px 25px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .e-book-second-block #pined-form-main-tokenization section.fixed-ebook-form {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
  }
}

@media (min-width: 1040px) {
  .e-book-second-block .e-book-content {
    max-width: 560px;
  }

  .e-book-second-block .ebook-form__container {
    width: 455px;
  }

  .e-book-second-block .ebook-form__container .fixed-ebook-form {
    padding: 40px 50px;
  }
}

.e-book-last-block {
  background-color: #f4f4f4;
  padding: 45px 0;
}

.e-book-last-block h2,
.e-book-last-block h3 {
  max-width: 545px;
  text-align: center;
  margin: 0 auto 35px;
  font-size: 32px;
  line-height: 1.25;
  font-weight: 600;
  text-transform: uppercase;
  color: #323232;
}

.e-book-last-block h3 {
  font-size: 26px;
}

.e-book-last-block ul li {
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
  font-size: 15px;
  line-height: 1.25;
  color: #323232;
}

.e-book-last-block ul li:before {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 5px;
  background: #1476f2;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .e-book-last-block {
    padding: 90px 0;
  }

  .e-book-last-block p {
    margin: 50px auto 0;
    max-width: 84%;
  }

  .e-book-last-block h2 {
    margin-bottom: 70px;
  }

  .e-book-last-block .wrap-ul {
    max-width: 84%;
    margin: 0 auto;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .e-book-last-block .wrap-ul ul {
    width: 49%;
    max-width: 375px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
}

.ebook {
  background-image: url(img/bg-ebook.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 55px 0 39px;
}

@media (max-width: 1023px) {
  .ebook {
    position: relative;
  }
}

@media (max-width: 1023px) {
  .ebook .box-left {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .ebook .box-left {
    width: 100%;
  }
}

.ebook .box-right {
  width: 100%;
}

.ebook__heading {
  text-transform: uppercase;
  position: relative;
  color: #0072f6;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
}

@media screen and (min-width: 768px) {
  .ebook__heading br {
    display: none;
  }
}

@media (max-width: 767px) {
  .ebook__heading {
    font-size: 30px;
    line-height: 39px;
    margin-bottom: 40px;
  }
}

.ebook__heading:before {
  content: "";
  background-image: url(img/icon-ebook.svg);
  position: absolute;
  left: 0;
  bottom: -68px;
  width: 37px;
  height: 50px;
}

@media (max-width: 767px) {
  .ebook__heading:before {
    top: 10px;
    right: 0;
    left: auto;
  }
}

.ebook__title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
}

@media (max-width: 1023px) {
  .ebook__title br {
    display: none;
  }
}

.ebook__lead {
  font-size: 12px;
  line-height: 18px;
  color: #bababa;
  margin-top: 20px;
}

@media screen and (max-width: 1023px) {
  .ebook__lead {
    margin-top: 6px;
    font-size: 10px;
  }
}

.ebook__form {
  margin: 20px 0 0;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .ebook__form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.ebook__form .form-row {
  position: relative;
  margin-right: 10px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.ebook__form .form-row .error:not(input) {
  position: absolute;
  top: 16px;
  right: 15px;
  color: #333;
}

.ebook__form .form-row .error:not(label) {
  -webkit-box-shadow: inset 0 0 0 4px #ffa8a1;
  box-shadow: inset 0 0 0 4px #ffa8a1;
  background-color: #fff1f2;
}

.ebook__form .form-row input {
  width: 100%;
  border: none;
  min-height: 45px;
  color: #000;
  vertical-align: middle;
}

.ebook__form .form-row input:not([type="submit"]) {
  padding-left: 17px;
}

.ebook__form .form-row input[type="submit"] {
  background-color: #0072f6;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  letter-spacing: 1.2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 300;
}

.ebook__form .form-row input[type="submit"]:hover {
  background-color: #0062d3;
}

.ebook__form .flex-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

@media screen and (max-width: 767px) {
  .ebook__form .flex-1 {
    margin-right: 0;
  }
}

.ebook__form .flex-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

@media screen and (max-width: 1023px) {
  .ebook__form .flex-2 {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .ebook__form .flex-2 {
    margin-top: 15px;
  }
}

.ebook__form .flex-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  margin-right: 0;
}

@media screen and (max-width: 1023px) {
  .ebook__form .flex-3 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .ebook__form .flex-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-top: 15px;
  }
}

.ebook__form .flex-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media screen and (max-width: 1023px) {
  .ebook__form .flex-4 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: -webkit-calc(50% + 5px);
    width: calc(50% + 5px);
    padding-right: 10px;
  }

  .ebook__form .flex-4 br {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .ebook__form .flex-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: 100%;
    padding: 0;
  }
}

.js-message {
  font-size: 18px;
  padding: 15px 0;
  color: #aede76;
  display: none;
}

.flickity-enabled {
  position: relative;
  opacity: 1 !important;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #fff;
  background: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flickity-prev-next-button:hover {
  background: #fff;
}

.flickity-prev-next-button:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 5px #09f;
  box-shadow: 0 0 0 5px #09f;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  width: 10px;
  height: 10px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
}

.flickity-page-dots {
  bottom: 25px;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 8px;
  background-color: transparent;
  cursor: pointer;
  opacity: 1 !important;
  border-radius: 0 !important;
  border: 2px solid #fff;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
  background: #fff;
}

.flickity-prev-next-button {
  background-position: 0 0;
  width: 25px;
  height: 38px;
  padding: 0;
  border-radius: 0;
  border: none;
}

.flickity-prev-next-button,
.flickity-prev-next-button:hover {
  background-image: url(img/arrow.png);
  background-color: transparent;
}

.flickity-prev-next-button.next {
  right: 64px;
  background-position: -77px 0;
}

.flickity-prev-next-button.next:hover {
  background-position: -51px 0;
}

@media screen and (max-width: 1280px) {
  .flickity-prev-next-button.next {
    right: 25px;
  }
}

@media screen and (max-width: 767px) {
  .flickity-prev-next-button.next {
    right: 14px;
  }
}

.flickity-prev-next-button.previous {
  left: 64px;
  background-position: 0 0;
}

.flickity-prev-next-button.previous:hover {
  background-position: -25px 0;
}

@media screen and (max-width: 1280px) {
  .flickity-prev-next-button.previous {
    left: 25px;
  }
}

@media screen and (max-width: 767px) {
  .flickity-prev-next-button.previous {
    left: 14px;
  }
}

.flickity-prev-next-button svg {
  display: none;
}

.footer {
  padding-top: 70px;
  padding-bottom: 30px;
}

.footer__flexbox {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  font-size: 14px;
  line-height: 1.71;
}

@media screen and (max-width: 1023px) {
  .footer__flexbox {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .footer__flexbox {
    line-height: 2.43;
    margin: auto;
  }
}

.footer__copyright {
  font-size: 14px;
  margin-top: 65px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 425px) {
  .footer__copyright {
    margin-top: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.footer__year {
  font-size: inherit;
  font-weight: 300;
  color: #c2c2c2;
}

@media screen and (max-width: 767px) {
  .footer__year {
    display: block;
    margin-bottom: 10px;
  }
}

.footer__privacy {
  color: #949494;
  font-weight: 300;
  border-left: 1px solid #e3e3e3;
  padding-left: 15px;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .footer__privacy {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }
}

.footer__menu {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 5;
  -ms-flex: 5;
  flex: 5;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.31;
  color: #323232;
}

@media screen and (max-width: 1023px) {
  .footer__menu {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .footer__menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.footer__menu .has-dropdown {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 1023px) {
  .footer__menu .has-dropdown {
    padding-bottom: 40px;
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .footer__menu .has-dropdown {
    padding: 0;
    width: 100%;
    border-top: 1px solid hsla(0, 0%, 59%, 0.15);
  }

  .footer__menu .has-dropdown:last-child {
    border-bottom: 1px solid hsla(0, 0%, 59%, 0.15);
  }
}

.footer__menu .has-dropdown > a {
  display: inline-block;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  font-size: 14px;
  line-height: 1;
  color: #323232;
}

@media screen and (max-width: 767px) {
  .footer__menu .has-dropdown > a:before {
    content: "";
    background-image: url(img/icons/arrow-right.svg);
    background-size: contain;
    position: absolute;
    right: 0;
    top: 50%;
    height: 14px;
    width: 7px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }
}

@media screen and (max-width: 767px) {
  .footer__menu .has-dropdown > a {
    line-height: 2.86;
    margin-bottom: 0;
    width: 100%;
  }
}

.footer__menu .has-dropdown .arrow-down:before {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}

@media screen and (max-width: 767px) {
  .footer__menu .sub-menu {
    padding-left: 10px;
    height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

.footer__menu .sub-menu a {
  color: #949494;
  font-weight: 300;
}

.footer__menu .sub-menu a:hover {
  color: #0072f6;
}

.footer__about {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  .footer__about {
    margin-top: 40px;
    padding: 0;
  }
}

.footer__heading {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 5px;
}

.footer__office:nth-child(odd) {
  margin-top: 15px;
}

.footer__city {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  color: #000;
  margin-bottom: 5px;
}

.footer__phone {
  display: block;
}

.footer__phone,
.footer__phone a {
  line-height: 1.3;
  color: #949494;
}

.footer__street {
  display: block;
  font-weight: 300;
  font-size: 13px;
  line-height: 1.54;
  color: #323232;
  opacity: 0.7;
}

@media screen and (max-width: 425px) {
  .footer__copy-container {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    text-align: center;
    margin-top: 35px;
  }
}

@media screen and (max-width: 425px) {
  .footer__social {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .footer__social .social-box__list a:last-child {
    margin-right: 0;
  }
}

.form {
  margin-top: 20px;
  margin-left: -7.5px;
  margin-right: -7.5px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form--ebook {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.form--ebook .form-group {
  padding: 0;
  margin-bottom: 20px;
}

.form--ebook .form-group:last-child {
  margin-bottom: 0;
}

.form--ebook .form-group input[type="text"] {
  width: 100%;
  height: 40px;
  border: 1px solid hsla(0, 0%, 59%, 0.25);
  font-size: 15px;
  line-height: 1.67;
  text-align: left;
  color: #323232;
}

.form--ebook .form-group input[type="text"]::-webkit-input-placeholder {
  font-size: 15px;
  line-height: 1.67;
  text-align: left;
  color: #777;
  font-weight: 300;
  font-family: Montserrat;
}

@media screen and (max-width: 767px) {
  .form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

.form__title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
}

.form__lead {
  font-size: 12px;
  line-height: 18px;
  color: #bababa;
  padding: 7.5px;
}

@media screen and (max-width: 1023px) {
  .form__lead {
    font-size: 10px;
  }
}

.form__submit {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-width: 140px;
  font-size: 13px;
  padding: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
  background: #1476f2;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  white-space: nowrap;
  display: block;
  width: 100%;
}

.form__submit [class*=" isoi-"],
.form__submit [class^="isoi-"] {
  font-size: 12px;
  color: #1476f2;
  padding-left: 12px;
  display: block;
}

.form__submit:hover {
  background: #005ed7;
  border-color: #005ed7;
  color: #fff;
}

.form__submit:hover [class*=" isoi-"],
.form__submit:hover [class^="isoi-"] {
  color: #fff;
}

.form__submit:active {
  background: #323232;
  border-color: #323232;
  color: #fff;
}

.form__submit:active [class*=" isoi-"],
.form__submit:active [class^="isoi-"] {
  color: #fff;
}

.form__submit--green {
  background: #17ca8e;
}

.form__submit--green:hover {
  background: #15da98;
}

.form__submit--contact {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-width: 140px;
  font-size: 13px;
  padding: 0 20px;
  text-transform: uppercase;
  background: #1476f2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  white-space: nowrap;
  background: transparent;
  border: 2px solid #fff;
  height: 50px;
  min-width: 170px;
  color: #fff;
  background: #17ca8e;
  border: 2px solid #17ca8e;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 0.81;
  text-align: center;
  display: inline-block;
  z-index: 10;
}

.form__submit--contact [class*=" isoi-"],
.form__submit--contact [class^="isoi-"] {
  font-size: 12px;
  color: #1476f2;
  padding-left: 12px;
  display: block;
}

.form__submit--contact:hover {
  background: #005ed7;
  border-color: #005ed7;
}

.form__submit--contact:hover [class*=" isoi-"],
.form__submit--contact:hover [class^="isoi-"] {
  color: #fff;
}

.form__submit--contact:active {
  background: #323232;
  border-color: #323232;
  color: #fff;
}

.form__submit--contact:active [class*=" isoi-"],
.form__submit--contact:active [class^="isoi-"] {
  color: #fff;
}

.form__submit--contact:hover {
  color: #323232;
  background: #fff;
  border: 2px solid #fff;
  color: #fff;
  background: #15da98;
  border: 2px solid #15da98;
}

.form__submit--contact:active {
  color: #323232;
  background: hsla(0, 0%, 88%, 0.8);
  border: 2px solid hsla(0, 0%, 88%, 0.1);
}

@media screen and (max-width: 1023px) {
  .form__submit--contact {
    max-width: 355px;
  }
}

@media screen and (max-width: 320px) {
  .form__submit--contact {
    font-size: 11px;
    padding: 0;
  }
}

.form__submit--contact-page {
  max-width: 385px;
}

.form__valid-fill {
  color: #fff;
  font-size: 14px;
  text-align: right;
  padding-right: 7.5px;
  width: 100%;
}

.form__valid-fill--black {
  color: #000;
}

.form__inform-data {
  color: #323232;
  opacity: 0.6;
}

.form__checkbox-label,
.form__inform-data {
  display: inline-block;
  position: relative;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.form__checkbox-label {
  padding-left: 30px;
  color: #fff;
  cursor: pointer;
}

.form__checkbox-label:after,
.form__checkbox-label:before {
  background-color: rgba(50, 50, 50, 0.03);
  border: 1px solid #eaeaea;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 17px;
  width: 17px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.form__checkbox-label:after {
  content: "";
  color: rgba(0, 0, 0, 0.87);
  opacity: 0;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 4px;
  left: 4px;
  border: 2px solid #1476f2;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.form__checkbox-label--gray {
  color: rgba(50, 50, 50, 0.6);
}

.form__checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  outline: 0;
  z-index: -1;
  width: 17px;
  height: 17px;
}

.form__checkbox:checked ~ label:after {
  opacity: 1;
}

.form__select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  color: rgba(50, 50, 50, 0.6);
  border-radius: 0;
  border: none;
  cursor: pointer;
  height: 40px;
  width: 100%;
  padding-left: 17px;
  line-height: 1.5;
}

.form__appearance:before {
  content: "";
  background-image: url(img/arrow-1-1.svg);
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 10px;
  height: 7px;
}

.form__select-container {
  width: 100%;
}

.form__select-container:before {
  content: "";
  background-image: url(img/select-arrow-new.svg);
  background-size: contain;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
}

.form__select-container .form__select {
  height: 40px;
  border: 1px solid hsla(0, 0%, 59%, 0.25);
  font-size: 15px;
  line-height: 1.67;
  text-align: left;
  color: #323232;
}

.form__textarea {
  border: none;
  border-radius: 0;
  min-height: 120px;
  padding: 17px 35px 0 17px;
  width: 100%;
  resize: none;
}

.form__footer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 10px;
  height: 30px;
}

.form__footer_consent {
  margin: 15px 0 25px;
}

.form__footer_consent-link {
  text-decoration: underline;
}

.form__footer_consent-link:hover {
  text-decoration: none;
}

.form__box {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .form__box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

.form__box .form-group {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .form__box .form-group {
    width: 100%;
  }
}

.form__box--contact-page {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.form__box--contact-page .form-group {
  width: 100%;
  margin-right: 0;
}

.form-group {
  color: #000;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
  padding: 7.5px;
}

.form-group:after {
  content: "";
  position: absolute;
  -webkit-transform: translate(150%, -50%);
  -ms-transform: translate(150%, -50%);
  transform: translate(150%, -50%);
  -webkit-transition: -webkit-transform 0.1s ease;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
  right: 15px;
  top: 50%;
}

.form-group .clear-attach {
  display: none;
  position: absolute;
  top: 5px;
  left: -5px;
  padding: 0;
  border: none;
  color: #1476f2;
  width: 20px;
  background-color: transparent;
}

.form-group input[type="text"] {
  border: none;
  border-radius: 0;
  height: 45px;
  line-height: 45px;
  vertical-align: middle;
  padding: 0 35px 0 20px;
  width: 100%;
}

.form-group input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form-group.has-error {
  color: #e74c3c;
}

.form-group.has-error:before {
  display: none;
}

.form-group.has-error:after {
  background-image: url(img/icons/has-error.svg);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 17px;
  width: 20px;
}

.form-group.has-error .upload__label--white {
  color: #e74c3c;
}

.form-group.has-success:not(.js-emptyFiles) {
  color: #26ad5f;
}

.form-group.has-success:not(.js-emptyFiles):before {
  display: none;
}

.form-group.has-success:not(.js-emptyFiles):after {
  background-image: url(img/icons/has-success.svg);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 12px;
  width: 16px;
}

.form-group.has-success:not(.js-emptyFiles) .upload__label--white {
  color: #26ad5f;
}

.form-group label.has-error {
  display: none !important;
}

.contact__error {
  display: none;
  color: #cd5c5c;
  text-align: center;
  padding: 5px;
}
.contact__success {
  display: none;
  color: #26ad5f;
  text-align: center;
  padding: 5px;
}

.upload__label {
  background-color: transparent;
  border: 2px dashed #d0d0d0;
  cursor: pointer;
  text-align: center;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 45px;
  line-height: 45px;
  padding: 0 35px 0 50px;
  width: 100%;
}

.upload__label i.isoi-upload {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 17px;
  font-size: 17px;
  left: -30px;
}

.upload__label span {
  position: relative;
  pointer-events: none;
  line-height: 1;
}

.upload__label--white {
  border-color: hsla(0, 0%, 82%, 0.5);
  color: #fff;
}

.flex-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.flex-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.flex-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

@media screen and (max-width: 1023px) {
  .flex-3 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
}

@media screen and (max-width: 767px) {
  .flex-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-left: 0;
  }
}

.flex-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 1023px) {
  .flex-4 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: 50%;
  }

  .flex-4 br {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .flex-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    width: 100%;
  }
}

.js-thx:not(.thank) {
  color: #000;
  display: none;
  font-size: 18px;
  padding: 15px 0;
  width: 100%;
}

.js-delay {
  display: block;
}

.thx--contact-page {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .form--home {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
}

.form--home .form-group {
  margin: 15px 0 0;
}

@media screen and (max-width: 1023px) {
  .form--home .form-group {
    margin: 15px 0 0;
  }
}

.form--contact {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
  display: none;
}

.form--contact--rating {
  max-width: 455px;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form--contact .no-validation-icon {
  margin-bottom: 0;
  width: 100%;
}

.form--contact .no-validation-icon:after {
  display: none;
}

.form--contact-page {
  max-width: 600px;
  margin: -7.5px;
}

.form-spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.form-spinner > div {
  background-color: #0072f6;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: form-spinner 1.2s infinite ease-in-out;
  animation: form-spinner 1.2s infinite ease-in-out;
}

.form-spinner.js-show {
  opacity: 1;
}

.form-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.form-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.form-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.form-spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

select::-ms-expand {
  display: none;
}

@-webkit-keyframes form-spinner {
  0%,
  40%,
  to {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes form-spinner {
  0%,
  40%,
  to {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.form-newsletter {
  background-color: #008b8b;
  height: 300px;
  margin: 70px 0 0;
}

.form-small {
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .form-small {
    margin: 0 -20px;
  }
}

.form-small__content {
  background-position: 50% 80%;
  background-size: cover;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  padding: 40px 40px 30px;
  z-index: 1;
}

@media screen and (max-width: 1023px) {
  .form-small__content {
    padding: 30px;
  }
}

.form-small__title {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 15px;
}

.form-small__lead {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: #fff;
}

.form-small__lead,
.form-small__submit {
  margin-bottom: 25px;
}

.form-small__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.form-small__tag--ebook,
.form-small__tag--newsletter {
  display: block;
  margin-bottom: 15px;
}

.form-small__body {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: -7.5px;
}

.contact-section,
.form-small__body {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
}

.contact-section {
  -webkit-box-orient: horizontal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .contact-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.contact-section .contact-about-wrap {
  width: 55%;
  padding: 44px 0 58px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 1400px) {
  .contact-section .contact-about-wrap {
    padding: 25px 0 30px;
  }
}

@media screen and (max-width: 767px) {
  .contact-section .contact-about-wrap {
    width: 100%;
  }
}

.contact-section .contact-about-wrap .contact-about-header {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.13;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .contact-section .contact-about-wrap .contact-about-header {
    max-width: 350px;
  }
}

.contact-section .contact-about-wrap .contact-about-stats {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  width: 86.364%;
  max-width: 570px;
  height: 150px;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  border-radius: 14px;
}

@media screen and (max-width: 1400px) {
  .contact-section .contact-about-wrap .contact-about-stats {
    margin: 0 auto 70px;
  }
}

@media screen and (max-width: 1200px) {
  .contact-section .contact-about-wrap .contact-about-stats {
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .contact-section .contact-about-wrap .contact-about-stats {
    margin: 70px auto;
  }
}

@media screen and (max-width: 480px) {
  .contact-section .contact-about-wrap .contact-about-stats {
    width: 100%;
  }
}

.contact-section .contact-about-wrap .contact-about-stats .stat-item {
  -ms-flex-preferred-size: 33.33333%;
  flex-basis: 33.33333%;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
  color: #fff;
}

.contact-section
.contact-about-wrap
.contact-about-stats
.stat-item:not(:last-child) {
  border-right: 1px solid hsla(0, 0%, 100%, 0.5);
}

.contact-section .contact-about-wrap .contact-about-stats .stat-item .stat-num {
  display: block;
  margin: 0 auto;
  font-size: 60px;
}

@media screen and (max-width: 1023px) {
  .contact-section
  .contact-about-wrap
  .contact-about-stats
  .stat-item
  .stat-num {
    font-size: 40px;
  }
}

@media screen and (max-width: 480px) {
  .contact-section
  .contact-about-wrap
  .contact-about-stats
  .stat-item
  .stat-num {
    font-size: 35px;
    line-height: 1.29;
  }
}

.contact-section
.contact-about-wrap
.contact-about-stats
.stat-item
.stat-desc {
  display: block;
  margin: 0 auto;
  width: 96px;
  font-size: 15px;
  line-height: 1;
  text-transform: uppercase;
}

@media screen and (max-width: 1023px) {
  .contact-section
  .contact-about-wrap
  .contact-about-stats
  .stat-item
  .stat-desc {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .contact-section
  .contact-about-wrap
  .contact-about-stats
  .stat-item
  .stat-desc {
    font-size: 10px;
    width: 78px;
  }
}

.contact-section .form-wrap__flexbox {
  position: relative;
  width: 45%;
  max-width: 455px;
  height: 690px;
  padding: 50px;
  background-color: #fff;
}

@media screen and (max-width: 1400px) {
  .contact-section .form-wrap__flexbox {
    padding: 33px 50px;
    height: 630px;
  }
}

@media screen and (max-width: 1023px) {
  .contact-section .form-wrap__flexbox {
    padding: 40px;
    height: 727px;
  }
}

@media screen and (max-width: 767px) {
  .contact-section .form-wrap__flexbox {
    padding: 30px;
    width: 100%;
    height: 740px;
  }
}

.contact-section .form-wrap__flexbox .form__box {
  width: 100%;
  max-width: 355px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 1023px) {
  .contact-section .form-wrap__flexbox .form__box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
}

.contact-section .form-wrap__flexbox .form__box .form-group {
  max-width: 170px;
}

@media screen and (max-width: 1023px) {
  .contact-section .form-wrap__flexbox .form__box .form-group {
    max-width: 355px;
  }
}

.contact-section .form-wrap__flexbox .form-group {
  position: relative;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  width: 100%;
  max-width: 355px;
  margin-bottom: 10px;
  padding: 0;
  color: rgba(50, 50, 50, 0.6);
  border: none;
}

@media screen and (max-width: 1400px) {
  .contact-section .form-wrap__flexbox .form-group {
    margin-bottom: 14px;
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .contact-section .form-wrap__flexbox .form-group {
    margin-bottom: 18px;
  }
}

.contact-section .form-wrap__flexbox .form-group .form__select {
  height: 40px;
  border: 1px solid rgba(50, 50, 50, 0.1);
}

@media screen and (max-width: 1400px) {
  .contact-section .form-wrap__flexbox .form-group .form__select {
    height: 33px;
  }
}

.contact-section .form-wrap__flexbox .form-group input[type="text"] {
  padding: 0 35px 0 16px;
  height: 40px;
  width: 100%;
  max-width: 355px;
  vertical-align: middle;
  color: #323232;
  border: 1px solid rgba(50, 50, 50, 0.1);
  -moz-appearance: none;
  -webkit-appearance: none;
}

@media screen and (max-width: 1400px) {
  .contact-section .form-wrap__flexbox .form-group input[type="text"] {
    height: 33px;
  }
}

.contact-section .form-wrap__flexbox .form-group input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  border: 1px solid rgba(50, 50, 50, 0.1);
}

.contact-section .form-wrap__flexbox .form-group--description {
  padding: 0;
  margin-bottom: 10px;
}

.contact-section .form-wrap__flexbox .form-group--description .form__textarea {
  color: #323232;
  padding: 10px 35px 0 16px;
  height: 100px;
  min-height: auto;
  border: 1px solid rgba(50, 50, 50, 0.1);
  -moz-appearance: none;
  -webkit-appearance: none;
}

@media screen and (max-width: 1400px) {
  .contact-section
  .form-wrap__flexbox
  .form-group--description
  .form__textarea {
    height: 85px;
  }
}

.contact-section .form-wrap__flexbox .form__footer {
  margin: 0 auto 30px;
  width: 100%;
  max-width: 355px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 1400px) {
  .contact-section .form-wrap__flexbox .form__footer {
    margin: 0 auto 27px;
  }
}

.contact-section .form-wrap__flexbox .form__footer .no-validation-icon {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 110px;
  margin: 0;
  width: auto;
  border: none;
}

.contact-section .form-wrap__flexbox .form__footer .form__checkbox-label {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: rgba(50, 50, 50, 0.6);
}

@media screen and (max-width: 420px) {
  .contact-section .form-wrap__flexbox .form__footer .form__checkbox-label {
    font-size: 12px;
    line-height: 2.2;
  }
}

.contact-section
.form-wrap__flexbox
.form__footer
.form__checkbox-label:before {
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  background-color: rgba(50, 50, 50, 0.03);
  border: 1px solid #eaeaea;
}

.contact-section .form-wrap__flexbox .form__footer .form__checkbox-label:after {
  top: 8px;
  left: 6px;
  border-left: 2px solid #1476f2;
  border-bottom: 2px solid #1476f2;
}

.contact-section .form-wrap__flexbox .form__footer .upload-wrapper {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 120px;
  height: 30px;
  margin: 0;
  width: auto;
  border: none;
}

.contact-section
.form-wrap__flexbox
.form__footer
.upload-wrapper
.upload__label {
  padding: 0;
  height: 25px;
  font-size: 15px;
  line-height: 1.67;
  color: #1476f2;
  border: none;
}

.contact-section
.form-wrap__flexbox
.form__footer
.upload-wrapper
.upload__label
i {
  padding-right: 12px;
}

@media screen and (max-width: 420px) {
  .contact-section
  .form-wrap__flexbox
  .form__footer
  .upload-wrapper
  .upload__label {
    font-size: 12px;
  }
}

.contact-section .form-spinner {
  position: absolute;
  bottom: 40px;
}

.form-choose-file {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 125px;
  height: 30px;
  margin: 0;
  width: auto;
  border: none;
}

@media screen and (max-width: 767px) {
  .form-choose-file {
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
  }
}

.form-choose-file .clear-attach {
  display: none;
  position: absolute;
  top: 10px;
  left: -5px;
  padding: 0;
  border: none;
  color: #1476f2;
  width: 20px;
  background-color: transparent;
}

.form-choose-file .uploaded__label {
  padding: 0;
  height: 25px;
  font-size: 15px;
  line-height: 1.67;
  color: #1476f2;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .form-choose-file .uploaded__label {
    font-size: 14px;
  }
}

.form-choose-file .uploaded__label i {
  padding-right: 12px;
}

.agreement__text {
  opacity: 0.46;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #323232;
  margin-top: 20px;
}

.agreement__text a {
  color: #323232 !important;
  text-decoration: underline;
}

.privacy__text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #323232 !important;
  opacity: 0.6;
  margin-top: 20px;
}

.privacy__text a {
  color: #323232 !important;
  text-decoration: underline;
}

.form-wrap {
  background-image: url(img/bg-ebook.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 55px 0 39px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1023px) {
  .form-wrap {
    position: relative;
  }
}

.form-wrap__flexbox {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.form-wrap__flexbox--contact-page {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: auto;
}

@media screen and (max-width: 1023px) {
  .form-wrap__flexbox--contact-page {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.form-wrap__left {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 10%;
  flex: 1 1 10%;
}

.form-wrap__right {
  -webkit-box-flex: 4;
  -ms-flex: 4;
  flex: 4;
}

@media screen and (min-width: 768px) {
  .form-wrap__right {
    min-height: 161px;
  }
}

@media screen and (max-width: 1023px) {
  .form-wrap__right {
    -webkit-box-flex: 3;
    -ms-flex: 3;
    flex: 3;
  }
}

.form-wrap__title {
  text-transform: uppercase;
  position: relative;
  color: #0072f6;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
}

@media (max-width: 767px) {
  .form-wrap__title {
    font-size: 30px;
    line-height: 39px;
    margin-bottom: 40px;
    padding-right: 40px;
  }
}

.form-wrap__title:before {
  content: "";
  background-image: url(img/icon-ebook.svg);
  position: absolute;
  left: 0;
  bottom: -68px;
  width: 37px;
  height: 50px;
}

@media (max-width: 767px) {
  .form-wrap__title:before {
    top: 10px;
    right: 0;
    left: auto;
  }
}

.form-wrap__lead {
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 10px;
  width: 100%;
}

.form-wrap--nobg {
  background: none;
  color: #000;
  padding: 0;
  width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form-wrap--nobg:nth-child(2n) {
  margin-top: 70px;
}

.form-wrap__title--home {
  color: #000;
  font-size: 26px;
  line-height: 1;
  margin: 0;
  text-transform: none;
}

@media screen and (max-width: 767px) {
  .form-wrap__title--home {
    font-size: 24px;
  }
}

.form-wrap__title--home:before {
  display: none;
}

.form-wrap--contact {
  padding: 50px 0;
  background-image: url(./img/form-ratings/bg-contact-form.jpg);
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .form-wrap--contact {
    padding: 30px 0;
  }
}

@media screen and (max-width: 767px) {
  .form-wrap--contact {
    padding: 50px 0;
    background-image: url(./img/form-ratings/bg-contact-form__mobile.jpg)
  }
}

.form-wrap__title--contact {
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
}

.form-wrap__title--contact span {
  color: #0072f6;
}

@media screen and (max-width: 767px) {
  .form-wrap__title--contact {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 35px;
    padding: 0;
  }
}

.form-wrap__title--contact:before {
  display: none;
}

.form-wrap__title--contact-page {
  color: #000;
  font-size: 22px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 30px;
  width: 294px;
}

.form-wrap__title--contact-page:before {
  display: none;
}

@media screen and (max-width: 1023px) {
  .form-wrap__title--contact-page {
    margin-bottom: 50px;
    width: 100%;
  }
}

.form-wrap--contact-page {
  background-image: none;
  padding: 70px 0;
}

@media screen and (max-width: 767px) {
  .form-wrap--contact-page {
    padding: 50px 0;
  }
}

.grid {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.no-padding-10 {
  margin: -10px;
}

.no-padding-20 {
  margin: -20px;
}

@media screen and (max-width: 767px) {
  .no-padding-20 {
    margin: 0 -20px;
  }
}

.grid--center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.grid--space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.grid--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.column {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.column-three {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 33%;
  flex: 1 1 33%;
  max-width: 33%;
}

.column-flex-3 {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3;
}

.column-flex-4 {
  -webkit-box-flex: 4;
  -ms-flex: 4;
  flex: 4;
}

.column-flex-2 {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

@media screen and (max-width: 1023px) {
  .column-flex-2 {
    -webkit-box-flex: 3;
    -ms-flex: 3;
    flex: 3;
  }
}

.column--half {
  max-width: 50%;
}

@media screen and (max-width: 1023px) {
  .column--half {
    max-width: 100%;
  }
}

.column--flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.column--flex-center,
.column--flex-end {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.column--flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.p-right-20 {
  padding-right: 20px;
}

.p-left-20 {
  padding-left: 20px;
}

.p-10 {
  padding: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.p-top-20 {
  padding-top: 20px;
}

.p-top-40 {
  padding-top: 40px;
}

.p-top-150 {
  padding-top: 150px;
}

@media screen and (max-width: 1023px) {
  .column-three {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .column-three {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
  }
}

.header {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding: 200px 0 100px;
  height: 630px;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.header--services {
  background-image: url(./img/new/5fNmWej4tAA.jpg);
}

.header--solutions {
  background-image: url(./img/solutions.jpg);
}

.header--solutions-main {
  /*background-image: url(./img/bg-solutions.jpg);*/
  background-image: url(./media/slide_4-2x.19519b1e.jpg);
}

.header--technologies {
  background-image: url(./img/technologies.jpg);
}

.header--platforms {
  background-image: url(./img/platforms.jpg);
}
.header--clients {
  background-image: url(./img/new/clients-8439697.jpg);
}
.header--industries {
  background-image: url(./img/new/ar-vr-showcase.jpg);
}
.hero--600 {
  background-image: url(./img/new/ITinfra.jpg);
}
.hero--601 {
  background-image: url(./img/bg-hero-platforms.jpg);
}

.header .box-left {
  float: left;
  max-width: 655px;
  width: 100%;
  margin-right: 85px;
}

.header .box-right {
  float: left;
  max-width: 350px;
  width: 100%;
}

@media (max-width: 1215px) {
  .header {
    padding: 240px 0 120px;
    height: auto;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 100px 0 35px;
  }
}

@media (max-width: 1215px) and (min-width: 1024px) {
  .header .box-left {
    max-width: 440px;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .header .box-left {
    max-width: 390px;
    margin-right: 50px;
  }
}

@media (max-width: 767px) {
  .header .box-left {
    float: none;
    max-width: 100%;
  }

  .header .box-left br {
    display: none;
  }
}

@media (max-width: 1023px) {
  .header .box-right {
    max-width: 184px;
  }
}

@media (max-width: 767px) {
  .header .box-right {
    float: none;
    max-width: 100%;
  }
}

.header__title {
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .header__title {
    margin-top: 100px;
  }
}

.header__lead {
  font-size: 18px;
  color: #fff;
  opacity: 0.8;
  max-width: 840px;
}

@media screen and (max-width: 1024px) {
  .header__lead {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .header__lead {
    font-size: 15px;
  }
}

.hero-slider {
  position: relative;
  display: block !important;
  height: 700px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hero-slider__content {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  max-width: 680px;
  width: 100%;
  padding-left: 140px;
}

@media screen and (max-width: 1023px) {
  .hero-slider__content {
    padding: 0 30px 0 93px;
  }
}

@media screen and (max-width: 767px) {
  .hero-slider__content {
    max-width: 100%;
    padding: 0 25px;
  }
}

@media screen and (min-width: 1600px) {
  .hero-slider__content {
    margin-left: 25%;
    padding: 0;
  }
}

.hero-slider__overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.hero-slider__overlay.overlay-40 {
  background-color: rgba(0, 0, 0, 0.4);
}

.hero-slider__lead {
  font-size: 18px;
  line-height: 1.67;
}

.hero-slider.owl-loaded {
  opacity: 1 !important;
}

.hero-slider .owl-dots {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 60px;
  left: 140px;
}

@media screen and (max-width: 1023px) {
  .hero-slider .owl-dots {
    left: 30px;
  }
}

@media screen and (min-width: 1600px) {
  .hero-slider .owl-dots {
    left: 25%;
  }
}

.hero-slider .owl-dot span {
  background: hsla(0, 0%, 100%, 0.2);
  display: inline-block;
  height: 2px;
  width: 50px;
  margin-right: 20px;
}

.hero-slider .owl-dot.active span {
  border-left: 50px solid #fff;
}

.hero-slider__item {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  height: 700px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.hero-slider__item--1 {
  background-image: url(./img/new/SOFTTEAM.jpeg);
}

@media screen and (max-width: 767px) {
  .hero-slider__item--1 {
    background-image: url(./img/new/SOFTTEAM.jpeg);
    /*background-image: -webkit-image-set(url("img/home-header-slider/slide_1-1x.jpg") 1x, url("img/home-header-slider/slide_1-2x.jpg") 2x);*/
    /*background-image: image-set(url("img/home-header-slider/slide_1-1x.jpg") 1x, url("img/home-header-slider/slide_1-2x.jpg") 2x)*/
  }
}

.hero-slider__item--2 {
  background-image: url(./img/home-header-slider/slide_2.jpg);
}

@media screen and (max-width: 767px) {
  .hero-slider__item--2 {
    background-image: url(img/home-header-slider/slide_2-1x.jpg);
    background-image: -webkit-image-set(url("img/home-header-slider/slide_2-1x.jpg") 1x, url("img/home-header-slider/slide_2-2x.jpg") 2x);
    background-image: image-set(url("img/home-header-slider/slide_2-1x.jpg") 1x, url("img/home-header-slider/slide_2-2x.jpg") 2x)
  }
}

.hero-slider__item--3 {
  background-image: url(./img/home-header-slider/slide_3.jpg);
}

@media screen and (max-width: 767px) {
  .hero-slider__item--3 {
    background-image: url(img/home-header-slider/slide_3-1x.jpg);
    background-image: -webkit-image-set(url("img/home-header-slider/slide_3-1x.jpg") 1x, url("img/home-header-slider/slide_3-2x.jpg") 2x);
    background-image: image-set(url("img/home-header-slider/slide_3-1x.jpg") 1x, url("img/home-header-slider/slide_3-2x.jpg") 2x)
  }
}

.hero-slider__item--4 {
  background-image: url(./img/home-header-slider/slide_4.jpg);
}

@media screen and (max-width: 767px) {
  .hero-slider__item--4 {
    background-image: url(img/home-header-slider/slide_4-1x.jpg);
    background-image: -webkit-image-set(url("img/home-header-slider/slide_4-1x.jpg") 1x, url("img/home-header-slider/slide_4-2x.jpg") 2x);
    background-image: image-set(url("img/home-header-slider/slide_4-1x.jpg") 1x, url("img/home-header-slider/slide_4-2x.jpg") 2x)
  }
}

.hero-slider__item--5 {
  background-image: url(./img/home-header-slider/slide_5.jpg);
}

@media screen and (max-width: 767px) {
  .hero-slider__item--5 {
    background-image: url(img/home-header-slider/slide_5-1x.jpg);
    background-image: -webkit-image-set(url("img/home-header-slider/slide_5-1x.jpg") 1x, url("img/home-header-slider/slide_5-2x.jpg") 2x);
    background-image: image-set(url("img/home-header-slider/slide_5-1x.jpg") 1x, url("img/home-header-slider/slide_5-2x.jpg") 2x)
  }
}

.hero-slider__item--6 {
  background-image: url(./img/home-header-slider/slide_6.jpg);
}

@media screen and (max-width: 767px) {
  /*.hero-slider__item--6 {*/
  /*  background-image: url(/img/home-header-slider/slide_6-1x.jpg);*/
  /*  background-image: -webkit-image-set(url("/img/home-header-slider/slide_6-1x.jpg") 1x, url("/img/home-header-slider/slide_6-2x.jpg") 2x);*/
  /*  background-image: image-set(url("/img/home-header-slider/slide_6-1x.jpg") 1x, url("/img/home-header-slider/slide_6-2x.jpg") 2x)*/
  /*}*/
}

.hero-slider--nav .owl-nav .owl-next,
.hero-slider--nav .owl-nav .owl-prev {
  font-size: 0;
  position: absolute;
  background: hsla(0, 0%, 100%, 0) url(img/arrow-left-dark.svg) no-repeat 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 80px;
  width: 40px;
}

.hero-slider--nav .owl-nav .owl-prev {
  left: 21px;
}

.hero-slider--nav .owl-nav .owl-prev:hover {
  background: hsla(0, 0%, 100%, .06) url(img/arrow-right-light.svg) no-repeat 50%
}

.hero-slider--nav .owl-nav .owl-next,
.hero-slider--nav .owl-nav .owl-prev:hover {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.hero-slider--nav .owl-nav .owl-next {
  right: 21px;
}

.hero-slider--nav .owl-nav .owl-next:hover {
  background: hsla(0, 0%, 100%, .06) url(img/arrow-right-light.svg) no-repeat 50%;
  -webkit-transform: translateY(-50%) rotate(1turn);
  -ms-transform: translateY(-50%) rotate(1turn);
  transform: translateY(-50%) rotate(1turn);
}

.hero-slider.hero-slider--nav .hero-slider__content {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  max-width: 680px;
  width: 100%;
  padding-left: 140px;
}

@media screen and (max-width: 1023px) {
  .hero-slider.hero-slider--nav .hero-slider__content {
    padding: 0 30px 0 93px;
  }
}

@media screen and (max-width: 767px) {
  .hero-slider.hero-slider--nav .hero-slider__content {
    padding: 0 30px;
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .hero-slider.hero-slider--nav .hero-slider__content h1 {
    font-size: 23px;
  }

  .hero-slider.hero-slider--nav .hero-slider__content p {
    font-size: 17px;
    line-height: 1.6;
  }
}

@media screen and (min-width: 1600px) {
  .hero-slider.hero-slider--nav .hero-slider__content {
    margin-left: 25%;
    padding: 0;
  }
}

.hero-slider.hero-slider--nav .button--white {
  max-width: 210px;
  margin-top: 60px;
}

@media screen and (max-width: 1024px) {
  .hero-slider.hero-slider--nav .button--white {
    padding: 0 10px;
  }
}

@media screen and (max-width: 480px) {
  .hero-slider.hero-slider--nav .button--white {
    margin-top: 40px;
  }
}

.hero-slider.hero-slider--nav.owl-loaded {
  opacity: 1 !important;
}

.hero-slider.hero-slider--nav .owl-dots {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 60px;
  left: 140px;
}

@media screen and (max-width: 1023px) {
  .hero-slider.hero-slider--nav .owl-dots {
    left: 93px;
  }
}

@media screen and (min-width: 1600px) {
  .hero-slider.hero-slider--nav .owl-dots {
    left: 25%;
  }
}

@media screen and (max-width: 767px) {
  .hero-slider.hero-slider--nav .owl-dots {
    left: 30px;
    bottom: 30px;
  }
}

.hero-slider.hero-slider--nav .owl-dot span {
  background: hsla(0, 0%, 100%, 0.3);
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 30px;
  vertical-align: middle;
  border: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.hero-slider.hero-slider--nav .owl-dot.active span {
  width: 10px;
  height: 10px;
  background: #fff;
}

.webinar-tokenization {
  background-image: url(img/docs/webinar-tokenization.jpg)
}

.hero {
  background-color: rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: 50%;
  position: relative;
  height: 440px;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .hero {
    height: 100%;
    padding: 120px 0 80px;
  }
}

.hero--600 {
  height: 600px;
}

@media screen and (max-width: 762px) {
  .hero--600 {
    height: auto;
    min-height: 600px;
  }
}

.hero--600 .container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.hero--600 .scrollmagic-pin-spacer {
  width: 50%;
}

.hero--full {
  height: 100vh;
}

@media screen and (max-width: 767px) {
  .hero--full {
    height: auto;
  }
}

.hero--centered .container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.hero--centered .container .btnReg {
  font-size: 16px;
  height: 50px;
  background-image: -webkit-repeating-linear-gradient(
          20deg,
          #9a59c1,
          #3fb1fd 50%,
          #9a59c1 80%,
          #9a59c1
  );
  background-image: repeating-linear-gradient(
          70deg,
          #9a59c1,
          #3fb1fd 50%,
          #9a59c1 80%,
          #9a59c1
  );
  background-position: 100% 0;
  background-size: 200% 100%;
  margin: 20px 0;
  border-radius: 25px;
  -webkit-transition: 0.5s cubic-bezier(0.365, 0.335, 0, 1);
  transition: 0.5s cubic-bezier(0.365, 0.335, 0, 1);
}

.hero--centered .container .btnReg:hover {
  background-position: 0;
}

.hero--centered .hero__title {
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: #fff;
  width: 100%;
  max-width: 710px;
  margin: 0 0 49px;
}

@media screen and (max-width: 768px) {
  .hero--centered .hero__title {
    font-size: 33px;
    margin-bottom: 25px;
  }
}

.hero--centered .hero__tag {
  padding: 7px 14px;
  background: transparent;
  border: 0.5px solid #fff;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin: 100px auto 31px;
}

@media screen and (max-width: 768px) {
  .hero--centered .hero__tag {
    margin: 20px auto 30px;
  }
}

.hero--centered.hero--full {
  height: 100vh;
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
  .hero--centered.hero--full {
    height: auto;
  }
}

@media screen and (max-height: 650px) {
  .hero--centered.hero--full {
    height: auto;
  }
}

.hero__overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.hero__content {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 680px;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .hero__content--blockchain {
    padding-bottom: 140px;
  }
}

.hero__content--half {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  max-width: 680px;
  padding-right: 100px;
}

@media screen and (max-width: 1024px) {
  .hero__content--half {
    max-width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .hero__content--half {
    max-width: 680px;
  }
}

@media screen and (max-width: 600px) {
  .hero__content--half {
    max-width: 680px;
    padding-right: 0;
  }
}

.hero__lead {
  font-size: 18px;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .hero__lead {
    font-size: 16px;
  }
}

@media screen and (max-width: 465px) {
  .hero__lead {
    font-size: 15px;
    text-align: justify;
  }
}

.hero__date-block {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #1476f2;
  -webkit-box-shadow: 0 10px 25px 0 rgba(50, 50, 50, 0.4);
  box-shadow: 0 10px 25px 0 rgba(50, 50, 50, 0.4);
  overflow: hidden;
}

.hero__date-item {
  padding: 15px 25px 17px;
  color: #fff;
}

@media screen and (max-width: 465px) {
  .hero__date-item {
    padding: 15px 10px 17px;
  }
}

.hero__date-item:first-child {
  padding: 15px 22px 17px;
  background: #0657bd;
}

@media screen and (max-width: 465px) {
  .hero__date-item:first-child {
    padding: 15px 15px 17px;
  }
}

.hero__date-item:last-child {
  padding: 15px 33px 17px;
  border-left: 1px solid hsla(0, 0%, 100%, 0.15);
}

@media screen and (max-width: 465px) {
  .hero__date-item:last-child {
    padding: 15px 7px 17px;
  }
}

.hero__date-thik {
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 1.33;
  color: #93c3ff;
  opacity: 0.7;
}

.hero__date-bold,
.hero__date-thik {
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  text-align: center;
}

.hero__date-bold {
  font-size: 28px;
  line-height: 1;
  color: #fff;
}

@media screen and (max-width: 465px) {
  .hero__date-bold {
    font-size: 15px;
  }
}

.countdown {
  margin-bottom: 30px;
  padding: 0 10px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  border-radius: 8px;
}

@media screen and (max-width: 465px) {
  .countdown {
    margin-bottom: 60px;
  }
}

.timer-block {
  padding: 13px 15px 10px;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
}

.timer-block span {
  margin-top: 2px;
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  opacity: 0.6;
}

.industries-section {
  background: url(img/platforms/bg-industries.jpg) no-repeat 50% fixed;
  background-size: cover;
  height: 100%;
  overflow: hidden;
}

.intro {
  padding: 35px 0;
}

.intro__content {
  padding: 25px 0;
}

.intro__content p {
  margin-bottom: 15px;
  font-weight: 300;
}

.intro__content p:last-child {
  margin-bottom: 0;
}

.jobs {
  padding-bottom: 60px;
}

.jobs .container {
  padding: 0;
}

.jobs .hide {
  display: none;
}

.jobs__title {
  margin-bottom: 175px;
  position: relative;
  text-align: center;
  font-weight: 600;
  line-height: 1;
  color: #323232;
  text-transform: uppercase;
  padding-top: 300px;
}

@media screen and (max-width: 768px) {
  .jobs__title {
    font-size: 30px;
    margin-bottom: 0;
    padding-bottom: 35px;
    background: #1476f2;
    color: #fff;
    padding-top: 40px;
    margin-bottom: -2px;
  }
}

.jobs__flexbox {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.jobs__location {
  padding-right: 15px;
}

.jobs__column {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.jobs__heading h4 {
  color: #171717;
  font-size: 15px;
  padding-left: 25px;
  border-left: 5px solid #0072f6;
  text-transform: uppercase;
}

.jobs__heading h4,
.jobs__item {
  margin-bottom: 35px;
}

.jobs__item p {
  font-size: 12px;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.jobs__item a {
  position: relative;
}

.jobs__item a:before {
  content: "";
  height: 2px;
  -webkit-transition: right 0.1s ease;
  transition: right 0.1s ease;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 100%;
  background: #0072f6;
}

.jobs__item a:hover:before {
  right: 0;
}

.jobs__title-container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 970px;
  margin: auto;
  padding: 0 20px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  line-height: 2;
  color: #323232;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .jobs__title-container {
    display: none;
  }
}

.jobs__position-title {
  width: 100%;
  max-width: 700px;
}

@media screen and (max-width: 970px) {
  .jobs__position-title {
    max-width: 600px;
  }
}

@media screen and (max-width: 870px) {
  .jobs__position-title {
    max-width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .jobs__position-title {
    max-width: none;
    width: auto;
  }
}

.jobs__cv-block {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 70% 30%;
  grid-template-columns: 70% 30%;
  max-width: 740px;
  margin: 10px auto 0;
  background-color: #fff;
  padding: 50px 30px;
}

@media screen and (max-width: 600px) {
  .jobs__cv-block {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    grid-row-gap: 20px;
    text-align: center;
  }
}

.jobs__cv-block .button--send-cv {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

@media screen and (max-width: 600px) {
  .jobs__cv-block .button--send-cv {
    max-width: 200px;
    margin: 0 auto;
  }
}

.jobs__cv-header {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5;
}

.link-arrow {
  color: #000;
  background-image: url(img/link-arrow-black.png);
  background-repeat: no-repeat;
  background-size: 5px 7px;
  margin-left: 15px;
  width: 5px;
  height: 7px;
}

.error-page {
  height: 600px;
  position: relative;
}

.error-page:before {
  display: none;
  background-image: url(img/error-bg.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  content: "";
  max-width: 1200px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.error-page__content {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.error-page__code {
  position: absolute;
  top: 25%;
  font-weight: 600;
  font-size: 286px;
  line-height: 65px;
  color: #323232;
  opacity: 0.03;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .error-page__code {
    font-size: 200px;
  }
}

@media screen and (max-width: 380px) {
  .error-page__code {
    font-size: 152px;
  }
}

.error-page__text {
  font-weight: 600;
  font-size: 40px;
  line-height: 0.75;
  color: #323232;
  margin-bottom: 37px;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .error-page__text {
    font-size: 20px;
    line-height: 0.7;
  }
}

@media screen and (max-width: 380px) {
  .error-page__text {
    font-size: 18px;
    line-height: 1.2;
  }
}

.error-page__message {
  color: #323232;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 45px;
  max-width: 617px;
}

@media screen and (max-width: 767px) {
  .error-page__message {
    font-size: 18px;
    line-height: 1;
  }
}

@media screen and (max-width: 380px) {
  .error-page__message {
    font-size: 14px;
    line-height: 1.2;
  }
}

@media screen and (min-width: 1221px) {
  .js-scroll,
  .nav.is-hover {
    background-color: #fff;
    -webkit-box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .js-scroll .menu-item a,
  .nav.is-hover .menu-item a {
    color: #000;
  }

  .js-scroll .is-hover a,
  .nav.is-hover .is-hover a {
    color: #0072f6;
  }

  .js-scroll .logo,
  .nav.is-hover .logo {
    background-image: url(./img/logo.png);
  }
}

.nav {
  position: absolute;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  z-index: 99;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
}

@media (max-width: 1220px) {
  .nav {
    position: fixed;
  }
}

.nav .current-menu-item {
  color: #0072f6;
}

.nav .container {
  position: relative;
  height: 100%;
}

.nav__wrapper {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 1220px) {
  .nav__wrapper {
    display: none;
  }
}

.nav__list {
  height: 100%;
  text-align: right;
  font-size: 0;
}

.nav__list li {
  display: inline-block;
  line-height: 75px;
}

.nav__list li a {
  font-size: 12px;
  line-height: 45px;
  padding: 0 20px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  vertical-align: middle;
  cursor: pointer;
}

.nav__list .is-hover a,
.nav__list li a:hover {
  color: #0072f6;
}

.nav--black .logo {
  background-image: url(./img/logo.png);
}

.nav--black .nav__list li a {
  color: #000;
}

.nav--black .nav__list li.is-hover a,
.nav--black .nav__list li a:hover {
  color: #0072f6;
}

.nav .has-dropdown > a {
  position: relative;
}

.nav .has-dropdown > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -32px;
  left: 0;
  background-color: #1476f2;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: none;
  transition: none;
}

.nav .has-dropdown:last-child {
  margin-right: 160px;
}

.nav .has-dropdown.is-hover {
  color: #1476f2;
}

.nav .has-dropdown.is-hover .sub-menu-wrapper {
  display: block;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-animation: animWrapper 0.3s ease-in-out;
  animation: animWrapper 0.3s ease-in-out;
}

.nav .has-dropdown.is-hover .sub-menu-wrapper .sub-menu-container {
  visibility: visible;
  -webkit-animation: animContainer 0.5s ease-in-out;
  animation: animContainer 0.5s ease-in-out;
}

.nav
.has-dropdown.is-hover
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper {
  top: 0;
  opacity: 1;
  visibility: visible;
  -webkit-animation: animItem 0.6s ease-in-out;
  animation: animItem 0.6s ease-in-out;
}

.nav
.has-dropdown.is-hover
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper:nth-child(2n) {
  -webkit-animation: animItem 0.7s ease-in-out;
  animation: animItem 0.7s ease-in-out;
}

.nav .has-dropdown.is-hover > a:before {
  visibility: visible;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition-delay: unset;
  transition-delay: unset;
}

.nav .has-dropdown .sub-menu-wrapper {
  display: none;
  position: absolute;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  height: auto;
  padding: 30px 0;
  top: 77px;
  left: 0;
  right: 0;
  line-height: normal;
  width: 100vw;
  margin-left: -webkit-calc(-50vw + 50%);
  margin-left: calc(-50vw + 50%);
  margin-right: -webkit-calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background-color: #fff;
  -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  border-top: 1px solid hsla(0, 0%, 85%, 0.3);
  overflow: hidden;
}

.nav .has-dropdown .sub-menu-wrapper .sub-menu-container {
  margin: 0 auto;
  max-width: 1200px;
  visibility: hidden;
}

.nav .has-dropdown .sub-menu-wrapper .sub-menu-container,
.nav .has-dropdown .sub-menu-wrapper .sub-menu-container .article-container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.nav .has-dropdown .sub-menu-wrapper .sub-menu-container .article-container {
  width: 70%;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper {
  width: 48%;
  visibility: hidden;
  opacity: 0;
  position: relative;
  top: 20px;
  cursor: pointer;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper
.article__item:hover
.article__media-inner {
  -webkit-transform: scale(1.16);
  -ms-transform: scale(1.16);
  transform: scale(1.16);
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper
.article__item
.article__content {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper
.article__item
.article__content
.article__text {
  padding-left: 25px;
  width: 65%;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper
.article__item
.article__content
.article__text
h3 {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  color: #323232;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper
.article__item
.article__content
.article__text
p {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #555;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper
.article__item
.article__content
.article__text
a {
  padding: 0;
  margin-top: 15px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  color: #1476f2;
  text-transform: uppercase;
  bottom: 5px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  -webkit-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper
.article__item
.article__content
.article__text
a
i {
  font-size: 11px;
  margin-left: 5px;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper
.article__item
.article__content
.article__text
a:hover {
  color: #005ed7;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper
.article__item
.article__content
.article__media {
  height: 160px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  width: 35%;
  overflow: hidden;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.article__item-wrapper
.article__item
.article__content
.article__media
.article__media-inner {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.sub-menu-list-container {
  position: relative;
  width: 23%;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.sub-menu-list-container
ul {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  padding: 0 20px 0 70px;
  opacity: 0;
  border-left: 1px solid #d8d8d8;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.sub-menu-list-container
ul.active {
  padding: 0 20px;
  opacity: 1;
  -webkit-animation: animList 0.7s ease-in-out;
  animation: animList 0.7s ease-in-out;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.sub-menu-list-container
ul:after {
  content: "";
  display: table;
  clear: both;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.sub-menu-list-container
ul
li {
  width: 100%;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.sub-menu-list-container
ul
li
a {
  text-transform: none;
  word-spacing: normal;
  padding: 0;
  text-align: left;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #323232;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.sub-menu-list-container
ul
li
a
i {
  position: relative;
  top: 1px;
  right: -4px;
  font-size: 12px;
  color: #1476f2;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  opacity: 0;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.sub-menu-list-container
ul
li:hover
a {
  color: #1476f2;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.sub-menu-list-container
ul
li:hover
a
i {
  right: -8px;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  opacity: 1;
}

.nav
.has-dropdown
.sub-menu-wrapper
.sub-menu-container
.sub-menu-list-container
ul
.service--main
a {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.nav .dropdown-split .sub-menu {
  min-width: 470px;
}

.nav .dropdown-split .sub-menu li {
  width: -webkit-calc(100% / 2 - 20px);
  width: calc(100% / 2 - 20px);
}

.nav .dropdown-split .sub-menu li:nth-child(2n) a {
  margin-left: 50px;
}

.nav .dropdown-split.narrow .sub-menu {
  min-width: 320px;
}

.nav__btn {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (min-width: 1280px) {
  .nav__btn {
    right: 0;
  }
}

@media (max-width: 767px) {
  .nav__btn {
    right: 20px;
  }
}

@media (max-width: 1220px) {
  .nav__btn .get-touch {
    margin-right: 25px;
  }
}

@media (max-width: 767px) {
  .nav__btn .get-touch {
    display: none;
  }
}

.nav__btn .sandwich {
  background-image: url(img/sandwich-circle.svg);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  width: 46px;
  height: 46px;
}

@media (min-width: 1221px) {
  .nav__btn .sandwich {
    display: none;
  }
}

.nav__btn .sandwich:before {
  content: "";
  background-image: url(img/sandwich-icon.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (min-width: 1221px) {
  .js-scroll {
    position: fixed;
    top: 0;
  }
}

@media (max-width: 1220px) {
  .js-scroll .logo {
    -webkit-transform: translateY(-150%);
    -ms-transform: translateY(-150%);
    transform: translateY(-150%);
  }
}

@media (min-width: 1221px) {
  .js-hidden {
    top: -90px;
  }
}

.nav__logo {
  position: absolute;
  top: 50%;
  left: 30px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

@media screen and (min-width: 1280px) {
  .nav__logo {
    left: 0;
  }
}

@media (max-width: 767px) {
  .nav__logo {
    left: 20px;
  }
}

.logo {
  background-image: url(./img/logo.png);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  height: 46px;
  width: 170px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.logo-white {
  background-image: url(./img/logo.png);
}

.nav-mobile {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 300px;
  z-index: 201;
  background-color: #000;
  -webkit-transform: translate3d(-300px, 0, 0);
  transform: translate3d(-300px, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.nav-mobile .c-menu__items {
  padding: 90px 0 35px;
  z-index: 200;
  -webkit-transition: padding 0.3s;
  transition: padding 0.3s;
}

.nav-mobile .c-menu__items li a {
  display: block;
  padding: 15px 25px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}

@media (max-width: 320px) {
  .nav-mobile .c-menu__items li a {
    font-size: 16px;
    padding: 13px 25px;
  }
}

@media screen and (max-height: 320px) {
  .nav-mobile .c-menu__items li a {
    font-size: 12px;
    padding: 12px 25px;
  }
}

.nav-mobile .c-menu__items .has-dropdown {
  overflow: hidden;
}

.nav-mobile .c-menu__items .has-dropdown .sub-menu {
  max-height: 0;
  -webkit-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}

.nav-mobile .c-menu__items .has-dropdown.active > a:after {
  background-image: url(img/icon-arrow-bottom.svg);
  width: 9px;
  height: 8px;
}

.nav-mobile .c-menu__items .has-dropdown.active .sub-menu {
  max-height: 900px;
}

.nav-mobile .c-menu__items .has-dropdown li a {
  text-transform: none;
  font-weight: 400;
  color: #b2b2b2;
}

.nav-mobile .c-menu__items .has-dropdown li a:hover {
  color: #0072f6;
}

.nav-mobile .c-menu__items .has-dropdown > a {
  position: relative;
}

.nav-mobile .c-menu__items .has-dropdown > a:after {
  content: "";
  position: absolute;
  background-image: url(img/icon-arrow-right.svg);
  background-repeat: no-repeat;
  width: 8px;
  height: 9px;
  right: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.nav-mobile .c-menu__items .has-dropdown li.service--main a {
  text-transform: uppercase;
  font-weight: 400;
  color: #fff;
}

.nav-mobile .c-menu__items .has-dropdown li.service--main a:hover {
  color: #0072f6;
}

.btn--menuLeft {
  padding: 0 25px;
}

.has-active-menu .nav-mobile {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.has-active-menu .nav-mobile--stripe {
  position: fixed;
  top: 0;
  left: 0;
  width: 290px;
  height: 17px;
  background-color: #000;
  z-index: 999;
}

.c-menu {
  position: fixed;
  z-index: 199;
  background-color: #000;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-backface-visibility: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.c-menu__close {
  color: #fff;
  background-color: #3184a1;
  font-size: 14px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.c-menu__close:focus {
  outline: none;
}

.nav-mobile__top {
  -webkit-transform: translate3d(-300px, 0, 0);
  transform: translate3d(-300px, 0, 0);
  z-index: 999;
  height: 79px;
  padding-left: 25px;
  top: 0;
  left: 0;
  padding-top: 17px;
  position: fixed;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 300px;
}

.has-active-menu .nav-mobile__top {
  background-color: #000;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@media (max-width: 321px) {
  .has-active-menu .nav-mobile__top {
    width: 100%;
  }
}

.has-active-menu .nav-mobile__top .btn-close {
  position: absolute;
  background-image: url(./img/icon-close.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 17px;
  height: 17px;
  right: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.c-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 0;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
  transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
}

.c-mask.is-active {
  width: 100%;
  height: 150vh;
  opacity: 0.7;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.c-menu--push-left,
.c-menu--push-right,
.c-menu--slide-left,
.c-menu--slide-right {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 321px) {
  .c-menu--push-left,
  .c-menu--push-right,
  .c-menu--slide-left,
  .c-menu--slide-right {
    width: 300px;
  }
}

.c-menu--push-left .c-menu__item,
.c-menu--push-right .c-menu__item,
.c-menu--slide-left .c-menu__item,
.c-menu--slide-right .c-menu__item {
  display: block;
  text-align: center;
  border-top: 1px solid #b5dbe9;
  border-bottom: 1px solid #3184a1;
}

.c-menu--push-left .c-menu__item:first-child,
.c-menu--push-right .c-menu__item:first-child,
.c-menu--slide-left .c-menu__item:first-child,
.c-menu--slide-right .c-menu__item:first-child {
  border-top: none;
}

.c-menu--push-left .c-menu__item:last-child,
.c-menu--push-right .c-menu__item:last-child,
.c-menu--slide-left .c-menu__item:last-child,
.c-menu--slide-right .c-menu__item:last-child {
  border-bottom: none;
}

.c-menu--push-left .c-menu__link,
.c-menu--push-right .c-menu__link,
.c-menu--slide-left .c-menu__link,
.c-menu--slide-right .c-menu__link {
  display: block;
  padding: 12px 24px;
  color: #fff;
}

.c-menu--push-left .c-menu__close,
.c-menu--push-right .c-menu__close,
.c-menu--slide-left .c-menu__close,
.c-menu--slide-right .c-menu__close {
  display: block;
  padding: 12px 24px;
  width: 100%;
}

.c-menu--push-left,
.c-menu--slide-left {
  top: 0;
  left: 0;
  bottom: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

@media (min-width: 321px) {
  .c-menu--push-left,
  .c-menu--slide-left {
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
  }
}

.c-menu--push-left.is-active,
.c-menu--slide-left.is-active {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.wrapper {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-backface-visibility: hidden;
}

.has-push-left .nav {
  z-index: 1;
}

@media (min-width: 321px) {
  .wrapper.has-push-left {
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
  }
}

.wrapper.has-push-right {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

@media (min-width: 321px) {
  .wrapper.has-push-right {
    -webkit-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
  }
}

.nav--case-main {
  height: 0;
}

.nav--case-main .logo {
  background-image: url(./img/logo.png);
}

.nav--case-main .sandwich {
  display: inline-block;
}

.nav--case-main .nav__wrapper {
  display: none;
}

.nav--case-main .nav__btn,
.nav--case-main .nav__logo {
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.nav--case-main .get-touch {
  margin-right: 25px;
}

.nav--case-main.js-scroll {
  background-color: transparent;
}

.nav--case-main.js-scroll .logo {
  -webkit-transform: translateY(-150%);
  -ms-transform: translateY(-150%);
  transform: translateY(-150%);
}

@-webkit-keyframes animWrapper {
  0% {
    display: none;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  1% {
    display: block;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  30% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  to {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes animWrapper {
  0% {
    display: none;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  1% {
    display: block;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  30% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  to {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-webkit-keyframes animContainer {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}

@keyframes animContainer {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}

@-webkit-keyframes animItem {
  0% {
    top: 20px;
    visibility: hidden;
    opacity: 0;
  }
  40% {
    top: 20px;
    opacity: 0;
    visibility: hidden;
  }
  50% {
    top: 20px;
    visibility: visible;
  }
  60% {
    opacity: 1;
  }
  to {
    top: 0;
  }
}

@keyframes animItem {
  0% {
    top: 20px;
    visibility: hidden;
    opacity: 0;
  }
  40% {
    top: 20px;
    opacity: 0;
    visibility: hidden;
  }
  50% {
    top: 20px;
    visibility: visible;
  }
  60% {
    opacity: 1;
  }
  to {
    top: 0;
  }
}

@-webkit-keyframes animList {
  0% {
    padding: 0 20px 0 70px;
    opacity: 0;
  }
  60% {
    padding: 0 20px 0 60px;
    opacity: 0;
  }
  70% {
    padding: 0 20px 0 50px;
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  to {
    padding: 0 20px;
  }
}

@keyframes animList {
  0% {
    padding: 0 20px 0 70px;
    opacity: 0;
  }
  60% {
    padding: 0 20px 0 60px;
    opacity: 0;
  }
  70% {
    padding: 0 20px 0 50px;
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  to {
    padding: 0 20px;
  }
}

.numbers {
  padding: 70px 0;
}

.numbers .container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.numbers .col-4 {
  padding-right: 50px;
  width: 25%;
  float: left;
}

@media (max-width: 1215px) {
  .numbers .col-4 {
    width: 50%;
  }

  .numbers .col-4:nth-child(3),
  .numbers .col-4:nth-child(4) {
    margin-top: 50px;
  }
}

@media (max-width: 1023px) {
  .numbers .col-4 {
    width: -webkit-calc(100% / 2 - 70px);
    width: calc(100% / 2 - 70px);
    margin-right: 70px;
    padding-right: 0;
  }

  .numbers .col-4:nth-child(2),
  .numbers .col-4:nth-child(4) {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .numbers .col-4 {
    width: 100%;
    float: none;
    margin: 50px 0 0;
  }
}

.numbers__item {
  font-size: 100px;
  line-height: 1;
  font-weight: 700;
  color: #bababa;
}

.numbers__heading {
  text-transform: uppercase;
  padding-left: 25px;
  border-left: 5px solid #0072f6;
  margin-top: 8px;
}

.numbers__text {
  font-size: 14px;
  margin-top: 26px;
  line-height: 18px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .numbers__text {
    max-width: 300px;
  }
}

.page-header {
  background-size: cover;
  background-position: 50%;
  position: relative;
}

.page-header--cases {
  background-image: url(img/case-studies-bg.jpg);
}

.page-header__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.page-header__content {
  height: 100vh;
  padding: 295px 0 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  .page-header__content {
    padding: 150px 0 70px;
    height: auto;
  }
}

.page-header__content .page-title h1 {
  color: #fff;
}

.page-header__content .page-lead {
  max-width: 500px;
  margin-top: 20px;
}

.page-header__content .page-lead p {
  color: #fff;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.page-header--centered {
  height: 600px;
}

.page-header--centered .page-title h1 {
  font-size: 50px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

@media screen and (max-width: 620px) {
  .page-header--centered .page-title h1 {
    margin-top: 10px;
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .page-header--centered .page-title h1 {
    margin-top: 20px;
    font-size: 26px;
  }
}

.page-header--centered .page-lead {
  max-width: 645px;
  margin-top: 0;
}

.page-header--centered .page-lead p {
  font-size: 18px;
  line-height: 1.47;
  text-align: center;
  color: #fff;
  margin: 0 auto;
}

@media screen and (max-width: 620px) {
  .page-header--centered .page-lead p {
    font-size: 16px;
  }
}

.page-header--centered .page-header__content {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
  height: 100%;
}

.page-header--centered .page-header__overlay {
  display: none;
}

.page-header--full-scr {
  height: 100vh;
  background-position: 0 0;
}

@media screen and (max-width: 768px) {
  .page-header--full-scr {
    min-height: 100vh;
    padding-bottom: 40px;
  }
}

.page-header__client-logo {
  height: 160px;
  width: 160px;
  background-position: 50%;
  background-repeat: no-repeat;
}

@media screen and (max-width: 620px) {
  .page-header__client-logo {
    height: 130px;
    width: 130px;
  }
}

.page-header__client-logo.joiful {
  background-size: 60%;
}

.page-header__client-logo.guest-services-web-portal {
  height: 100px;
}

@media screen and (max-width: 620px) {
  .page-header__client-logo.blockchain {
    height: 140px;
    width: 150px;
  }
}

.page-header__client-logo.euroaccident-mobile-app {
  height: 115px;
  width: 160px;
}

.partners {
  padding-bottom: 50px;
}

.partners .container--flex {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

@media screen and (max-width: 767px) {
  .partners .container--flex {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.partners__title {
  font-weight: 900;
  font-size: 60px;
  line-height: 65px;
  color: #121212;
}

.partners__item {
  padding: 0 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 33%;
}

@media screen and (max-width: 767px) {
  .partners__item {
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .partners__item {
    width: 100%;
  }
}

.partners__item a {
  display: block;
}

.partners__item h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.partners__item p {
  font-size: 16px;
  font-weight: 300;
}

.partners__item figure img {
  max-width: 100%;
  height: 130px;
  display: block;
}

.partners-block {
  padding: 0 0 90px;
  position: relative;
}

@media (max-width: 1075px) {
  .partners-block {
    padding: 0 0 167px;
  }
}

@media (max-width: 1024px) {
  .partners-block {
    padding-left: 37px;
    padding-right: 37px;
    padding-bottom: 149px;
  }
}

@media (max-width: 767px) {
  .partners-block {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 85px;
  }
}

.partners-block__title {
  margin-bottom: 48px;
  padding-top: 91px;
}

@media (max-width: 1024px) {
  .partners-block__title {
    margin-bottom: 22px;
    padding-top: 81px;
  }
}

@media (max-width: 767px) {
  .partners-block__title {
    margin-bottom: 0;
    padding-top: 38px;
  }
}

.partners-block__title h2 {
  font-size: 60px;
  font-weight: 900;
  letter-spacing: 0.3px;
}

@media (max-width: 1024px) {
  .partners-block__title h2 {
    font-size: 44px;
  }
}

@media (max-width: 767px) {
  .partners-block__title h2 {
    font-size: 30px;
  }
}

.partners-block__text {
  width: 370px;
  margin: 0 0 0 1px;
}

@media (max-width: 1024px) {
  .partners-block__text {
    width: 320px;
  }
}

@media (max-width: 767px) {
  .partners-block__text {
    width: auto;
    margin: 27px 0 0;
  }
}

.partners-block__text p {
  font-size: 22.5px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: -0.2px;
}

@media (max-width: 1024px) {
  .partners-block__text p {
    letter-spacing: 0;
    font-size: 17px;
    line-height: 30px;
  }
}

@media (max-width: 767px) {
  .partners-block__text p {
    font-size: 18px;
    line-height: 25px;
  }
}

.partners-block__inner {
  width: 1200px;
  margin: 0 auto;
  padding-left: 98px;
  position: relative;
  z-index: 1;
}

.partners-block__inner:before {
  content: "";
  display: block;
  width: 100px;
  height: 8px;
  position: absolute;
  top: -43px;
  left: 100px;
  background: #000;
}

@media (max-width: 1075px) {
  .partners-block__inner:before {
    left: 3px;
  }
}

@media (max-width: 1024px) {
  .partners-block__inner:before {
    top: -1px;
  }
}

@media (max-width: 1075px) {
  .partners-block__inner {
    width: 907px;
    padding-left: 0;
  }
}

@media (max-width: 1024px) {
  .partners-block__inner {
    width: auto;
  }
}

.partners-block__content {
  float: left;
  width: 400px;
}

@media (max-width: 1024px) {
  .partners-block__content {
    float: none;
    margin-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .partners-block__content {
    width: auto;
    margin-bottom: 40px;
  }
}

.partners-block__logos {
  float: right;
  width: 600px;
  margin: 40px 1px 0 0;
}

@media (max-width: 1075px) {
  .partners-block__logos {
    width: 452px;
  }
}

@media (max-width: 1024px) {
  .partners-block__logos {
    float: none;
    width: 690px;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .partners-block__logos {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}

.partners-block__logo {
  position: relative;
  float: left;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 165px;
  height: 115px;
}

.partners-block__logo img {
  width: 100px;
  height: auto;
}

.partners-block__logo.scaling img {
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

.partners-block__logo.scaling img:hover {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.partners-block__logo:before {
  left: -2.5px;
}

.partners-block__logo:after,
.partners-block__logo:before {
  display: block;
  content: "";
  position: absolute;
  background: url(img/plus.png) no-repeat;
  width: 5px;
  height: 5px;
  background-size: 100%;
  top: -2.5px;
}

.partners-block__logo:after {
  right: -2.5px;
}

@media (max-width: 1075px) {
  .partners-block__logo {
    width: 150px;
    height: 105px;
  }
}

@media (max-width: 1024px) {
  .partners-block__logo {
    width: 230px;
    height: 140px;
  }
}

@media (max-width: 767px) {
  .partners-block__logo {
    max-width: 150px;
    height: 105px;
    width: 50%;
  }
}

.partners-block__logo span:before {
  right: -2.5px;
}

.partners-block__logo span:after,
.partners-block__logo span:before {
  display: block;
  content: "";
  position: absolute;
  background: url(img/plus.png) no-repeat;
  width: 5px;
  height: 5px;
  background-size: 100%;
  bottom: -2.5px;
}

.partners-block__logo span:after {
  left: -2.5px;
}

.phone {
  background-color: #fff;
  padding-top: 70px;
  background-size: cover;
}

@media (max-width: 460px) {
  .phone .h2 {
    font-size: 26px;
  }
}

.phone.augmented-reality--process {
  background: url(img/platforms/ar-construction/Block01.jpg);
  background-size: cover;
}

.phone.augmented-reality--process .phone__image {
  background-position: 50%;
}

@media screen and (max-width: 767px) {
  .phone.augmented-reality--process {
    background: #fff url(img/platforms/ar-construction/Block01_m.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
  }
}

.phone.augmented-reality--validation {
  background: url(img/platforms/ar-construction/Block2.jpg);
  background-size: cover;
  color: #fff;
}

.phone.augmented-reality--validation .phone__lead {
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .phone.augmented-reality--validation {
    background-position-x: 60%;
  }
}

@media screen and (max-width: 768px) {
  .phone.augmented-reality--validation {
    background: #000 url(img/platforms/ar-construction/Block2_m.jpg);
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: 90%;
    background-size: cover;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 425px) {
  .phone.augmented-reality--validation {
    background-size: 175%;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: 14%;
  }
}

.phone.augmented-reality--reconstruction {
  background: url(img/platforms/ar-construction/Block3.jpg);
  background-size: cover;
  color: #fff;
}

.phone.augmented-reality--reconstruction .phone__lead {
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .phone.augmented-reality--reconstruction {
    background-position-x: 60%;
  }
}

@media screen and (max-width: 768px) {
  .phone.augmented-reality--reconstruction {
    background-size: cover;
    background-position: 50%;
  }

  .phone.augmented-reality--reconstruction .phone__list li {
    max-width: none;
    margin-right: 20px;
  }
}

@media screen and (max-width: 600px) {
  .phone.augmented-reality--reconstruction {
    background: #4d4d4f url(img/platforms/ar-construction/Block3_m.jpg);
    background-size: 180%;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: 80%;
  }
}

.phone.augmented-reality--hazard {
  background: url(img/platforms/ar-construction/Block4.jpg);
  background-size: cover;
  color: #fff;
}

.phone.augmented-reality--hazard .phone__lead {
  color: #fff;
}

@media screen and (max-width: 1280px) {
  .phone.augmented-reality--hazard {
    background-position-x: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .phone.augmented-reality--hazard {
    background-position-x: 50%;
  }
}

@media screen and (max-width: 767px) {
  .phone.augmented-reality--hazard {
    background: #313c50 url(img/platforms/ar-construction/Block4_m.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
  }
}

@media screen and (max-width: 425px) {
  .phone.augmented-reality--hazard {
    background-size: 175%;
  }
}

.phone.augmented-reality--collaboration {
  background: url(img/platforms/ar-construction/Block5.jpg);
  background-size: cover;
}

@media screen and (max-width: 1024px) {
  .phone.augmented-reality--collaboration {
    background-position-x: 60%;
  }
}

@media screen and (max-width: 768px) {
  .phone.augmented-reality--collaboration {
    background: #fff url(img/platforms/ar-construction/Block5_m.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
  }
}

@media screen and (max-width: 425px) {
  .phone.augmented-reality--collaboration {
    background-size: 205%;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: 80%;
  }
}

.phone--image {
  background-size: cover;
  background-position: 50%;
  color: #fff;
}

.phone--image .phone__lead {
  color: #fff;
  opacity: 0.8;
}

.phone__content {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .phone__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.phone__column {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  position: relative;
}

@media screen and (max-width: 767px) {
  .phone__column {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
  }
}

.phone__column--height {
  min-height: 550px;
}

@media screen and (max-width: 767px) {
  .phone__column--height {
    min-height: 450px;
  }
}

@media screen and (max-device-width: 400px) {
  .phone__column--height {
    min-height: 350px;
  }
}

.phone__column--order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

@media screen and (max-width: 767px) {
  .phone__column--order-2 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}

.phone__title {
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
}

.phone__image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  position: absolute;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .phone__image {
    background-size: 90%;
  }
}

@media screen and (max-width: 767px) {
  .phone__image {
    background-position: bottom;
  }
}

.phone__image--left {
  background-position: 0 100%;
}

@media screen and (max-width: 767px) {
  .phone__image--left {
    background-position: bottom;
  }
}

.phone__lead {
  margin-bottom: 30px;
  color: #555;
}

@media screen and (max-width: 1024px) {
  .phone__lead {
    padding-right: 40px;
  }
}

@media screen and (max-width: 768px) {
  .phone__lead {
    padding-right: 0;
  }
}

.phone__list {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .phone__list {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .phone__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.phone__list li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  max-width: 50%;
  font-weight: 400;
  padding: 25px 25px 25px 0;
  font-size: 15px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .phone__list li {
    max-width: 100%;
  }
}

.icon {
  position: relative;
}

.icon .list--text {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.icon:before {
  content: "";
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 0;
  height: 40px;
  width: 40px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon--opacity:before {
  opacity: 0.7;
}

#phone {
  display: none;
  z-index: 999;
  position: fixed;
  top: 80%;
  right: 0;
  padding-right: 10px;
}

.newsletter {
  position: fixed;
  bottom: 0;
  height: 100px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 99;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

@media screen and (max-width: 767px) {
  .newsletter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 120px;
  }
}

@media screen and (max-width: 330px) {
  .newsletter {
    height: 140px;
  }
}

.newsletter--show {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.newsletter--show .newsletter-title {
  width: 70%;
  padding: 10px 31px 10px 40px;
  opacity: 0.8;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .newsletter--show .newsletter-title {
    width: 100%;
    padding: 10px 31px 5px 40px;
    font-size: 11px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (max-width: 480px) {
  .newsletter--show .newsletter-title {
    font-size: 10px;
    padding: 7px 10px 3px;
  }
}

.newsletter--show .newsletter-link {
  color: #fff;
}

.newsletter--show .newsletter-link:hover {
  color: #0072f6;
}

.newsletter--show .newsletter-button {
  width: 30%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 767px) {
  .newsletter--show .newsletter-button {
    width: 100%;
  }
}

.newsletter--show .newsletter-button .popup-ok {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-width: 140px;
  font-size: 14px;
  padding: 0 20px;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  white-space: nowrap;
  font-family: Montserrat, Arial, sans-serif !important;
  color: #fff;
  line-height: 0.93;
  letter-spacing: normal;
  text-align: right;
  border: 1px solid #fff;
  text-decoration: none;
  font-style: normal;
  font-stretch: normal;
  cursor: pointer;
}

.newsletter--show .newsletter-button .popup-ok:hover {
  background: #fff;
  color: #323232;
}

.share-widget {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.share-widget--post {
  margin-top: 50px;
}

@media screen and (max-width: 767px) {
  .share-widget--post {
    margin-top: 30px;
  }
}

.share-widget span {
  color: red;
  font-size: 12px;
  margin-right: 30px;
}

.share-widget a {
  margin-right: 10px;
}

.slider .carousel,
.slider .carousel-cell {
  width: 100%;
}

.social-box .linkedin> svg {
  fill: #b0b0b0;
}

.social-box .linkedin > svg:hover {
  fill: #0b86ca;
}

.social-box .facebook > svg {
  fill: #b0b0b0;
}

.social-box .facebook > svg:hover {
  fill: #4c76be;
}

.social-box .twitter > svg {
  fill: #b0b0b0;
}

.social-box .twitter > svg:hover {
  fill: #00c7ff;
}

.social-box .flickr > svg {
  fill: #b0b0b0;
}

.social-box .flickr > svg:hover .dribble-blue {
  fill: #0062dd;
}

.social-box .flickr > svg:hover .dribble-red {
  fill: #ff0084;
}

.social-box .youtube > svg {
  fill: #b0b0b0;
}

.social-box .youtube > svg:hover {
  fill: red;
}

.social-box__list {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.social-box__list,
.social-box__list a {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.social-box__list a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}

.social-box__list svg {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/*@font-face {
font-family: social-share-kit;
src: url(fonts/social-share-kit.eot);
src: url(fonts/social-share-kit.eot?#iefix) format("embedded-opentype"), url(fonts/social-share-kit.woff) format("woff"), url(fonts/social-share-kit.ttf) format("truetype"), url(fonts/social-share-kit.svg#social-share-kit) format("svg");
font-weight: 400;
font-style: normal
}*/

.ssk:before {
  display: inline-block;
  font-family: social-share-kit !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ssk-facebook:before {
  content: "a";
  text-indent: 4px;
  margin-right: -4px;
}

.ssk-twitter:before {
  content: "b";
}

.ssk-google-plus:before {
  content: "v";
}

.ssk-google-plus-old:before {
  content: "c";
}

.ssk-email:before {
  content: "d";
  top: -1px;
  position: relative;
}

.ssk-pinterest:before {
  content: "e";
}

.ssk-tumblr:before {
  content: "f";
}

.ssk-linkedin:before {
  content: "g";
}

.ssk-github:before {
  content: "h";
}

.ssk-vk:before {
  content: "i";
}

.ssk-linkedin:before {
  content: "j";
}

.ssk-amazon:before {
  content: "k";
}

.ssk-skype:before {
  content: "s";
}

.ssk-youtube:before {
  content: "x";
}

.ssk-vimeo:before {
  content: "u";
}

.ssk-ebay:before {
  content: "p";
}

.ssk-apple:before {
  content: "l";
}

.ssk-behance:before {
  content: "q";
}

.ssk-dribble:before {
  content: "n";
}

.ssk-android:before {
  content: "o";
}

.ssk-whatsapp:before {
  content: "m";
}

.ssk-reddit:before {
  content: "r";
}

.ssk-reddit2:before {
  content: "t";
}

.ssk {
  background-color: #757575;
  color: #fff;
  display: inline-block;
  font-size: 22px;
  line-height: 1px;
  margin-right: 2px;
  margin-bottom: 2px;
  padding: 7px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.1s;
  -webkit-transition: background-color 0.1s;
  -moz-transition: background-color 0.1s;
  -ms-transition: background-color 0.1s;
  -o-transition: background-color 0.1s;
}

.ssk .fa,
.ssk .glyphicon,
.ssk:before {
  position: relative;
  font-size: 22px;
  top: 0;
  vertical-align: middle;
}

.ssk-xs > .ssk,
.ssk.ssk-xs {
  padding: 4px;
}

.ssk-xs > .ssk .fa,
.ssk-xs > .ssk .glyphicon,
.ssk-xs > .ssk:before,
.ssk.ssk-xs .fa,
.ssk.ssk-xs .glyphicon,
.ssk.ssk-xs:before {
  font-size: 15px;
}

.ssk-sm > .ssk,
.ssk.ssk-sm {
  padding: 5px;
}

.ssk-sm > .ssk .fa,
.ssk-sm > .ssk .glyphicon,
.ssk-sm > .ssk:before,
.ssk.ssk-sm .fa,
.ssk.ssk-sm .glyphicon,
.ssk.ssk-sm:before {
  font-size: 20px;
}

.ssk-lg > .ssk,
.ssk.ssk-lg {
  padding: 9px;
}

.ssk-lg > .ssk .fa,
.ssk-lg > .ssk .glyphicon,
.ssk-lg > .ssk:before,
.ssk.ssk-lg .fa,
.ssk.ssk-lg .glyphicon,
.ssk.ssk-lg:before {
  font-size: 28px;
}

.ssk:last-child {
  margin-right: 0;
}

.ssk:hover {
  background-color: #424242;
}

.ssk:focus,
.ssk:hover {
  color: #fff;
  text-decoration: none;
}

.ssk-round .ssk,
.ssk.ssk-round {
  border-radius: 50%;
}

.ssk-round .ssk:before,
.ssk.ssk-round:before {
  text-indent: 0;
  margin-right: 0;
}

.ssk-rounded .ssk,
.ssk.ssk-rounded {
  border-radius: 15%;
}

.ssk.ssk-icon {
  color: #757575;
  padding: 2px;
  font-size: 24px;
}

.ssk.ssk-icon,
.ssk.ssk-icon:hover {
  background-color: transparent;
}

.ssk.ssk-icon:hover {
  color: #424242;
}

.ssk-xs > .ssk.ssk-icon,
.ssk.ssk-icon.ssk-xs {
  font-size: 16px;
}

.ssk-sm > .ssk.ssk-icon,
.ssk.ssk-icon.ssk-sm {
  font-size: 20px;
}

.ssk-lg > .ssk.ssk-icon,
.ssk.ssk-icon.ssk-lg {
  font-size: 28px;
}

.ssk.ssk-text {
  overflow: hidden;
  font-size: 17px;
  line-height: normal;
  padding-right: 10px;
}

.ssk.ssk-text .fa,
.ssk.ssk-text .glyphicon,
.ssk.ssk-text:before {
  margin: -7px 10px -7px -7px;
  padding: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  vertical-align: bottom;
  text-indent: 0;
}

.ssk-block .ssk.ssk-text {
  display: block;
  margin-right: 0;
  text-align: left;
}

.ssk-xs > .ssk.ssk-text,
.ssk.ssk-text.ssk-xs {
  font-size: 12px;
  padding-right: 6px;
}

.ssk-xs > .ssk.ssk-text .fa,
.ssk-xs > .ssk.ssk-text .glyphicon,
.ssk-xs > .ssk.ssk-text:before,
.ssk.ssk-text.ssk-xs .fa,
.ssk.ssk-text.ssk-xs .glyphicon,
.ssk.ssk-text.ssk-xs:before {
  margin: -4px 6px -4px -4px;
  padding: 4px;
}

.ssk-sm > .ssk.ssk-text,
.ssk.ssk-text.ssk-sm {
  font-size: 16px;
  padding-right: 7px;
}

.ssk-sm > .ssk.ssk-text .fa,
.ssk-sm > .ssk.ssk-text .glyphicon,
.ssk-sm > .ssk.ssk-text:before,
.ssk.ssk-text.ssk-sm .fa,
.ssk.ssk-text.ssk-sm .glyphicon,
.ssk.ssk-text.ssk-sm:before {
  margin: -5px 7px -5px -5px;
  padding: 5px;
}

.ssk-lg > .ssk.ssk-text,
.ssk.ssk-text.ssk-lg {
  font-size: 22px;
  padding-right: 13px;
}

.ssk-lg > .ssk.ssk-text .fa,
.ssk-lg > .ssk.ssk-text .glyphicon,
.ssk-lg > .ssk.ssk-text:before,
.ssk.ssk-text.ssk-lg .fa,
.ssk.ssk-text.ssk-lg .glyphicon,
.ssk.ssk-text.ssk-lg:before {
  margin: -9px 13px -9px -9px;
  padding: 9px;
}

.ssk-group,
.ssk-sticky {
  font-size: 0;
}

.ssk-sticky {
  top: 0;
  position: fixed;
  z-index: 2000;
}

.ssk-sticky .ssk {
  transition: padding 0.1s ease-out;
  -webkit-transition: padding 0.1s ease-out;
  -moz-transition: padding 0.1s ease-out;
  -ms-transition: padding 0.1s ease-out;
  -o-transition: padding 0.1s ease-out;
  margin: 0;
}

@media (min-width: 768px) {
  .ssk-sticky.ssk-left .ssk,
  .ssk-sticky.ssk-right .ssk {
    display: block;
    clear: both;
  }

  .ssk-sticky.ssk-left.ssk-center,
  .ssk-sticky.ssk-right.ssk-center {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  .ssk-sticky.ssk-left {
    left: 0;
  }

  .ssk-sticky.ssk-left .ssk {
    float: left;
  }

  .ssk-sticky.ssk-left .ssk:hover {
    padding-left: 15px;
  }

  .ssk-sticky.ssk-right {
    right: 0;
  }

  .ssk-sticky.ssk-right .ssk {
    float: right;
  }

  .ssk-sticky.ssk-right .ssk:hover {
    padding-right: 15px;
  }
}

.ssk-sticky.ssk-bottom {
  font-size: 0;
  top: auto;
  bottom: 0;
}

.ssk-sticky.ssk-bottom.ssk-center {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.ssk-sticky.ssk-bottom .ssk {
  vertical-align: bottom;
}

.ssk-sticky.ssk-bottom .ssk:hover {
  padding-bottom: 15px;
}

.ssk-sticky.ssk-round.ssk-xs .ssk:hover {
  padding: 8px;
}

.ssk-sticky.ssk-round.ssk-sm .ssk:hover {
  padding: 9px;
}

.ssk-sticky.ssk-round .ssk:hover {
  padding: 11px;
}

.ssk-sticky.ssk-round.ssk-lg .ssk:hover {
  padding: 13px;
}

@media (max-width: 767px) {
  .ssk-sticky {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    -js-display: flex !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .ssk-sticky.ssk-sticky-hide-xs {
    display: none !important;
  }

  .ssk-sticky .ssk {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: auto;
  }

  .ssk-sticky .ssk .ssk-num {
    display: none;
  }
}

.ssk-count {
  padding-top: 20px;
}

.ssk-count .ssk {
  position: relative;
}

.ssk-count .ssk-num {
  border-radius: 4px;
  color: #8f8f8f;
  background-color: rgba(50, 50, 50, 0.03);
  display: block;
  font-size: 12px;
  left: 0;
  line-height: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  top: -20px;
}

@media (min-width: 768px) {
  .ssk-count.ssk-sticky {
    padding-top: 0;
  }

  .ssk-count.ssk-sticky.ssk-left .ssk-num,
  .ssk-count.ssk-sticky.ssk-right .ssk-num {
    top: 20%;
    background-color: transparent;
  }

  .ssk-count.ssk-sticky.ssk-left .ssk-num {
    left: 100%;
    margin-left: 5px;
  }

  .ssk-count.ssk-sticky.ssk-right .ssk-num {
    right: 115%;
    margin-left: -100%;
    text-align: right;
  }
}

.ssk-facebook {
  background-color: #255c95;
}

.ssk-grayscale > .ssk-facebook {
  background-color: #757575;
}

.ssk-facebook:hover {
  background-color: #1b436c;
}

.ssk-grayscale > .ssk-facebook:hover {
  background-color: #255c95;
}

.ssk-facebook.ssk-icon {
  color: #255c95;
}

.ssk-facebook.ssk-icon:hover {
  color: #1b436c;
}

.ssk-facebook.ssk-icon:before {
  text-indent: 0;
  margin-right: 0;
}

.ssk-twitter {
  background-color: #00b4e0;
}

.ssk-grayscale > .ssk-twitter {
  background-color: #757575;
}

.ssk-twitter:hover {
  background-color: #008bad;
}

.ssk-grayscale > .ssk-twitter:hover {
  background-color: #00b4e0;
}

.ssk-twitter.ssk-icon {
  color: #00b4e0;
}

.ssk-twitter.ssk-icon:hover {
  color: #008bad;
}

.ssk-google-plus {
  background-color: #f1403a;
}

.ssk-grayscale > .ssk-google-plus {
  background-color: #757575;
}

.ssk-google-plus:hover {
  background-color: #e81810;
}

.ssk-grayscale > .ssk-google-plus:hover {
  background-color: #f1403a;
}

.ssk-google-plus.ssk-icon {
  color: #f1403a;
}

.ssk-google-plus.ssk-icon:hover {
  color: #e81810;
}

.ssk-pinterest {
  background-color: #cb2027;
}

.ssk-grayscale > .ssk-pinterest {
  background-color: #757575;
}

.ssk-pinterest:hover {
  background-color: #9f191f;
}

.ssk-grayscale > .ssk-pinterest:hover {
  background-color: #cb2027;
}

.ssk-pinterest.ssk-icon {
  color: #cb2027;
}

.ssk-pinterest.ssk-icon:hover {
  color: #9f191f;
}

.ssk-tumblr {
  background-color: #395773;
}

.ssk-grayscale > .ssk-tumblr {
  background-color: #757575;
}

.ssk-tumblr:hover {
  background-color: #283d51;
}

.ssk-grayscale > .ssk-tumblr:hover {
  background-color: #395773;
}

.ssk-tumblr.ssk-icon {
  color: #395773;
}

.ssk-tumblr.ssk-icon:hover {
  color: #283d51;
}

.ssk-email,
.ssk-grayscale > .ssk-email {
  background-color: #757575;
}

.ssk-email:hover {
  background-color: #5b5b5b;
}

.ssk-grayscale > .ssk-email:hover {
  background-color: #757575;
  background-color: #5b5b5b;
}

.ssk-email.ssk-icon {
  color: #757575;
}

.ssk-email.ssk-icon:hover {
  color: #5b5b5b;
}

.ssk-vk {
  background-color: #54769a;
}

.ssk-grayscale > .ssk-vk {
  background-color: #757575;
}

.ssk-vk:hover {
  background-color: #425d79;
}

.ssk-grayscale > .ssk-vk:hover {
  background-color: #54769a;
}

.ssk-vk.ssk-icon {
  color: #54769a;
}

.ssk-vk.ssk-icon:hover {
  color: #425d79;
}

.ssk-linkedin {
  background-color: #1c87bd;
}

.ssk-grayscale > .ssk-linkedin {
  background-color: #757575;
}

.ssk-instargram:hover {
  background-color: #156791;
}

.ssk-grayscale > .ssk-linkedin:hover {
  background-color: #1c87bd;
}

.ssk-linkedin.ssk-icon {
  color: #1c87bd;
}

.ssk-linkedin.ssk-icon:hover {
  color: #156791;
}

.ssk-whatsapp {
  background-color: #34af23;
}

.ssk-grayscale > .ssk-whatsapp {
  background-color: #757575;
}

.ssk-whatsapp:hover {
  background-color: #27851a;
}

.ssk-grayscale > .ssk-whatsapp:hover {
  background-color: #34af23;
}

.ssk-whatsapp.ssk-icon {
  color: #34af23;
}

.ssk-whatsapp.ssk-icon:hover {
  color: #27851a;
}

.ssk-reddit {
  background-color: #5f99cf;
}

.ssk-grayscale > .ssk-reddit {
  background-color: #757575;
}

.ssk-reddit:hover {
  background-color: #3a80c1;
}

.ssk-grayscale > .ssk-reddit:hover {
  background-color: #5f99cf;
}

.ssk-reddit.ssk-icon {
  color: #5f99cf;
}

.ssk-reddit.ssk-icon:hover {
  color: #3a80c1;
}

.ssk-reddit2 {
  background-color: #5f99cf;
}

.ssk-grayscale > .ssk-reddit2 {
  background-color: #757575;
}

.ssk-reddit2:hover {
  background-color: #3a80c1;
}

.ssk-grayscale > .ssk-reddit2:hover {
  background-color: #5f99cf;
}

.ssk-reddit2.ssk-icon {
  color: #5f99cf;
}

.ssk-reddit2.ssk-icon:hover {
  color: #3a80c1;
}

.ssk-turquoise {
  background-color: #1abc9c;
}

.ssk-turquoise:hover {
  background-color: #148f77;
}

.ssk-emerald {
  background-color: #2ecc71;
}

.ssk-emerald:hover {
  background-color: #25a25a;
}

.ssk-peter-river {
  background-color: #3498db;
}

.ssk-peter-river:hover {
  background-color: #217dbb;
}

.ssk-belize-hole {
  background-color: #2980b9;
}

.ssk-belize-hole:hover {
  background-color: #20638f;
}

.ssk-amethyst {
  background-color: #9b59b6;
}

.ssk-amethyst:hover {
  background-color: #804399;
}

.ssk-wisteria {
  background-color: #8e44ad;
}

.ssk-wisteria:hover {
  background-color: #703688;
}

.ssk-wet-asphalt {
  background-color: #34495e;
}

.ssk-wet-asphalt:hover {
  background-color: #222f3d;
}

.ssk-midnight-blue {
  background-color: #2c3e50;
}

.ssk-midnight-blue:hover {
  background-color: #1a242f;
}

.ssk-green-sea {
  background-color: #16a085;
}

.ssk-green-sea:hover {
  background-color: #107360;
}

.ssk-nephritis {
  background-color: #27ae60;
}

.ssk-nephritis:hover {
  background-color: #1e8449;
}

.ssk-sunflower {
  background-color: #f1c40f;
}

.ssk-sunflower:hover {
  background-color: #c29d0b;
}

.ssk-orange {
  background-color: #f39c12;
}

.ssk-orange:hover {
  background-color: #c87f0a;
}

.ssk-carrot {
  background-color: #e67e22;
}

.ssk-carrot:hover {
  background-color: #bf6516;
}

.ssk-pumpkin {
  background-color: #d35400;
}

.ssk-pumpkin:hover {
  background-color: #a04000;
}

.ssk-alizarin {
  background-color: #e74c3c;
}

.ssk-alizarin:hover {
  background-color: #d62c1a;
}

.ssk-pomegranate {
  background-color: #c0392b;
}

.ssk-pomegranate:hover {
  background-color: #962d22;
}

.ssk-clouds {
  background-color: #cfd9db;
}

.ssk-clouds:hover {
  background-color: #b1c2c6;
}

.ssk-concrete {
  background-color: #95a5a6;
}

.ssk-concrete:hover {
  background-color: #798d8f;
}

.ssk-silver {
  background-color: #bdc3c7;
}

.ssk-silver:hover {
  background-color: #a1aab0;
}

.ssk-asbestos {
  background-color: #7f8c8d;
}

.ssk-asbestos:hover {
  background-color: #667273;
}

.ssk-dark-gray {
  background-color: #555;
}

.ssk-dark-gray:hover {
  background-color: #3b3b3b;
}

.ssk-black {
  background-color: #333;
}

.ssk-black:hover {
  background-color: #1a1a1a;
}

.team-stuff {
  margin-top: 70px;
}

.team-stuff__flexbox {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.team-stuff__fade {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.team-stuff__item {
  position: relative;
  width: 20%;
}

@media screen and (max-width: 1023px) {
  .team-stuff__item {
    width: 25%;
  }
}

@media screen and (max-width: 767px) {
  .team-stuff__item {
    width: 50%;
  }
}

.team-stuff__item:focus,
.team-stuff__item:hover {
  outline: none;
}

.team-stuff__item:focus .team-stuff__fade,
.team-stuff__item:hover .team-stuff__fade {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: rgba(21, 127, 249, 0.9);
}

.team-stuff__item:focus .team-stuff__fade:before,
.team-stuff__item:hover .team-stuff__fade:before {
  content: "";
  display: block;
  background: #fff;
  width: 20px;
  height: 5px;
  left: 30px;
  bottom: 35px;
  position: absolute;
}

.team-stuff__item:focus span,
.team-stuff__item:hover span {
  opacity: 1;
}

.team-stuff__item img {
  display: block;
  width: 100%;
}

.team-stuff__item span {
  position: absolute;
  top: 25%;
  left: 30px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.team-stuff .b-lazy {
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  max-width: 100%;
  opacity: 0;
}

.team-stuff .b-lazy.b-loaded {
  opacity: 1;
}

.team-top {
  padding: 100px 0;
  background: #fff;
}

@media screen and (min-width: 1280px) {
  .team-top .container {
    padding: 0 40px;
  }
}

.team-top__flexbox {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: 0 -15px;
}

@media screen and (max-width: 1023px) {
  .team-top__flexbox {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.team-top__item {
  margin-bottom: 100px;
  width: 25%;
  padding: 0 10px;
}

@media screen and (max-width: 1279px) {
  .team-top__item {
    width: 25%;
  }
}

@media screen and (max-width: 1023px) {
  .team-top__item {
    width: 33%;
  }
}

@media screen and (max-width: 767px) {
  .team-top__item {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 500px) {
  .team-top__item {
    width: 100%;
  }
}

.team-top__item img {
  display: block;
  width: 100%;
  max-width: 265px;
  height: 360px;
  margin-bottom: 25px;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .team-top__item img {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 500px) {
  .team-top__item img {
    margin: 15px auto;
  }
}

.team-top__item h4 {
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 9px;
  color: #323232;
  font-family: Montserrat, Arial, sans-serif;
}

@media screen and (max-width: 500px) {
  .team-top__item h4 {
    text-align: center;
  }
}

.team-top__item span {
  font-size: 13px;
  color: #707070;
  line-height: 17px;
}

@media screen and (max-width: 500px) {
  .team-top__item span {
    text-align: center;
    display: block;
  }
}

.tech-block {
  position: relative;
  min-height: 1685px;
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 1325px) {
  .tech-block {
    min-height: 1571px;
  }
}

@media (max-width: 1024px) {
  .tech-block {
    padding: 0 37px;
    min-height: 1420px;
  }
}

@media (max-width: 767px) {
  .tech-block {
    padding-left: 30px;
    padding-right: 30px;
    min-height: 0;
    margin-bottom: 91px;
  }
}

.tech-block__subtitle {
  display: block;
  position: absolute;
  left: 5%;
  top: 418px;
  width: 0;
  height: 0;
}

@media (max-width: 1325px) {
  .tech-block__subtitle {
    position: static;
    width: 1000px;
    height: auto;
    margin: 34px auto -49px;
  }
}

@media (max-width: 1075px) {
  .tech-block__subtitle {
    width: 900px;
  }
}

@media (max-width: 1024px) {
  .tech-block__subtitle {
    width: auto;
    margin: 4px auto -19px;
  }
}

.tech-block__subtitle span {
  color: #0072f6;
  font-size: 12px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2.2px;
  display: block;
  white-space: nowrap;
  position: relative;
  margin-left: 28px;
}

.tech-block__subtitle span:after {
  position: absolute;
  width: 55px;
  height: 1px;
  content: "";
  display: block;
  background: #0072f6;
  top: 6px;
  left: 275px;
}

@media (max-width: 1325px) {
  .tech-block__subtitle span:after {
    display: none;
  }
}

@media (max-width: 1325px) {
  .tech-block__subtitle span {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 1024px) {
  .tech-block__subtitle span {
    font-size: 10px;
    letter-spacing: 2px;
  }
}

.tech-block__title {
  position: relative;
  margin-bottom: 35px;
  padding-right: 560px;
}

.tech-block__title h2 {
  letter-spacing: -0.55px;
  line-height: 40px;
  font-size: 37px;
  font-weight: 900;
}

@media (max-width: 1024px) {
  .tech-block__title h2 {
    font-size: 28px;
    line-height: 30px;
  }
}

.tech-block__title h2:after {
  position: absolute;
  content: "";
  display: block;
  width: 100px;
  height: 8px;
  background: #000;
  top: -118px;
  left: 3px;
}

@media (max-width: 1024px) {
  .tech-block__title h2:after {
    top: -85px;
  }
}

@media (max-width: 1024px) {
  .tech-block__title {
    padding: 0;
    width: 260px;
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .tech-block__title {
    padding: 0;
    width: auto;
    margin: 0 0 19px;
  }
}

.tech-block__text {
  width: 450px;
  margin: 0 0 66px 2px;
}

@media (max-width: 1075px) {
  .tech-block__text {
    width: 385px;
  }
}

@media (max-width: 1024px) {
  .tech-block__text {
    margin-bottom: 70px;
    width: 355px;
  }
}

@media (max-width: 767px) {
  .tech-block__text {
    width: auto;
    margin: 0 0 26px;
  }
}

.tech-block__text p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 0;
}

@media (max-width: 1024px) {
  .tech-block__text p {
    line-height: 25px;
  }
}

@media (max-width: 767px) {
  .tech-block__text p {
    font-size: 14px;
    line-height: 20px;
  }
}

.tech-block__content {
  width: 1006px;
  margin: 0 auto;
  padding-top: 70px;
  position: relative;
  z-index: 101;
}

@media (max-width: 1075px) {
  .tech-block__content {
    width: 907px;
  }
}

@media (max-width: 1024px) {
  .tech-block__content {
    width: auto;
    padding-top: 37px;
  }
}

.tech-block__nav {
  margin: 0 0 0 1px;
  padding: 0;
  width: 502px;
}

@media (max-width: 767px) {
  .tech-block__nav {
    width: 100%;
  }
}

.tech-block__nav ul {
  float: left;
  list-style-type: none;
  padding: 0;
  margin-left: 40px;
  width: 100%;
}

.tech-block__nav ul:first-child {
  margin-left: 0;
}

@media (max-width: 1075px) {
  .tech-block__nav ul {
    width: 424px;
    margin: 0;
  }
}

@media (max-width: 1024px) {
  .tech-block__nav ul {
    width: 390px;
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 767px) {
  .tech-block__nav ul {
    float: none;
    padding: 0;
    width: 100%;
  }
}

.tech-block__nav ul li {
  margin: 0 0 21px;
  width: 49%;
  display: inline-block;
}

@media (max-width: 1024px) {
  .tech-block__nav ul li {
    margin: 0 0 17px;
  }
}

@media (max-width: 767px) {
  .tech-block__nav ul li {
    float: none;
    margin-bottom: 12px;
    width: 100%;
  }
}

.tech-block__nav ul li a {
  font-size: 15px;
  color: #171717;
  font-weight: 600;
  position: relative;
  display: block;
}

@media (max-width: 1024px) {
  .tech-block__nav ul li a {
    font-size: 13px;
  }
}

.tech-block__nav ul li a span {
  position: relative;
}

.tech-block__nav ul li a span:after {
  content: "";
  height: 2px;
  -webkit-transition: right 0.1s ease;
  transition: right 0.1s ease;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 100%;
  background: #0072f6;
}

.tech-block__nav ul li a:hover {
  border-bottom: none;
  color: #0072f6;
}

.tech-block__nav ul li a:hover span:after {
  right: -5px;
}

.tech-block__imac {
  background: url(img/harley_main.jpg) no-repeat;
  position: absolute;
  z-index: 0;
  width: 961px;
  height: 805px;
  background-size: 100% 100%;
  right: 50%;
  margin-right: 198px;
  top: 0;
}

@media (max-width: 1075px) {
  .tech-block__imac {
    top: 625px;
  }
}

@media (max-width: 1024px) {
  .tech-block__imac {
    width: 671px;
    height: 562px;
    margin-right: 50px;
    top: 695px;
  }
}

@media (max-width: 767px) {
  .tech-block__imac {
    display: none;
  }
}

.tech-block__ipad {
  background: url(img/ey.jpg) no-repeat;
  z-index: 0;
  width: 721px;
  height: 494px;
  background-size: 100% 100%;
  margin: 0 0 0 71px;
  position: relative;
  top: 50px;
}

@media (max-width: 1075px) {
  .tech-block__ipad {
    top: 150px;
  }
}

@media (max-width: 1024px) {
  .tech-block__ipad {
    width: 525px;
    height: 345px;
    margin: 0 0 0 47px;
    top: 223px;
  }
}

.tech-block__macbook {
  background: url(img/jaguar.jpg) no-repeat;
  z-index: 0;
  width: 1138px;
  height: 670px;
  background-size: 100% 100%;
}

@media (max-width: 1024px) {
  .tech-block__macbook {
    width: 800px;
    height: 470px;
  }
}

.tech-block__mooving-couple {
  position: absolute;
  top: 50px;
  left: 50%;
  margin-left: -105px;
  z-index: 100;
}

@media (max-width: 1075px) {
  .tech-block__mooving-couple {
    top: 3px;
  }
}

@media (max-width: 1024px) {
  .tech-block__mooving-couple {
    margin-left: 23px;
    top: 150px;
  }
}

@media (max-width: 767px) {
  .tech-block__mooving-couple {
    display: none;
  }
}

.text-block {
  margin: 10px 0 100px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
}

@media screen and (max-width: 1279px) {
  .text-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .text-block {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.text-block__sidebar {
  padding-right: 70px;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  max-width: 294px;
  width: 100%;
}

@media screen and (max-width: 1279px) {
  .text-block__sidebar {
    width: 100%;
  }
}

.text-block__sidebar h3 {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 28px;
}

.text-block__sidebar p {
  font-size: 15px;
  font-weight: 300;
  line-height: 25px;
  margin: 0 0 47px;
}

.text-block__content {
  -ms-flex-negative: 3;
  flex-shrink: 3;
  position: relative;
}

.text-block__content:before {
  content: "";
  position: absolute;
  display: block;
  width: 100px;
  height: 8px;
  top: -55px;
  left: 0;
  background: #000;
}

@media screen and (max-width: 1279px) {
  .text-block__content {
    position: static;
  }
}

.text-block__content p {
  font-size: 15px;
  line-height: 25px;
  font-weight: 300;
  margin-bottom: 25px;
}

.text-block__content p:last-child {
  margin-bottom: 0;
}

.thank {
  border: 1px solid #dfdfdf;
  padding: 35px;
  margin: 0 auto;
  max-width: 580px;
  width: 100%;
}

.thank--border-none {
  border: none;
}

.thank__content {
  color: #000;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.thank__content--white {
  color: #fff;
}

.thank__content--blue {
  color: #1476f2;
}

.thank__title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .thank__title {
    font-size: 20px;
  }
}

.thank__title--big {
  font-size: 28px;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .thank__title--big {
    font-size: 22px;
  }
}

.thank__text {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 20px;
  max-width: 360px;
}

@media screen and (max-width: 767px) {
  .thank__text {
    font-size: 16px;
  }
}

.title {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #fff;
  text-transform: uppercase;
}

.title--black {
  color: #323232;
  text-align: center;
}

.title--uppercase,
h2 {
  text-transform: uppercase;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .title--margin-top {
    margin-top: 150px;
  }
}

@media screen and (max-width: 480px) {
  .title--margin-top {
    margin-top: 80px;
  }
}

.title--cases {
  color: #000;
  font-weight: 600;
  font-size: 36px;
  text-transform: uppercase;
}

@media screen and (max-width: 1023px) {
  .title--cases {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .title--cases .title__preheader {
    display: none;
  }
}

.title__preheader {
  color: inherit;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  opacity: 0.3;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.title__preheader--tag {
  background-color: rgba(0, 114, 246, 0.8);
  font-size: 13px;
  font-weight: 500;
  opacity: 1;
  padding: 5px 10px 4px;
  -ms-flex-item-align: start;
  align-self: flex-start;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 1023px) {
  .title__preheader--tag {
    font-size: 14px;
  }
}

.title__preheader--gray {
  opacity: 0.6;
  background-color: #a7a7a7;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #fff;
}

.title__preheader--white {
  color: #fff;
}

.title--medium,
h2 {
  font-size: 32px;
  margin-bottom: 15px;
}

.title--sitemap {
  font-size: 30px;
  font-weight: 600;
  line-height: 1em;
  color: #323232;
}

.static-page-title {
  background-image: url(img/map-gray.png);
  background-size: 55%;
  background-position: 50% -166px;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media screen and (max-width: 1440px) {
  .static-page-title {
    background-position: 50% -100px;
  }
}

@media screen and (max-width: 1024px) {
  .static-page-title {
    background-size: contain;
    background-position: 50% -10px;
  }
}

@media screen and (max-width: 600px) {
  .static-page-title {
    background-size: cover;
    background-position: 50% -130px;
  }
}

.static-page-title__heading {
  font-size: 40px;
  line-height: 1em;
  text-transform: uppercase;
  color: #323232;
  font-weight: 600;
  font-family: Montserrat, Arial, sans-serif;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .static-page-title__heading {
    font-size: 30px;
  }
}

.static-page-title__additional-text {
  font-family: Montserrat;
  font-size: 15px;
  line-height: 1.67;
  text-align: center;
  color: #555;
  font-weight: 400;
  padding-top: 15px;
  max-width: 745px;
  margin: auto;
}

.static-page-title {
  padding: 235px 0 60px;
}

p.description {
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: rgba(50, 50, 50, 0.7);
  width: 100%;
  max-width: 800px;
  margin: auto;
}

@media screen and (max-width: 1023px) {
  p.description {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  p.description {
    font-size: 14px;
  }
}

.cd-intro {
  max-width: 1006px;
  margin: 0 auto;
  min-height: 180px;
}

.cd-title {
  position: relative;
  height: 160px;
  line-height: 230px;
  text-align: center;
}

.cd-title h1 {
  font-size: 2.4rem;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .cd-title {
    line-height: 250px;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-title {
    height: 200px;
    line-height: 300px;
  }

  .cd-title h1 {
    font-size: 3rem;
  }
}

.cd-headline {
  font-size: 24px;
  color: #0072f6;
}

.cd-words-wrapper {
  display: block;
  font-size: 36px;
  color: #000;
  position: relative;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .cd-words-wrapper {
    font-size: 24px;
  }
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.cd-words-wrapper b.is-visible {
  position: relative;
}

.no-js .cd-words-wrapper b {
  opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

.cd-headline.rotate-1 .cd-words-wrapper {
  -webkit-perspective: 300px;
  perspective: 300px;
}

.cd-headline.rotate-1 b {
  opacity: 0;
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.cd-headline.rotate-1 b.is-visible {
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-animation: cd-rotate-1-in 1.2s;
  animation: cd-rotate-1-in 1.2s;
}

.cd-headline.rotate-1 b.is-hidden {
  -webkit-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-animation: cd-rotate-1-out 1.2s;
  animation: cd-rotate-1-out 1.2s;
}

.cd-headline.type .cd-words-wrapper {
  vertical-align: top;
  overflow: hidden;
}

.cd-headline.type .cd-words-wrapper:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 90%;
  width: 1px;
  background-color: #aebcb9;
}

.cd-headline.type .cd-words-wrapper.waiting:after {
  -webkit-animation: cd-pulse 1s infinite;
  animation: cd-pulse 1s infinite;
}

.cd-headline.type .cd-words-wrapper.selected {
  background-color: #aebcb9;
}

.cd-headline.type .cd-words-wrapper.selected:after {
  visibility: hidden;
}

.cd-headline.type .cd-words-wrapper.selected b {
  color: #0d0d0d;
}

.cd-headline.type b {
  visibility: hidden;
}

.cd-headline.type b.is-visible {
  visibility: visible;
}

.cd-headline.type i {
  position: absolute;
  visibility: hidden;
}

.cd-headline.type i.in {
  position: relative;
  visibility: visible;
  font-style: normal;
}

.cd-headline.rotate-2 .cd-words-wrapper {
  -webkit-perspective: 300px;
  perspective: 300px;
}

.cd-headline.rotate-2 em,
.cd-headline.rotate-2 i {
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cd-headline.rotate-2 b {
  opacity: 0;
}

.cd-headline.rotate-2 i {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-20px) rotateX(90deg);
  -ms-transform: translateZ(-20px) rotateX(90deg);
  transform: translateZ(-20px) rotateX(90deg);
  opacity: 0;
}

.is-visible .cd-headline.rotate-2 i {
  opacity: 1;
}

.cd-headline.rotate-2 i.in {
  -webkit-animation: cd-rotate-2-in 0.4s forwards;
  animation: cd-rotate-2-in 0.4s forwards;
}

.cd-headline.rotate-2 i.out {
  -webkit-animation: cd-rotate-2-out 0.4s forwards;
  animation: cd-rotate-2-out 0.4s forwards;
}

.cd-headline.rotate-2 em {
  -webkit-transform: translateZ(20px);
  -ms-transform: translateZ(20px);
  transform: translateZ(20px);
}

.no-csstransitions .cd-headline.rotate-2 i {
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 0;
}

.no-csstransitions .cd-headline.rotate-2 i em {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.no-csstransitions .cd-headline.rotate-2 .is-visible i {
  opacity: 1;
}

.cd-headline.loading-bar span {
  display: inline-block;
  padding: 0.2em 0;
}

.cd-headline.loading-bar .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.loading-bar .cd-words-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 0;
  background: #0096a7;
  z-index: 2;
  -webkit-transition: width 0.3s -0.1s;
  transition: width 0.3s -0.1s;
}

.cd-headline.loading-bar .cd-words-wrapper.is-loading:after {
  width: 100%;
  -webkit-transition: width 3s;
  transition: width 3s;
}

.cd-headline.loading-bar b {
  top: 0.2em;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.cd-headline.loading-bar b.is-visible {
  opacity: 1;
  top: 0;
}

.cd-headline.slide span {
  display: block;
  padding: 0.2em 0;
  text-transform: uppercase;
}

.cd-headline.slide .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.slide b {
  opacity: 0;
  top: 0.2em;
  text-transform: uppercase;
}

.cd-headline.slide b.is-visible {
  top: 0;
  opacity: 1;
  -webkit-animation: slide-in 0.6s;
  animation: slide-in 0.6s;
}

.cd-headline.slide b.is-hidden {
  -webkit-animation: slide-out 0.6s;
  animation: slide-out 0.6s;
}

@-webkit-keyframes cd-rotate-1-in {
  0% {
    -webkit-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    transform: rotateX(180deg);
    opacity: 0;
  }
  35% {
    -webkit-transform: rotateX(120deg);
    -ms-transform: rotateX(120deg);
    transform: rotateX(120deg);
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  to {
    -webkit-transform: rotateX(1turn);
    -ms-transform: rotateX(1turn);
    transform: rotateX(1turn);
    opacity: 1;
  }
}

@keyframes cd-rotate-1-in {
  0% {
    -webkit-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    transform: rotateX(180deg);
    opacity: 0;
  }
  35% {
    -webkit-transform: rotateX(120deg);
    -ms-transform: rotateX(120deg);
    transform: rotateX(120deg);
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  to {
    -webkit-transform: rotateX(1turn);
    -ms-transform: rotateX(1turn);
    transform: rotateX(1turn);
    opacity: 1;
  }
}

@-webkit-keyframes cd-rotate-1-out {
  0% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
  35% {
    -webkit-transform: rotateX(-40deg);
    -ms-transform: rotateX(-40deg);
    transform: rotateX(-40deg);
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  to {
    -webkit-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    transform: rotateX(180deg);
    opacity: 0;
  }
}

@keyframes cd-rotate-1-out {
  0% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
  35% {
    -webkit-transform: rotateX(-40deg);
    -ms-transform: rotateX(-40deg);
    transform: rotateX(-40deg);
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  to {
    -webkit-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    transform: rotateX(180deg);
    opacity: 0;
  }
}

@-webkit-keyframes cd-pulse {
  0% {
    -webkit-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-50%) scale(0.9);
    -ms-transform: translateY(-50%) scale(0.9);
    transform: translateY(-50%) scale(0.9);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(-50%) scale(0);
    -ms-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
    opacity: 0;
  }
}

@keyframes cd-pulse {
  0% {
    -webkit-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-50%) scale(0.9);
    -ms-transform: translateY(-50%) scale(0.9);
    transform: translateY(-50%) scale(0.9);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(-50%) scale(0);
    -ms-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
    opacity: 0;
  }
}

@-webkit-keyframes cd-rotate-2-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(90deg);
    -ms-transform: translateZ(-20px) rotateX(90deg);
    transform: translateZ(-20px) rotateX(90deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(-10deg);
    -ms-transform: translateZ(-20px) rotateX(-10deg);
    transform: translateZ(-20px) rotateX(-10deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0deg);
    -ms-transform: translateZ(-20px) rotateX(0deg);
    transform: translateZ(-20px) rotateX(0deg);
  }
}

@keyframes cd-rotate-2-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(90deg);
    -ms-transform: translateZ(-20px) rotateX(90deg);
    transform: translateZ(-20px) rotateX(90deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(-10deg);
    -ms-transform: translateZ(-20px) rotateX(-10deg);
    transform: translateZ(-20px) rotateX(-10deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0deg);
    -ms-transform: translateZ(-20px) rotateX(0deg);
    transform: translateZ(-20px) rotateX(0deg);
  }
}

@-webkit-keyframes cd-rotate-2-out {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0);
    -ms-transform: translateZ(-20px) rotateX(0);
    transform: translateZ(-20px) rotateX(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-100deg);
    -ms-transform: translateZ(-20px) rotateX(-100deg);
    transform: translateZ(-20px) rotateX(-100deg);
  }
  to {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-90deg);
    -ms-transform: translateZ(-20px) rotateX(-90deg);
    transform: translateZ(-20px) rotateX(-90deg);
  }
}

@keyframes cd-rotate-2-out {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(-20px) rotateX(0);
    -ms-transform: translateZ(-20px) rotateX(0);
    transform: translateZ(-20px) rotateX(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-100deg);
    -ms-transform: translateZ(-20px) rotateX(-100deg);
    transform: translateZ(-20px) rotateX(-100deg);
  }
  to {
    opacity: 0;
    -webkit-transform: translateZ(-20px) rotateX(-90deg);
    -ms-transform: translateZ(-20px) rotateX(-90deg);
    transform: translateZ(-20px) rotateX(-90deg);
  }
}

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
    -ms-transform: translateY(20%);
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
    -ms-transform: translateY(20%);
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
    -ms-transform: translateY(120%);
    transform: translateY(120%);
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
    -ms-transform: translateY(120%);
    transform: translateY(120%);
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.page-title h1 {
  font-weight: 900;
  font-size: 60px;
  line-height: 65px;
  color: #121212;
  margin: 0 0 13px;
}

@media screen and (max-width: 1023px) {
  .page-title h1 {
    font-size: 36px;
    line-height: 40px;
  }
}

.page-title h2 {
  font-weight: 900;
  font-size: 60px;
  line-height: 65px;
  color: #121212;
}

@media screen and (max-width: 1023px) {
  .page-title h2 {
    font-size: 36px;
    line-height: 40px;
  }
}

@media screen and (max-width: 600px) {
  .page-title h2 {
    font-size: 28px;
    line-height: 34px;
  }
}

.page-lead p {
  font-size: 22px;
  line-height: 35px;
  font-weight: 700;
}

@media screen and (max-width: 1023px) {
  .page-lead p {
    font-size: 18px;
    line-height: 1.5;
  }
}

.block-title {
  margin-bottom: 50px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .block-title {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.block-title h1 {
  font-size: 36px;
  font-weight: 900;
}

.block-title__link {
  font-size: 11px;
  color: #000;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .block-title__link {
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-top: 20px;
  }
}

.block-title__link--home {
  margin-right: 50px;
}

@media screen and (max-width: 767px) {
  .block-title__link--home {
    margin-right: 0;
  }
}

.partners .page-title {
  margin: 70px 0 80px;
}

.fade {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.cf:after,
section:after {
  content: "";
  display: table;
  clear: both;
}

.container {
  margin: 0 auto;
  padding: 0 40px;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .container {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    width: 1200px;
    padding: 0;
  }
}

.container--flex {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .container--flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (min-width: 1024px) {
  .container--post {
    width: 1000px;
  }
}

a {
  color: #171717;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

a:hover {
  color: #0072f6;
  -webkit-transition: none;
  transition: none;
}

.btn {
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  min-width: 140px;
  font-size: 13px;
  padding: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
  background: #1476f2;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  white-space: nowrap;
}

.btn [class*=" isoi-"],
.btn [class^="isoi-"] {
  font-size: 12px;
  color: #1476f2;
  padding-left: 12px;
  display: block;
}

.btn:hover {
  background: #005ed7;
  border-color: #005ed7;
  color: #fff;
}

.btn:hover [class*=" isoi-"],
.btn:hover [class^="isoi-"] {
  color: #fff;
}

.btn:active {
  background: #323232;
  border-color: #323232;
  color: #fff;
}

.btn:active [class*=" isoi-"],
.btn:active [class^="isoi-"] {
  color: #fff;
}

.btn.btn--about-news {
  height: 50px;
  min-width: 170px;
}

.btn--fluid {
  width: 100%;
  padding: 0;
}

#fp-nav ul li:last-child {
  display: none;
}

.js-show {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.overflow-hidden {
  overflow: hidden;
}

.js-hide {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.static {
  padding-top: 230px;
}

.notify {
  background-color: #e74c3c;
  color: #fff;
  cursor: pointer;
  padding: 20px;
  width: 350px;
  opacity: 0;
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(200%);
  -ms-transform: translateY(200%);
  transform: translateY(200%);
  z-index: 9;
}

.text-white {
  color: #fff !important;
}

.text-center {
  text-align: center;
}

.m-none {
  margin: 0 !important;
}

.m-left-none {
  margin-left: 0 !important;
}

.m-right-none {
  margin-right: 0 !important;
}

@media screen and (min-width: 768px) {
  .m-right-md-none {
    margin-right: 0 !important;
  }
}

.m-top-none {
  margin-top: 0 !important;
}

.m-bottom-none {
  margin-bottom: 0 !important;
}

.p-none {
  padding: 0 !important;
}

.p-left-none {
  padding-left: 0 !important;
}

.p-right-none {
  padding-right: 0 !important;
}

.p-top-none {
  padding-top: 0 !important;
}

.p-bottom-none {
  padding-bottom: 0 !important;
}

@media screen and (max-width: 768px) {
  .flex-dir-mobile {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 1023px) {
  .hide-tablet {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .hide-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 1023px) {
  .show-tablet {
    display: none;
  }
}

.bg-fluid {
  background-size: cover;
  background-position: 50%;
}

.section-margin {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .section-margin {
    margin-bottom: 40px;
  }
}

.egg {
  position: fixed;
  height: auto;
  width: 100%;
  z-index: 9999;
  display: block;
}

.m-bottom-35 {
  margin-bottom: 35px;
}

.parallax-image-100 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
}

.video {
  background-image: url(img/video-bg.jpg);
  background-position: top;
  max-width: 1200px;
  margin: 0 auto;
  background-repeat: no-repeat;
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
  background-color: rgba(0, 0, 0, 0.25);
  background-blend-mode: overlay;
}

@media (max-width: 767px) {
  .video {
    display: none;
  }
}

.video__wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.video__wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.video__company {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
  line-height: 1;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__company span {
  margin-left: 50px;
}

@media (max-width: 1215px) {
  .video__company {
    bottom: 5%;
  }
}

.video__lead {
  font-size: 18px;
  position: absolute;
  bottom: 10%;
}

@media (max-width: 1215px) {
  .video__lead {
    font-size: 16px;
    bottom: 15%;
  }
}

.video__content {
  text-align: center;
  color: #fff;
  max-width: 600px;
  width: 100%;
  max-height: 430px;
  height: 100%;
}

.video__content,
.video__content .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video__content .video-btn {
  display: block;
  background-image: url(img/video-btn.png);
  width: 104px;
  height: 104px;
  cursor: pointer;
}

.video__content h2 {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 40px;
  position: relative;
  top: 0;
}

@media (max-width: 1023px) {
  .video__content {
    max-width: 510px;
  }
}

@media (max-width: 1023px) {
  .video__content .video-btn {
    top: 42%;
  }
}

@media (max-width: 1215px) {
  .video__content h2 {
    font-size: 24px;
    line-height: 30px;
    top: 5%;
  }
}

.stats {
  position: relative;
  padding: 90px 0 114px;
  text-align: center;
  color: #fff;
  overflow: hidden;
}

.stats .bg-stats-clients {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1476f2;
  z-index: -1;
}

.stats .bg-stats-clients .bg-img {
  display: block;
  margin: 0 auto;
  width: 180%;
  max-width: 180%;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-animation: 30s scale-in infinite linear;
  animation: 30s scale-in infinite linear;
}

@media (min-width: 520px) {
  .stats .bg-stats-clients .bg-img {
    width: 120%;
    max-width: 120%;
  }
}

@media (min-width: 768px) {
  .stats .bg-stats-clients .bg-img {
    width: 80%;
    max-width: 80%;
  }
}

@media (min-width: 1040px) {
  .stats .bg-stats-clients .bg-img {
    width: 65%;
    max-width: 65%;
  }
}

@media (min-width: 1200px) {
  .stats .bg-stats-clients .bg-img {
    width: 50%;
    max-width: 50%;
  }
}

@-webkit-keyframes scale-in {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes scale-in {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

.stats--blue {
  background-color: #0072f6;
  background-image: none;
  padding: 45px 0;
}

.stats--white {
  color: #0072f6;
  background-color: #fff;
  background-image: none;
  padding: 0;
}

.stats__wrapper {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -35px 0;
}

.stats__title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.stats__lead {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 60px;
}

.stats__item {
  width: 33%;
  padding: 35px 0;
}

.stats__item--4 {
  width: 25%;
}

.stats__content {
  max-width: 230px;
  width: 100%;
  margin: 0 auto;
}

.stats__content--vertically {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.stats__number {
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
}

.stats__number--inline {
  margin: 0;
}

.stats__number--massive {
  font-size: 80px;
}

.stats__number--large {
  font-size: 40px;
}

.stats__heading {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.stats__heading--inline {
  margin: 0;
  padding-left: 15px;
}

.stats__heading--black {
  color: #323232;
}

.stats__text {
  line-height: 1.33;
  opacity: 0.8;
}

@media screen and (max-width: 1023px) {
  .stats__item--4 {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .stats__item,
  .stats__item--4 {
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .stats--overlay {
    background-color: rgba(0, 114, 246, 0.9);
    margin-top: -130px;
    position: relative;
    z-index: 2;
  }
}

@media (max-device-width: 1024px) {
  .stats__content--vertically {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .stats__heading--inline {
    padding-left: 0;
    padding-top: 5px;
  }

  .stats__item {
    padding: 25px 0;
  }
}

.icons {
  background-image: url(img/bg-clients-icon.jpg);
  background-position: top;
  background-repeat: no-repeat;
  padding: 80px 0 60px;
}

.icons h2 {
  color: #323232;
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 55px;
}

.icons ul {
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: 0 -20px;
}

.icons li,
.icons ul {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.icons li {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.icons i {
  font-size: 50px;
  display: block;
  color: #505050;
  margin-bottom: 20px;
}

.icons h3 {
  font-size: 16px;
  font-weight: 400;
  color: #323232;
}

@media screen and (max-width: 767px) {
  .icons li {
    width: 50%;
  }

  .icons ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .icons li {
    width: 100%;
  }
}

.clients {
  padding: 0 0 70px;
  position: relative;
}

.clients--kado .clients__content--blue {
  background: url(img/kado.jpg)
}

.clients--kado .clients__content--blue.ar {
  background: url(img/ar.jpg);
  background-position: 50%;
  background-size: cover;
}

.clients--kado .clients__content--blue.ar .clients__text--blue {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.clients--kado .clients__content--blue.ar .button--white {
  width: 180px;
}

.clients--kado .clients__content--blue.ar .clients__tag {
  font-weight: 400;
}

.clients--kado .clients__content--blue.iot {
  background: url(img/iot.jpg);
  background-position: 50%;
  background-size: cover;
}

.clients--kado .clients__content--blue.iot .clients__text--blue {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.clients--kado .clients__content--blue.iot .button--white {
  width: 180px;
}

.clients--kado .clients__content--blue.iot .clients__tag {
  font-weight: 400;
}

.clients--kado .clients__text--blue {
  background-color: rgba(0, 114, 246, 0);
}

.clients--kado img {
  margin-bottom: 20px;
}

.clients--kado .clients__title--massive {
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.clients--kado .clients__tag--medium {
  font-family: Montserrat;
  font-size: 15px;
  line-height: 1.67;
  text-align: center;
  color: #fff;
  font-weight: 300;
  text-transform: none;
  margin-bottom: 30px;
}

.clients__grid {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -10px;
}

.clients__grid,
.clients__item {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.clients__item {
  padding: 10px;
  width: 50%;
}

.clients__item:hover .clients__media-inner {
  -webkit-transform: scale(1.16);
  -ms-transform: scale(1.16);
  transform: scale(1.16);
}

.clients__item:hover .clients__tag:not(.clients__tag--medium) {
  margin-top: -15px;
  opacity: 0;
}

.clients__item:hover .clients__link--more {
  opacity: 1;
  bottom: 40px;
}

.clients__item:hover .clients__link--more i {
  margin-left: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

@media screen and (min-width: 1024px) {
  .clients__item:hover .clients__text--fluid {
    margin-top: -20px;
    height: 300px;
  }

  .clients__item:hover .clients__text--blue {
    height: 100%;
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .clients__item:hover div:not(.clients__content--blue) .clients__text {
    height: 280px;
    margin-top: -20px;
  }
}

@media screen and (max-width: 340px) {
  .clients__item:hover .clients__link--more {
    opacity: 1;
    bottom: 25px;
  }
}

.clients__item--2 {
  width: 50%;
}

.clients__item--3 {
  width: 33.33%;
}

.clients__item--4 {
  width: 25%;
}

@media screen and (max-width: 1185px) {
  .clients__item--4 {
    width: 50%;
  }
}

.clients__item--5 {
  width: 20%;
}

@media screen and (max-width: 1185px) {
  .clients__item--5 {
    width: 100%;
  }
}

.clients__content {
  background-color: #fff;
  background-size: cover;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  color: #323232;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
}

.clients__content:hover {
  -webkit-box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.1);
}

.clients__content--blue {
  background-image: url(img/bg-clients-blue.jpg)
}

.clients__media {
  height: 210px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  width: 30%;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .clients__media--blockchain {
    height: 230px;
  }
}

.clients__media-inner {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  /*background-color: #333;*/
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.clients__logo {
  position: relative;
  z-index: 1;
}

.clients__text {
  padding: 40px;
  width: 70%;
}

@media screen and (max-width: 767px) {
  .clients__text {
    height: 260px;
    margin-top: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: #fff;
    z-index: 2;
  }
}

.clients__text--blue {
  background-color: rgba(0, 114, 246, 0.9);
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.clients__tag {
  color: #a7a7a7;
  display: block;
  font-size: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 5px;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: 0;
}

.clients__tag--medium {
  font-size: 16px;
  color: #fff;
  margin-bottom: 15px;
}

.clients__tag--medium,
.clients__title {
  font-weight: 600;
  text-transform: uppercase;
}

.clients__title--massive {
  font-size: 80px;
  font-weight: 600;
  color: #fff;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .clients__title--massive {
    margin-top: 50px;
  }
}

.clients__title--fluid {
  position: absolute;
  left: 15%;
  bottom: 20%;
  max-width: 205px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
}

@media screen and (max-width: 1023px) {
  .clients__title--fluid {
    top: 15%;
  }
}

@media screen and (max-width: 767px) {
  .clients__title--fluid {
    max-width: none;
    top: unset;
    left: 10%;
    bottom: 20%;
  }
}

.clients__title--blockchain {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.54;
  text-align: left;
  color: #323232;
  text-transform: unset;
}

.clients__description {
  margin-top: 5px;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.54;
  width: 100%;
}

.clients__description--blockchain {
  margin-bottom: 5px;
  font-weight: 600;
  color: #323232;
}

.clients__description--medium {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .clients__description--medium {
    margin-bottom: 80px;
  }
}

.clients__link {
  color: #a7a7a7;
  font-size: 14px;
  bottom: 30px;
}

.clients__link,
.clients__link--more {
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
}

.clients__link--more {
  font-size: 13px;
  line-height: 1;
  color: #1476f2;
  bottom: 10px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

.clients__link--more i {
  font-size: 11px;
  margin-left: -3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.clients__link--more:hover {
  color: #005ed7;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .clients__text--blue {
    min-height: 390px;
  }
}

@media screen and (max-width: 1023px) {
  .clients__item {
    width: 100%;
  }

  .clients__text {
    padding: 30px;
  }

  .clients__item--three {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .clients__media {
    width: 100%;
    height: 140px;
  }

  .clients__text {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .clients__content--block {
    display: block;
  }

  .clients__media--fluid {
    width: 100%;
    height: 130px;
  }

  .clients__text--fluid {
    width: 100%;
    height: 280px;
    background: #fff;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .clients__text--blue {
    height: 100%;
    background-color: rgba(0, 114, 246, 0.9);
  }
}

.text {
  color: #555;
  font-size: 16px;
  line-height: 1.6;
}

.text--w-large {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.text--w-medium {
  max-width: 635px;
  margin: 0 auto;
  width: 100%;
}

.text--w-massive {
  max-width: 920px;
  margin: 0 auto;
  width: 100%;
}

.text--white {
  color: #fff;
}

.text--center {
  text-align: center;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--s-small {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.67;
}

.text--s-medium {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
}

@media screen and (max-width: 767px) {
  .text--s-medium {
    font-size: 24px;
  }
}

.clients-success {
  padding-bottom: 60px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .clients-success {
    padding-bottom: 0;
  }
}

.clients-success .container {
  width: 100%;
  max-width: 1120px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.clients-success .container h2.title {
  padding: 60px 0 10px;
  font-size: 32px;
  font-weight: 600;
  line-height: 0.94;
  text-align: center;
  color: #323232;
}

a.all-link {
  margin: 0 auto;
  color: #0072f6;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 191px;
  font-size: 13px;
  padding: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  white-space: nowrap;
}

a.all-link:hover {
  color: #005ed7;
}

a.all-link i {
  padding-left: 12px;
}

a.all-link-about {
  margin-top: 27px;
}

@media screen and (max-width: 767px) {
  a.all-link .container {
    padding: 0;
  }

  a.all-link .container h2.title {
    margin: 3px 0;
    padding: 46px 0;
    font-size: 24px;
    line-height: 1.25;
    background-color: #fff;
  }
}

.swiper-container {
  width: 100%;
  height: 420px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 0.6s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.6s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.6s ease, transform 0.3s ease;
  transition: opacity 0.6s ease, transform 0.3s ease,
  -webkit-transform 0.3s ease;
}

@media screen and (max-width: 767px) {
  .swiper-container {
    height: 360px;
  }
}

.swiper-container.main-slider {
  margin: 40px 0 2px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.03);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.03);
}

@media screen and (max-width: 480px) {
  .swiper-container.main-slider {
    margin-top: 18px;
    height: 552px;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.swiper-container.nav-slider {
  width: 100%;
  height: 110px;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  background-color: rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.03);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.03);
}

@media screen and (max-width: 480px) {
  .swiper-container.nav-slider {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: 40px;
  }
}

.swiper-container.nav-slider .swiper-slide {
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.swiper-container.nav-slider .swiper-slide .logo-nav {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-bottom 0.3s ease;
  transition: border-bottom 0.3s ease;
}

.swiper-container.nav-slider .swiper-slide .logo-nav .entity-img {
  opacity: 0.7;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.swiper-container.nav-slider .swiper-slide.swiper-slide-active .logo-nav {
  border-bottom: 2px solid #1476f2;
}

.swiper-container.nav-slider
.swiper-slide.swiper-slide-active
.logo-nav
.entity-img {
  opacity: 1;
}

.swiper-container.loading {
  opacity: 0;
  visibility: hidden;
}

.swiper-slide {
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

.swiper-slide .entity-img {
  width: 100%;
  max-width: 120px;
  max-height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.main-slider .slide-wrapper {
  width: 100%;
  height: 100%;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

@media screen and (max-width: 480px) {
  .main-slider .slide-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
}

.main-slider .clients-image {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 60%;
  flex: 0 1 60%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
}

.main-slider .clients-image .entity-img {
  display: none;
}

@media screen and (max-width: 480px) {
  .main-slider .clients-image {
    margin: 0 auto;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 40%;
    flex: 0 1 40%;
    max-height: 202px;
    height: 100%;
  }
}

.swiper-container .content {
  padding: 50px 40px;
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 30%;
  flex: 0 1 30%;
  height: 100%;
  color: #323232;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .swiper-container .content {
    padding: 30px 25px;
  }
}

@media screen and (max-width: 480px) {
  .swiper-container .content {
    padding: 30px 25px 0;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 60%;
    flex: 0 1 60%;
    max-height: 350px;
    height: 100%;
  }
}

.swiper-container .content .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  text-align: left;
  color: #323232;
  text-transform: capitalize;
}

@media screen and (max-width: 767px) {
  .swiper-container .content .title .title {
    font-size: 18px;
    line-height: 1;
  }
}

@media screen and (max-width: 480px) {
  .swiper-container .content .title {
    padding: 0;
    font-size: 24px;
    line-height: 1.25;
  }
}

.swiper-container .content .caption {
  margin: 13px auto 36px;
  font-size: 16px;
  line-height: 1.56;
  text-align: left;
  color: #323232;
  display: block;
  opacity: 1;
}

.swiper-container .content .caption.show {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .swiper-container .content .caption {
    font-size: 15px;
    line-height: 1.25;
  }
}

@media screen and (max-width: 480px) {
  .swiper-container .content .caption {
    margin: 13px auto;
  }
}

.swiper-container .content .content-link-more {
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  color: #1476f2;
  text-transform: uppercase;
  position: absolute;
  bottom: 45px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.swiper-container .content .content-link-more i {
  font-size: 11px;
  margin-left: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

@media screen and (max-width: 767px) {
  .swiper-container .content .content-link-more {
    opacity: 1;
    font-size: 13px;
    bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .swiper-container .content .content-link-more {
    font-size: 13px;
    bottom: 40px;
  }
}

.swiper-container :hover .content-link-more {
  opacity: 1;
  bottom: 60px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.swiper-container:hover .content-link-more i {
  margin-left: 12px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

@media screen and (max-width: 767px) {
  .swiper-container :hover .content-link-more {
    bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .swiper-container :hover .content-link-more {
    bottom: 50px;
  }
}

.divider-b-100 {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .divider-b-100 {
    padding-bottom: 50px;
  }
}

.divider-b-80 {
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .divider-b-80 {
    padding-bottom: 50px;
  }
}

.divider-b-75 {
  padding-top: 94px;
  padding-bottom: 75px;
}

@media screen and (max-width: 768px) {
  .divider-b-75 {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  .divider-b-75 {
    padding-bottom: 25px;
  }
}

.divider-t-100 {
  padding-top: 100px;
}

@media screen and (max-width: 767px) {
  .divider-t-100 {
    padding-top: 50px;
  }
}

.divider-t-70 {
  padding-top: 70px;
}

.divider-t-50 {
  padding-top: 50px;
}

.divider-130 {
  padding: 130px 0;
}

@media screen and (max-width: 767px) {
  .divider-130 {
    padding: 65px 0;
  }
}

.devider-t-230 {
  padding-top: 230px;
}

.map {
  height: 700px;
  margin-bottom: -250px;
  margin-top: 20px;
  overflow-x: scroll;
}

.map__image {
  background-image: url(img/bg-about-map.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin: 0 auto;
  height: 100%;
  width: 1280px;
}

@media screen and (max-width: 1023px) {
  .map {
    height: 600px;
  }
}

.bg {
  background-size: cover;
  background-position: 50%;
}

.bg-white {
  background-color: #fff;
}

.photo-slider {
  padding: 0 0 50px;
}

.photo-slider .owl-nav .owl-next,
.photo-slider .owl-nav .owl-prev {
  background-image: url(img/arrow.png);
  font-size: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 38px;
  width: 25px;
}

.photo-slider .owl-nav .owl-prev {
  background-position: 0 0;
  left: 40px;
}

.photo-slider .owl-nav .owl-prev:hover {
  background-position: -25px 0;
}

.photo-slider .owl-nav .owl-next {
  background-position: -77px 0;
  right: 40px;
}

.photo-slider .owl-nav .owl-next:hover {
  background-position: -51px 0;
}

.solution {
  background-color: transparent;
}

.solution__nav {
  width: 40%;
}

@media screen and (max-width: 1023px) {
  .solution__nav {
    display: none;
  }
}

.solution__cards {
  width: 60%;
}

@media screen and (max-width: 1023px) {
  .solution__cards {
    width: 100%;
  }
}

.solution__menu {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  padding: 70px 0;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .solution__menu {
    top: 25px;
  }
}

@media screen and (max-height: 645px) {
  .solution__menu {
    position: static;
  }
}

.solution__item {
  color: #a7a7a7;
  font-size: 14px;
  padding: 12px 0;
  list-style-type: square;
}

.solution__item:hover,
.solution__item_active,
.solution__item_active .solution__link {
  color: #0072f6;
}

.solution__card {
  padding: 20px 0;
  position: relative;
}

@media screen and (max-width: 1023px) {
  .solution__card {
    border-left: none;
  }
}

.solution__card:first-child {
  padding-top: 120px;
}

@media screen and (max-width: 1023px) {
  .solution__card:first-child {
    padding-top: 80px;
  }
}

.solution__card:last-child {
  padding-bottom: 70px;
}

.solution__card:after {
  content: "";
  background-color: hsla(0, 0%, 65%, 0.25);
  position: absolute;
  left: -1px;
  top: 0;
  height: 100%;
  width: 1px;
}

@media screen and (max-width: 1023px) {
  .solution__card:after {
    display: none;
  }
}

.solution__card_active .solution__title:before {
  background-color: #0072f6;
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
}

.solution__content {
  padding: 40px;
}

@media screen and (max-width: 1023px) {
  .solution__content {
    padding: 0;
  }
}

.solution__icon {
  display: inline-block;
  font-size: 60px;
  color: #505050;
  margin-bottom: 20px;
}

.solution__title {
  font-size: 16px;
  margin-bottom: 15px;
  text-transform: uppercase;
  position: relative;
}

.solution__title:before {
  content: "";
  display: block;
  background-color: #a7a7a7;
  position: absolute;
  top: 33%;
  left: -42.5px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  height: 5px;
  width: 5px;
  z-index: 1;
}

@media screen and (max-width: 1023px) {
  .solution__title:before {
    display: none;
  }
}

.solution__description {
  font-size: 14px;
  color: #555;
  line-height: 1.5;
}

.solution__icon {
  height: 60px;
  width: 60px;
}

.solution__readmore_link {
  font-family: Montserrat;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  color: #1476f2;
  margin: 15px 0 0;
  text-transform: uppercase;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.solution__readmore_link i {
  padding-left: 10px;
}

.solution__readmore_link:hover {
  color: #005ed7;
  -webkit-transition: none;
  transition: none;
}

.bullseye {
  background-color: #0072f6;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 700px;
  position: relative;
  color: #fff;
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  .bullseye {
    height: 500px;
  }
}

.bullseye__circle {
  background-image: url(img/bg-bullseye.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .bullseye__circle {
    background-position: 50% -50px;
  }
}

.bullseye__item {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.bullseye__item--25 {
  width: 25%;
}

@media screen and (max-width: 1023px) {
  .bullseye__item--25 {
    display: none;
  }
}

.bullseye__item--50 {
  width: 50%;
}

@media screen and (max-width: 1023px) {
  .bullseye__item--50 {
    width: 100%;
  }
}

.bullseye__content {
  text-align: center;
  height: 100%;
  width: 100%;
}

.bullseye__content h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  text-transform: uppercase;
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
}

.bullseye__content h3 + h3 {
  top: 53%;
}

@media screen and (max-width: 1110px) {
  .bullseye__content h3 {
    padding: 0 30px;
    top: 29%;
  }

  .bullseye__content h3 + h3 {
    top: 54%;
  }
}

@media screen and (max-width: 1023px) {
  .bullseye__content h3 {
    top: 35%;
  }

  .bullseye__content h3 + h3 {
    top: 54%;
  }
}

@media screen and (max-width: 767px) {
  .bullseye__content h3 {
    font-size: 28px;
    top: 20%;
  }

  .bullseye__content h3 + h3 {
    top: 56%;
  }
}

@media screen and (max-width: 604px) {
  .bullseye__content h3 {
    font-size: 28px;
    top: 30%;
  }

  .bullseye__content h3 + h3 {
    top: 46%;
  }
}

@media screen and (max-width: 604px) and (max-height: 360px) {
  .bullseye__content h3 {
    font-size: 28px;
    top: 19%;
  }

  .bullseye__content h3 + h3 {
    top: 48%;
  }
}

@media screen and (max-width: 542px) {
  .bullseye__content h3 {
    top: 25%;
  }

  .bullseye__content h3 + h3 {
    top: 46%;
  }
}

@media screen and (max-width: 379px) {
  .bullseye__content h3 {
    top: 20%;
  }

  .bullseye__content h3 + h3 {
    top: 46%;
  }
}

.bullseye__content p {
  line-height: 1.6;
  position: absolute;
  top: 64%;
  left: 0;
  right: 0;
  padding: 0 50px;
}

@media screen and (max-width: 767px) {
  .bullseye__content p {
    padding: 0 30px;
  }
}

.bullseye__arrow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
}

.bullseye__arrow:before {
  top: 25px;
  -webkit-animation-name: arrow;
  animation-name: arrow;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.bullseye__arrow:after,
.bullseye__arrow:before {
  content: "";
  background-image: url(img/icons/bullseye-arrow.svg);
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0.25;
  height: 12px;
  width: 24px;
}

.bullseye__arrow:after {
  top: 35px;
  -webkit-animation-name: arrow;
  animation-name: arrow;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.bullseye__macbook {
  background-position: 0;
}

.bullseye__ipad,
.bullseye__macbook {
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  height: 538px;
  width: 100%;
}

.bullseye__ipad {
  background-position: 150%;
  position: relative;
}

@media screen and (min-width: 1530px) {
  .bullseye__ipad {
    background-size: contain;
  }
}

@media screen and (min-width: 1280px) {
  .bullseye__ipad {
    background-position: -125px;
  }
}

.bullseye__ipad:after {
  content: "";
  background-image: url(img/bullseye-iphone.png);
  background-position: 100% 100%;
  background-size: 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 1530px) {
  .bullseye__ipad:after {
    background-position: 50% 100%;
    background-size: 40%;
  }
}

@-webkit-keyframes arrow {
  0%,
  to {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
}

@keyframes arrow {
  0%,
  to {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
}

.apocalypse {
  background-size: cover;
  background-position: 100%;
  padding: 150px 0;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .apocalypse {
    background-position: 70%;
  }
}

@media screen and (max-width: 1023px) {
  .apocalypse {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    padding: 75px 0;
  }
}

@media screen and (max-width: 767px) {
  .apocalypse {
    background-size: 300%;
    height: 1450px;
  }
}

.apocalypse--platforms {
  background-color: #19191a;
  background-image: url(img/feature-highlights/bg-platforms.jpg)
}

@media screen and (max-width: 1023px) {
  .apocalypse--platforms {
    background-image: url(img/feature-highlights/bg-platforms-mobile.jpg)
  }
}

.apocalypse--azure {
  background-color: #232323;
  background-image: url(img/feature-highlights/bg-azure.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 853px 3px;
}

@media screen and (max-width: 1440px) {
  .apocalypse--azure {
    background-position: 565px 3px;
  }
}

@media screen and (max-width: 1262px) {
  .apocalypse--azure {
    background-position: 430px 100px;
  }
}

@media screen and (max-width: 1024px) {
  .apocalypse--azure {
    background-position: 310px 174px;
  }
}

@media screen and (max-width: 1023px) {
  .apocalypse--azure {
    height: 900px;
    background-position: -80px 480px;
    background-size: 120%;
  }
}

@media screen and (max-width: 768px) {
  .apocalypse--azure {
    height: 1000px;
    background-position: -80px 550px;
    background-size: 163%;
  }
}

@media screen and (max-width: 425px) {
  .apocalypse--azure {
    height: 1000px;
    background-position: -100px 640px;
    background-size: 163%;
  }
}

@media screen and (max-width: 375px) {
  .apocalypse--azure {
    background-position: -140px 760px;
    background-size: 193%;
  }
}

.clutch {
  position: absolute;
  right: 90px;
  bottom: 80px;
  width: 160px;
  height: 173px;
  background-image: url(img/clutch.png);
  z-index: -1;
}

@media screen and (max-width: 1023px) {
  .clutch {
    right: 30px;
    bottom: 13px;
  }
}

@media screen and (max-width: 767px) {
  .clutch {
    bottom: -8%;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .clutch {
    bottom: -10%;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
}

@media screen and (max-width: 375px) {
  .clutch {
    bottom: -58px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
}

.apocalypse__list-wrapper {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1023px) {
  .apocalypse__list-wrapper {
    min-width: auto;
  }
}

@media screen and (max-width: 767px) {
  .apocalypse__list-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 480px;
    width: 100%;
  }
}

.apocalypse__tools {
  margin: 80px auto 0;
  width: 100%;
  max-width: 952px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 17px;
  border: 1px solid hsla(0, 0%, 59%, 0.76);
}

@media screen and (max-width: 1023px) {
  .apocalypse__tools {
    max-width: 408px;
  }
}

@media screen and (max-width: 320px) {
  .apocalypse__tools {
    max-width: 310px;
  }
}

.apocalypse__tools-title {
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 59%, 0.76);
}

.apocalypse__tools-title h2,
.apocalypse__tools-title h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 0.83;
  text-align: center;
  color: #fafafa;
  text-transform: uppercase;
}

@media screen and (max-width: 320px) {
  .apocalypse__tools-title h2,
  .apocalypse__tools-title h3 {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.1;
  }
}

.apocalypse__tools-items {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .apocalypse__tools-items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
}

.apocalypse__item-wrapper {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

@media screen and (max-width: 1023px) {
  .apocalypse__item-wrapper:not(:first-child) {
    border-top: 1px solid hsla(0, 0%, 59%, 0.76);
  }
}

.apocalypse__tools-item {
  width: 135px;
  height: 135px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 45px;
  padding-bottom: 25px;
  border-right: 1px solid hsla(0, 0%, 59%, 0.76);
}

@media screen and (max-width: 1023px) {
  .apocalypse__tools-item:nth-child(3n + 0) {
    border-right: none;
  }
}

@media screen and (max-width: 1023px) {
  .apocalypse__tools-item {
    width: 33.33333%;
  }
}

@media screen and (max-width: 640px) {
  .apocalypse__tools-item h3 {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .apocalypse__tools-item {
    padding-top: 20px;
    padding-bottom: 20px;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .apocalypse__tools-item h3 {
    font-size: 10px;
  }
}

.apocalypse__tools-item i {
  color: #fff;
}

.apocalypse__tools-item i.isoi-hyper {
  font-size: 27px;
}

.apocalypse__tools-item i.isoi-etherum {
  font-size: 31px;
}

.apocalypse__tools-item i.isoi-parity {
  font-size: 23px;
}

.apocalypse__tools-item i.isoi-exonum {
  font-size: 25px;
}

.apocalypse__tools-item i.isoi-r-3 {
  font-size: 23px;
}

.apocalypse__tools-item i.isoi-multichain {
  font-size: 26px;
}

.apocalypse__tools-item i.isoi-nxt {
  font-size: 12px;
}

.apocalypse__tools-item h3.small-text,
.apocalypse__tools-item p.small-text {
  font-weight: 400;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  max-width: 135px;
}

.apocalypse__tools-item--nxt {
  border-top: none;
  border-right: none;
}

@media screen and (max-width: 1023px) {
  .apocalypse__tools-item--nxt {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .apocalypse__tools-item--nxt {
    border: none;
  }
}

.apocalypse--augmented-reality,
.apocalypse--blockchain,
.apocalypse--fintech,
.apocalypse--internet-of-things {
  background-image: url(img/fintech-about-bg.jpg);
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .apocalypse--augmented-reality,
  .apocalypse--blockchain,
  .apocalypse--fintech,
  .apocalypse--internet-of-things {
    background-size: cover;
    height: auto;
    padding-bottom: 75px;
  }
}

.apocalypse--augmented-reality .apocalypse__list,
.apocalypse--blockchain .apocalypse__list,
.apocalypse--fintech .apocalypse__list,
.apocalypse--internet-of-things .apocalypse__list {
  width: 100%;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.apocalypse--augmented-reality .apocalypse__item,
.apocalypse--blockchain .apocalypse__item,
.apocalypse--fintech .apocalypse__item,
.apocalypse--internet-of-things .apocalypse__item {
  width: 50%;
  max-width: 420px;
}

@media screen and (max-width: 767px) {
  .apocalypse--augmented-reality .apocalypse__item,
  .apocalypse--blockchain .apocalypse__item,
  .apocalypse--fintech .apocalypse__item,
  .apocalypse--internet-of-things .apocalypse__item {
    width: 100%;
  }
}

.apocalypse--ai {
  background: url(img/ai-about-bg.jpg), #232323;
  background-size: contain;
  height: 800px;
  background-position: 50%;
}

.apocalypse--ai .apocalypse__title {
  max-width: 520px;
}

@media screen and (max-width: 767px) {
  .apocalypse--ai {
    background-size: cover;
    height: auto;
    padding-bottom: 75px;
  }
}

.apocalypse--ai .apocalypse__list {
  width: 100%;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.apocalypse--ai .apocalypse__item {
  width: 50%;
  max-width: 420px;
}

@media screen and (max-width: 767px) {
  .apocalypse--ai .apocalypse__item {
    width: 100%;
  }
}

.apocalypse--blockchain {
  background-image: url(img/blockchain-about-bg.jpg)
}

.apocalypse--blockchain .apocalypse__item {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 484px;
}

@media screen and (max-width: 1023px) {
  .apocalypse--blockchain .apocalypse__item {
    width: 80%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.apocalypse--blockchain .apocalypse__list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 484px;
}

@media screen and (max-width: 1050px) {
  .apocalypse--blockchain .apocalypse__list {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .apocalypse--blockchain .apocalypse__list {
    width: 90%;
    margin-top: 20px;
  }
}

.apocalypse--augmented-reality,
.apocalypse--internet-of-things {
  background: #232323;
  padding-bottom: 245px;
  background-image: url(img/augmented-reality-about-bg.jpg);
  background-size: 80%;
  background-position: 50% 100px;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1440px) {
  .apocalypse--augmented-reality,
  .apocalypse--internet-of-things {
    background-size: 100%;
    background-position: 50% 60 pxs;
  }
}

@media screen and (max-width: 1200px) {
  .apocalypse--augmented-reality,
  .apocalypse--internet-of-things {
    background-size: 105%;
    background-position: 50% 92px;
  }
}

@media screen and (max-width: 1024px) {
  .apocalypse--augmented-reality,
  .apocalypse--internet-of-things {
    background-size: 105%;
    background-position: 50% 110px;
  }
}

@media screen and (max-width: 768px) {
  .apocalypse--augmented-reality,
  .apocalypse--internet-of-things {
    background-size: 105%;
    background-position: 50% 190px;
  }
}

@media screen and (max-width: 425px) {
  .apocalypse--augmented-reality,
  .apocalypse--internet-of-things {
    background-size: 265%;
    background-position: 50% 100%;
  }
}

.apocalypse--augmented-reality .apocalypse__title,
.apocalypse--internet-of-things .apocalypse__title {
  text-align: center;
  margin: 0 auto 150px;
  max-width: none;
}

@media screen and (max-width: 1023px) {
  .apocalypse--augmented-reality .apocalypse__title,
  .apocalypse--internet-of-things .apocalypse__title {
    text-align: center;
    width: 100%;
    max-width: none;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .apocalypse--augmented-reality .apocalypse__title,
  .apocalypse--internet-of-things .apocalypse__title {
    text-align: left;
    font-size: 28px;
  }
}

@media screen and (max-width: 1023px) {
  .apocalypse--augmented-reality,
  .apocalypse--internet-of-things {
    height: auto;
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  .apocalypse--augmented-reality,
  .apocalypse--internet-of-things {
    padding-bottom: 150px;
  }
}

.apocalypse--internet-of-things {
  background: none;
  padding-bottom: 125px;
}

.apocalypse--internet-of-things .apocalypse__title {
  color: #fff;
  opacity: 1;
  text-align: center;
}

.apocalypse__title {
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 60px;
  max-width: 560px;
  line-height: 1.25;
  font-size: 40px;
}

@media screen and (max-width: 767px) {
  .apocalypse__title--blockchain {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1023px) {
  .apocalypse__title {
    text-align: center;
    width: 100%;
    max-width: none;
  }
}

@media screen and (max-width: 767px) {
  .apocalypse__title {
    text-align: left;
    font-size: 28px;
  }
}

.apocalypse__list {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -20px 0;
  color: #fff;
  width: 40%;
}

@media screen and (max-width: 1023px) {
  .apocalypse__list {
    padding: -20px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .apocalypse__list {
    padding: -20px 0;
  }
}

.apocalypse__icon {
  color: #1476f2;
}

.apocalypse__item {
  padding: 20px 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media screen and (max-width: 1023px) {
  .apocalypse__item {
    width: 50%;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .apocalypse__item {
    padding: 20px 0;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.apocalypse__content {
  padding-left: 10px;
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .apocalypse__content {
    padding-left: 0;
    padding-top: 30px;
  }

  .apocalypse__content--blockchain {
    padding-left: 0;
    padding-top: 0;
  }
}

.apocalypse__heading {
  text-transform: uppercase;
  line-height: 1.3;
  margin-bottom: 10px;
}

.apocalypse__heading--blockchain {
  margin-bottom: 0;
}

.apocalypse__description {
  font-size: 13px;
  line-height: 1.5;
  opacity: 0.8;
}

.icon-apocalypsis-item,
.icon-artificial-intelligence,
.icon-augmented-reality,
.icon-beacons-sensors,
.icon-flexibility,
.icon-internet-things,
.icon-rich-analytics,
.icon-scalability,
.icon-virtual-reality {
  position: relative;
}

.icon-apocalypsis-item:before,
.icon-artificial-intelligence:before,
.icon-augmented-reality:before,
.icon-beacons-sensors:before,
.icon-flexibility:before,
.icon-internet-things:before,
.icon-rich-analytics:before,
.icon-scalability:before,
.icon-virtual-reality:before {
  content: "";
  background-repeat: no-repeat;
  background-position: 50%;
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
}

/*
.icon-artificial-intelligence:before {
  background-image: url(/img/icons/ai-assistant-blue.svg)
}

.icon-augmented-reality:before {
  background-image: url(/img/icons/augmented-reality-blue.svg)
}

.icon-beacons-sensors:before {
  background-image: url(/img/icons/beacons-blue.svg)
}

.icon-virtual-reality:before {
  background-image: url(/img/icons/virtual-reality-blue.svg)
}

.icon-rich-analytics:before {
  background-image: url(/img/icons/big-data-analytics-blue.svg)
}

.icon-scalability:before {
  background-image: url(/img/icons/scalability-blue.svg)
}

.icon-flexibility:before {
  background-image: url(/img/icons/flexibility-blue.svg)
}

.icon-internet-things:before {
  background-image: url(/img/icons/iot-blue.svg)
}
*/

.planalitics {
  background-image: url(img/bg-planalitics.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

@media screen and (max-width: 767px) {
  .planalitics {
    background-image: none;
  }
}

.planalitics .planalitics__list ul {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -20px 0;
}

.planalitics .planalitics__list ul li {
  padding: 20px 45px;
  width: 33.33%;
}

@media screen and (max-width: 1023px) {
  .planalitics .planalitics__list ul li {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .planalitics .planalitics__list ul li {
    width: 100%;
    padding: 20px 0;
  }
}

.planalitics .planalitics__list ul li i {
  display: block;
  margin-bottom: 40px;
  font-size: 50px;
  color: #1476f2;
}

.planalitics .planalitics__list ul li h3 {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.planalitics .planalitics__list ul li p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

.planalitics__image {
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 115px;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  height: 350px;
  width: 100%;
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  .planalitics__image {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .planalitics__image {
    margin-top: 50px;
    height: 0;
  }
}

.snakepit {
  padding-bottom: 100px;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 767px) {
  .snakepit {
    padding-bottom: 50px;
  }
}

.snakepit .owl-next,
.snakepit .owl-prev {
  position: absolute;
  top: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: solid rgba(50, 50, 50, 0.4);
}

.snakepit .owl-next:hover,
.snakepit .owl-prev:hover {
  border-color: rgba(50, 50, 50, 0.8);
}

.snakepit .owl-prev {
  left: 29%;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

@media screen and (max-width: 767px) {
  .snakepit .owl-prev {
    left: 10px;
  }
}

.snakepit .owl-next {
  right: 29%;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media screen and (max-width: 767px) {
  .snakepit .owl-next {
    right: 10px;
  }
}

.snakepit__list {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  margin: -10px -50px;
  padding: 38px 0;
  width: auto;
}

@media screen and (max-width: 1366px) and (orientation: portrait) {
  .snakepit__list {
    margin: 4px -50px;
    padding: 0;
  }
}

@media screen and (max-width: 1023px) {
  .snakepit__list {
    margin: 0;
    padding: 0;
    background-image: none;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
  }
}

@media screen and (max-width: 1023px) and (max-width: 1024px) and (orientation: landscape) {
  .snakepit__list {
    background-size: 30%;
  }
}

@media screen and (max-width: 1023px) and (max-width: 1023px) {
  .snakepit__list {
    display: none;
  }
}

.snakepit__item {
  cursor: pointer;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1px 0;
  width: 35%;
}

.snakepit__item:nth-child(odd) {
  padding-left: 50px;
}

@media screen and (max-width: 1023px) {
  .snakepit__item:nth-child(odd) {
    padding-left: 0;
  }
}

.snakepit__item:nth-child(2n) {
  padding-right: 50px;
}

@media screen and (max-width: 1023px) {
  .snakepit__item:nth-child(2n) {
    padding-right: 0;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .snakepit__item {
    width: 34%;
  }
}

@media screen and (max-width: 1366px) and (orientation: portrait) {
  .snakepit__item {
    width: 350px;
  }
}

@media screen and (max-width: 1023px) {
  .snakepit__item {
    padding: 0;
    width: 320px;
    margin: 0 auto 6px;
  }
}

@media screen and (max-width: 767px) {
  .snakepit__item {
    padding: 10px 0;
  }
}

@media screen and (max-width: 480px) {
  .snakepit__item {
    max-width: 260px;
  }
}

.snakepit__content {
  min-height: 139px;
  background-color: #fff;
  padding: 28px 30px 34px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
}

.snakepit__content.top-border {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

@media screen and (max-width: 1023px) {
  .snakepit__content.top-border {
    border-radius: unset;
  }
}

.snakepit__content.bottom-border {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

@media screen and (max-width: 1023px) {
  .snakepit__content.bottom-border {
    border-radius: unset;
  }
}

.snakepit__heading {
  color: #323232;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.88;
  margin-bottom: 1px;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 1023px) {
  .snakepit__heading {
    height: 25px;
  }
}

.snakepit__description {
  font-size: 13.5px;
  line-height: 1.43;
  color: rgba(50, 50, 50, 0.7);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

@media screen and (max-width: 1023px) {
  .snakepit__description {
    min-height: 60px;
  }
}

@media screen and (max-width: 767px) and (max-height: 425px) {
  .snakepit__description {
    min-height: 70px;
  }
}

@media screen and (max-width: 425px) {
  .snakepit__description {
    min-height: 110px;
  }
}

@media screen and (max-width: 345px) {
  .snakepit__description {
    min-height: 130px;
  }
}

.snakepit__screen {
  margin: 35px auto 0;
}

@media screen and (min-width: 1024px) {
  .snakepit__screen {
    display: none;
  }
}

@media screen and (max-width: 767px) and (max-height: 425px) {
  .snakepit__screen {
    margin: 20px auto;
  }
}

@media screen and (min-width: 1024px) {
  .snakepit__phone {
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-out;
    transition: opacity 0.4s ease-out;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: auto;
    height: 655px;
    width: 375px;
    z-index: 2;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .snakepit__phone {
    top: 46px;
  }
}

@media screen and (max-width: 1366px) and (orientation: portrait) {
  .snakepit__phone {
    top: 30px;
  }
}

.snakepit__link--more {
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  bottom: 10px;
  position: absolute;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.45s ease;
  transition: all 0.45s ease;
  pointer-events: all;
}

.snakepit__link--more:hover {
  color: #fff;
}

.snakepit__link--more i {
  font-size: 11px;
  margin-left: -3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.snakepit__preload {
  display: none;
}

.snakepit__rectangle--left,
.snakepit__rectangle--right {
  position: relative;
}

.snakepit__rectangle--left:before,
.snakepit__rectangle--right:before {
  content: "";
  display: block;
  position: absolute;
  top: 22%;
  left: -12px;
  border-top: 12px solid transparent;
  border-right: 12px solid rgba(20, 118, 242, 0.8);
  border-bottom: 12px solid transparent;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.snakepit__rectangle--right:before {
  left: auto;
  right: -12px;
  border-top: 12px solid transparent;
  border-right: none;
  border-left: 12px solid rgba(20, 118, 242, 0.8);
  border-bottom: 12px solid transparent;
}

.snakepit__item:hover .snakepit__heading {
  color: rgba(20, 118, 242, 0.8);
}

@media screen and (min-width: 1024px) {
  .snakepit .js-active .snakepit__content {
    padding-top: 14px;
    background-color: rgba(20, 118, 242, 0.8);
  }

  .snakepit .js-active .snakepit__rectangle--left:before,
  .snakepit .js-active .snakepit__rectangle--right:before {
    opacity: 1;
  }

  .snakepit .js-active .snakepit__heading {
    color: #fff;
  }

  .snakepit .js-active .snakepit__description {
    color: hsla(0, 0%, 100%, 0.8);
  }

  .snakepit .js-active .snakepit__link--more {
    opacity: 1;
    bottom: 20px;
  }

  .snakepit .js-active .snakepit__link--more i {
    margin-left: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
}

.sitemap {
  background: #fff;
  padding: 55px 0 75px;
}

.sitemap__title {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  color: #323232;
  text-transform: uppercase;
  text-align: left;
  position: relative;
  display: inline-block;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.sitemap__title:before {
  display: block;
  content: "";
  position: absolute;
  /*background: url(/img/icons/icon-more-gray.svg);*/
  width: 6px;
  height: 11px;
  right: -10px;
  background-size: cover;
  background-position: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

.sitemap__title:hover {
  color: #006efa;
}

.sitemap__title:hover:before {
  /*background: url(/img/icons/icon-more.svg);*/
  background-size: cover;
  background-position: 50%;
}

.sitemap__list {
  list-style: none;
  width: 100%;
  padding-top: 15px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.sitemap__item,
.sitemap__list {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sitemap__item {
  width: 33%;
  position: relative;
  padding: 0 15px;
  margin: 10px 0;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .sitemap__item {
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  .sitemap__item {
    width: 100%;
  }
}

.sitemap__item:before {
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  background: #e3e3e3;
  left: 0;
  top: 7.5px;
  margin: auto 0;
}

.sitemap__item:hover {
  color: #1476f2;
}

.sitemap__item:hover:before {
  background: #1476f2;
}

.sitemap__list-container {
  padding: 40px 0 45px;
}

.key-points {
  background: #fff;
}

.key-points ~ .divider-b-75 {
  padding-top: 94px;
}

@media screen and (max-width: 768px) {
  .key-points {
    padding-bottom: 50px;
  }
}

.key-points .container--flex {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.apocalypse__title--key-points {
  margin: 60px auto 0;
  max-width: 630px;
  color: #323232;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .apocalypse__title--key-points {
    margin: 60px auto 0;
  }
}

.key-points__sticky-image {
  top: 70px;
  padding: 70px 0;
  position: -webkit-sticky;
  position: sticky;
  max-width: 250px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 235px;
  width: 100%;
}

.key-points__card {
  padding: 140px 95px 50px;
  min-height: 600px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .key-points__card {
    padding: 140px 20px 50px;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

@media screen and (max-width: 768px) {
  .key-points__card {
    min-height: auto;
    padding: 90px 0 0;
  }
}

@media screen and (max-width: 425px) {
  .key-points__card {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.key-points__image {
  width: 100%;
  max-width: 250px;
  height: 250px;
}

.key-points__image iframe {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 375px) {
  .key-points__image iframe {
    width: 50px;
    height: 50px;
    display: block;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .key-points__image {
    width: 100%;
    max-width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 600px) {
  .key-points__image {
    max-width: 140px;
    height: 140px;
  }
}

@media screen and (max-width: 425px) {
  .key-points__image {
    max-width: 80px;
    height: 70px;
    margin: 0 auto 25px;
  }
}

@media screen and (max-width: 375px) {
  .key-points__image {
    max-width: 60px;
    height: 50px;
    margin: 0 auto 25px;
  }
}

.key-points__content {
  width: 100%;
  max-width: 550px;
  padding-left: 170px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

@media screen and (max-width: 1023px) {
  .key-points__content {
    padding-left: 100px;
  }
}

@media screen and (max-width: 768px) {
  .key-points__content {
    max-width: 400px;
    padding-left: 25px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 425px) {
  .key-points__content {
    padding-left: 0;
  }
}

.key-points__cards {
  width: 100%;
}

.key-points__header {
  display: block;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
  color: #323232;
  line-height: 1;
  margin-bottom: 20px;
}

.key-points__header span {
  display: inline;
  color: #1476f2;
}

@media screen and (max-width: 425px) {
  .key-points__header {
    text-align: center;
  }
}

.key-points__description {
  font-size: 15px;
  line-height: 1.67;
  color: #555;
}

.platform-client {
  width: 100%;
  overflow: hidden;
  padding: 50px 0 0;
}

@media screen and (max-width: 900px) {
  .platform-client .container--flex {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.platform-client__content {
  width: 100%;
  max-width: 550px;
  float: left;
  padding-top: 40px;
  padding-bottom: 100px;
  padding-right: 40px;
}

@media screen and (max-width: 900px) {
  .platform-client__content {
    max-width: none;
    padding-bottom: 65px;
    padding-right: 0;
  }
}

.platform-client__device-image {
  width: 100%;
  max-width: 440px;
  float: right;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

@media screen and (max-width: 900px) {
  .platform-client__device-image {
    max-width: none;
    overflow: hidden;
  }
}

.platform-client__device-image img {
  width: 100%;
  max-width: 440px;
  display: block;
  margin: auto;
  -webkit-box-shadow: -20px 100px 70px 0 rgba(0, 0, 0, 0.1);
  box-shadow: -20px 100px 70px 0 rgba(0, 0, 0, 0.1);
}

.platform-client__description {
  font-family: Montserrat;
  font-size: 15px;
  line-height: 1.67;
  color: #555;
  margin-top: 20px;
}

.platform-client__title {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  color: #323232;
  text-transform: uppercase;
}

@media screen and (max-width: 425px) {
  .platform-client__title {
    font-size: 24px;
  }
}

.platform-client__list {
  padding: 30px 0;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.platform-client__list--technologies {
  padding: 0 0 0 20px;
}

.platform_client__list-item {
  width: 100%;
  max-width: 275px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
  position: relative;
  padding: 8px 0 8px 20px;
  font-family: Montserrat, Arial, sans-serif;
}

.platform_client__list-item--technologies {
  padding: 3px 0 0 20px;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.34;
  color: #323232;
}

@media screen and (min-width: 1024px) {
  .platform_client__list-item {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .platform_client__list-item {
    width: 50%;
    max-width: none;
  }
}

@media screen and (max-width: 500px) {
  .platform_client__list-item {
    width: 100%;
    max-width: 275px;
  }
}

.platform_client__list-item:before {
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  background: #1476f2;
  position: absolute;
  left: 0;
  top: 5px;
  bottom: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
}

.platform_client__list-item--technologies:before {
  width: 2px;
  height: 2px;
  background: #323232;
}

.platform_client__list-item--column {
  width: 100%;
  max-width: none;
}

.platform-client__button-container {
  width: 100%;
  padding: 40px 0;
  display: none;
  background: #fff;
}

@media screen and (max-width: 900px) {
  .platform-client__button-container {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.capabilities {
  padding: 50px 0;
  background-color: #fafafa;
}

.capabilities .capabilities-title {
  max-width: 770px;
  margin: 0 auto;
  margin-bottom: 100px;
  text-align: center;
  font-size: 32px;
  line-height: 1.25;
  font-weight: 600;
  text-transform: uppercase;
  color: #323232;
}

.capabilities .capabilities-description {
  max-width: 770px;
  text-align: center;
  margin: -20px auto 60px;
  font-size: 18px;
  line-height: 1.67;
  color: #555;
}

.capabilities .capabilities-block .developers {
  margin-bottom: 50px;
}

.capabilities .capabilities-block .developers h5 {
  margin-bottom: 5px;
  font-size: 17px;
  line-height: 1.53;
  font-weight: 400;
  color: #323232;
  opacity: 0.5;
}

.capabilities .capabilities-block .developers .dev-description {
  border-top: 1px solid rgba(50, 50, 50, 0.1);
  max-height: 100px;
  -webkit-transition: max-height 0.4s;
  transition: max-height 0.4s;
  overflow: hidden;
}

.capabilities .capabilities-block .developers .dev-description:last-child {
  border-bottom: 1px solid rgba(50, 50, 50, 0.1);
}

.capabilities .capabilities-block .developers .dev-description .dev-title {
  padding-left: 20px;
  height: 100px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

.capabilities
.capabilities-block
.developers
.dev-description
.dev-title
.icon-dev {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 65px;
  margin-right: 20px;
}

.capabilities
.capabilities-block
.developers
.dev-description
.dev-title
.icon-dev
i {
  font-size: 46px;
  color: #1476f2;
}

.capabilities .capabilities-block .developers .dev-description .dev-title h3 {
  width: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  text-transform: uppercase;
  color: #323232;
}

.capabilities .capabilities-block .developers .dev-description p {
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 1.68;
  font-weight: 300;
  color: #323232;
  overflow: hidden;
}

.capabilities .capabilities-block .developers .dev-description .technologies {
  margin-left: 20px;
}

.capabilities
.capabilities-block
.developers
.dev-description
.technologies
li {
  margin-bottom: 30px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.capabilities
.capabilities-block
.developers
.dev-description
.technologies
li
p {
  margin-left: 0;
  margin-bottom: 0;
}

.capabilities
.capabilities-block
.developers
.dev-description
.technologies
li
.icon-dev {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 60px;
  margin-right: 15px;
}

.capabilities
.capabilities-block
.developers
.dev-description
.technologies
li
.icon-dev
i {
  font-size: 40px;
  color: #323232;
  opacity: 0.2;
}

.capabilities .capabilities-block .developers .dev-description.active {
  max-height: 700px;
}

.capabilities .capabilities-block .developers .dev-description.active svg {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  .capabilities {
    padding: 100px 0;
  }

  .capabilities .capabilities-title {
    margin-bottom: 50px;
  }

  .capabilities .capabilities-block {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .capabilities .capabilities-block .developers {
    width: 45%;
  }
}

.case-about {
  height: 470px;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 425px) {
  .case-about {
    height: 600px;
  }
}

.case-about:before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  background: url(img/case-about-bg.png);
  background-position-x: 105%;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1200px) {
  .case-about:before {
    background-position-x: 136%;
  }
}

@media screen and (max-width: 910px) {
  .case-about:before {
    background-position-x: 170%;
    opacity: 0.7;
  }
}

@media screen and (max-width: 425px) {
  .case-about:before {
    opacity: 0.35;
  }
}

.case-about:after {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  background: url(img/cases/case-about-bg.png);
  background-position-x: 105%;
  background-repeat: no-repeat;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media screen and (max-width: 1200px) {
  .case-about:after {
    background-position-x: 136%;
  }
}

@media screen and (max-width: 910px) {
  .case-about:after {
    background-position-x: 235%;
    opacity: 0.35;
  }
}

@media screen and (max-width: 425px) {
  .case-about:after {
    display: none;
  }
}

.case-about .container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.case-about__text {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.67;
  text-align: center;
  color: #4d4d4d;
  max-width: 720px;
}

.case-testimonial {
  width: 100%;
  height: 650px;
  background-image: url(img/map-gray.png);
  background-position: center -60px;
  background-repeat: no-repeat;
}

.case-testimonial .container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.case-testimonial.case-euroaccident {
  height: 820px;
}

.case-testimonial__body {
  width: 100%;
  max-width: 930px;
  background: #fff;
  position: relative;
  padding: 125px 140px 65px;
  -webkit-box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.07);
}

@media screen and (max-width: 700px) {
  .case-testimonial__body {
    padding: 125px 35px 65px;
  }
}

@media screen and (max-width: 425px) {
  .case-testimonial__body {
    padding: 125px 20px 65px;
  }
}

.case-testimonial__body:before {
  content: "â€œ";
  display: block;
  opacity: 0.49;
  font-family: Montserrat;
  font-size: 144px;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 95px;
  left: 66px;
}

@media screen and (max-width: 700px) {
  .case-testimonial__body:before {
    top: 75px;
    left: 20px;
  }
}

@media screen and (max-width: 425px) {
  .case-testimonial__body:before {
    font-size: 100px;
  }
}

.case-testimonial__body:after {
  content: "â€œ";
  display: block;
  opacity: 0.49;
  font-family: Montserrat;
  font-size: 144px;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.09);
  position: absolute;
  bottom: 35px;
  right: 66px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media screen and (max-width: 700px) {
  .case-testimonial__body:after {
    bottom: 14px;
    right: 5px;
  }
}

@media screen and (max-width: 425px) {
  .case-testimonial__body:after {
    font-size: 100px;
  }
}

.case-testimonial__customer {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: auto;
}

.case-testimonial__img-container {
  width: 80px;
  height: 80px;
  background: #1476f2;
  margin: auto;
  margin-bottom: 15px;
}

.case-testimonial__img-container img {
  width: 80px;
  height: 80px;
}

.case-testimonial__name {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #323232;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.case-testimonial__position {
  font-size: 13px;
  line-height: 1.15;
  text-align: center;
  color: #a7a7a7;
}

.case-testimonial__text p {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.46;
  text-align: center;
  color: #1476f2;
  word-spacing: 3px;
}

@media screen and (max-width: 700px) {
  .case-testimonial__text p {
    font-size: 20px;
    word-spacing: 0;
  }
}

@media screen and (max-width: 425px) {
  .case-testimonial__text p {
    font-size: 15px;
  }
}

.ranking {
  width: 100%;
  height: 600px;
  background: #fff;
}

@media screen and (max-width: 599px) {
  .ranking {
    height: 500px;
  }
}

.ranking .owl-dot span {
  width: 50px;
  height: 2px;
  display: inline-block;
  background: hsla(0, 0%, 61%, 0.3);
  margin: 0 10px;
}

.ranking .owl-dot.active span {
  border-left: 50px solid #006efa;
}

.ranking .owl-dots {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 130px;
}

.ranking .owl-dots,
.ranking__item {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ranking__item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ranking__item img {
  max-height: 170px;
  max-width: 265px;
}

.ranking__sign {
  margin-top: 15px;
  font-size: 15px;
}

.ranking__sign,
.ranking__title {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #323232;
}

.ranking__title {
  font-size: 30px;
  padding: 95px 0 75px;
}

@media screen and (max-width: 320px) {
  .ranking__title {
    width: 100%;
    max-width: 240px;
    word-break: normal;
    margin: auto;
  }
}

.ranking-form {
  width: 100%;
  max-width: 423px;
  background: transparent;
}

.ranking-form .owl-dot span {
  width: 50px;
  height: 2px;
  display: inline-block;
  background: hsla(0, 0%, 100%, 0.4);
  margin: 0 10px;
}

.ranking-form .owl-dot.active span {
  border-left: 50px solid #fff;
}

.ranking-form .owl-dots {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 60px;
}

@media screen and (max-width: 1400px) {
  .ranking-form .owl-dots {
    padding-top: 35px;
  }
}

@media screen and (max-width: 1023px) {
  .ranking-form .owl-dots {
    padding-top: 26px;
  }
}

.ranking-form__item {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ranking-form__item img {
  max-height: 170px;
  max-width: 265px;
}

.ranking-form__sign {
  margin-top: 15px;
  font-size: 15px;
  line-height: 1;
  color: #323232;
}

.ranking-form__sign,
.ranking-form__title {
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}

.ranking-form__title {
  margin: 0 auto 60px;
  font-size: 18px;
  line-height: 1.22;
  color: #fff;
}

@media screen and (max-width: 1400px) {
  .ranking-form__title {
    margin: 0 auto 35px;
  }
}

@media screen and (max-width: 1023px) {
  .ranking-form__title {
    margin: 0 auto 30px;
  }
}

@media screen and (max-width: 480px) {
  .ranking-form__title {
    width: 100%;
    max-width: 240px;
    word-break: normal;
  }
}

.case-fixed {
  background: #fff;
  overflow: hidden;
}

.case-fixed__row {
  height: 100vh;
}

@media screen and (max-width: 1024px) {
  .case-fixed__row {
    height: auto;
  }
}

.case-fixed__row:after,
.case-fixed__row:before {
  content: "";
  display: table;
}

.scrollmagic-pin-spacer {
  float: left !important;
}

.case-fixed__fixed-img {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .case-fixed__fixed-img {
    width: 100%;
  }
}

.case-fixed__item {
  max-height: 100vh;
}

@media screen and (max-width: 1024px) {
  .case-fixed__item {
    max-height: none;
    padding: 0 0 90px;
  }
}

.case-fixed__item--phone:first-child {
  padding: 80px 0 90px;
}

@media screen and (max-width: 1024px) {
  .case-fixed__item--phone .case-fixed__text {
    margin-top: 0;
    width: 100%;
    height: auto;
    float: none;
    padding: 0 30px;
    margin-top: 30px;
    color: #555;
  }

  .case-fixed__item--phone .case-fixed__text h2 {
    width: 100%;
    margin: auto;
  }
}

.case-fixed__item--phone .img-container {
  margin-top: 30px;
}

.case-fixed__text {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  padding: 0 140px 0 100px;
  width: 50%;
  float: right;
}

.case-fixed__text h2 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #323232;
}

@media screen and (max-width: 1024px) {
  .case-fixed__text {
    width: 100%;
    height: auto;
    float: none;
    padding: 0 30px;
    margin-top: 30px;
    color: #555;
    max-width: 600px;
    margin: auto;
  }

  .case-fixed__text h2 {
    width: 100%;
    margin: 0 auto 20px;
    max-width: 600px;
  }
}

@media screen and (max-width: 425px) {
  .container .case-fixed__text {
    padding: 0;
  }
}

.img {
  position: relative;
}

.img img {
  min-height: 100vh;
  object-fit: cover;
  position: absolute;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 1.2s;
  transition: all 1.2s;
  display: block;
  top: 0;
  z-index: 2;
}

.img img.phone-img {
  max-width: 520px;
  object-fit: contain;
  margin-left: 100px;
}

@media screen and (max-width: 1024px) {
  .img img.phone-img {
    margin-left: 0;
    object-fit: contain;
  }
}

.img .case-fixed__hidden-image {
  opacity: 0;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 1.2s;
  transition: all 1.2s;
  z-index: 2;
}

@media screen and (max-width: 1024px) {
  .img-container {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 425px) {
  .img-container {
    margin-bottom: 0;
  }
}

.img-container img {
  display: none;
}

.img-container img.phone-img:first-child {
  object-fit: contain;
  height: 320px;
}

.img-container img:first-child {
  position: relative;
  width: 100%;
  height: 500px;
  object-fit: cover;
  display: block;
  opacity: 1;
}

@media screen and (min-width: 1025px) {
  .img-container img:first-child {
    display: none;
  }
}

.case-mask {
  width: 100%;
  padding: 140px 0 130px;
  background-position: 50%;
  background-size: cover;
  color: #fff;
  font-weight: 300;
  line-height: 1.67;
}

.case-mask .container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.case-mask__content p {
  display: block;
  text-align: center;
  width: 100%;
  max-width: 720px;
  font-size: 24px;
}

@media screen and (max-width: 425px) {
  .case-mask__content p {
    font-size: 20px;
  }
}

.testimonial-video {
  padding-top: 100px;
  background-image: url(img/map-gray.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% -135px;
}

@media screen and (max-width: 425px) {
  .testimonial-video {
    background-position: 50%;
    background-size: cover;
  }
}

.testimonial-video__title {
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 65px;
}

.testimonial-video__video {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 120px;
}

.testimonial-video__video iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.testimonial-video__video-wrap {
  width: 100%;
  max-width: 885px;
  margin: 0 auto;
}

.testimonial-video__overlay {
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: 50%;
  background-size: cover;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial-video__overlay,
.testimonial-video__play-button {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-video__play-button {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #1476f2;
  opacity: 0.9;
  cursor: pointer;
}

.testimonial-video__play-button i {
  color: #fff;
  font-size: 35px;
  display: block;
  margin-left: 35px;
}

.modal-video {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.modal-video,
.modal-video .container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.modal-video__iframe-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.modal-video__iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.position-list {
  width: 100%;
  max-width: 740px;
  margin: auto;
}

.position {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 10px;
}

.position:last-child {
  padding-bottom: 0;
}

.position a {
  position: relative;
  margin: auto;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 50px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 17px 20px;
  background: #fff;
  color: #323232;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .position a {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: none;
    padding: 15px 20px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-size: 15px;
    line-height: 1.33;
    color: #323232;
  }
}

.position a:hover {
  color: #1476f2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}

.position a:hover .position__location {
  margin-right: 25px;
}

.position a:hover .position__location:after {
  border-color: #1476f2;
}

.position__title {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .position__title {
    max-width: none;
    width: auto;
  }
}

.position__location {
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  min-width: 100px;
  margin-right: 15px;
  -webkit-transition: margin 0.5s;
  transition: margin 0.5s;
}

@media screen and (max-width: 768px) {
  .position__location {
    font-size: 13px;
    line-height: 1.54;
    min-width: 0;
  }
}

.position__location:after {
  content: "";
  position: absolute;
  top: 19px;
  right: 20px;
  width: 10px;
  height: 11px;
  border: solid #323232;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .position__location:after {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .country-select {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #1476f2;
    padding: 10px 0;
  }
}

.country-list__select-container {
  position: relative;
}

.country-list__select-container i {
  position: absolute;
  right: 15px;
  top: 18px;
  display: block;
  color: #fff;
  z-index: 1;
  pointer-events: none;
}

.country-list {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 60px;
  width: auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .country-list {
    display: none;
  }
}

.country-list--mobile {
  display: none;
  color: #fff;
  width: 280px;
  padding: 15px;
  border: 2px solid hsla(0, 0%, 100%, 0.5);
  background: transparent;
  border-radius: 0;
  margin-bottom: 30px;
  text-transform: uppercase;
  z-index: 2;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .country-list--mobile {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.country-list__item {
  width: 150px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #323232;
  text-transform: uppercase;
  display: block;
  height: 30px;
  cursor: pointer;
  position: relative;
  border-bottom: 2px solid rgba(50, 50, 50, 0.1);
}

.country-list__item:before {
  display: block;
  position: absolute;
  content: "";
  height: 2px;
  width: 0;
  bottom: -2px;
  left: 0;
  opacity: 1;
  background: #1476f2;
}

.country-list__item:hover {
  color: #1476f2;
}

.country-list__item:hover:after {
  background-color: #1476f2;
}

.country-list__item.active {
  color: #1476f2;
}

.country-list__item.active:before {
  display: block;
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  bottom: -2px;
  left: 0;
  right: 0;
  margin: auto;
  background: #1476f2;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.country-list__item.active:after {
  background-color: #1476f2;
}

.country-list__item--belarus:after {
  -webkit-mask-image: url(img/icons/belarus-icon.svg);
  mask-image: url(img/icons/belarus-icon.svg);
  width: 91px;
  height: 74px;
}

.country-list__item--belarus:after,
.country-list__item--ukraine:after {
  content: "";
  position: absolute;
  bottom: 35px;
  left: 30px;
  background-color: #323232;
}

.country-list__item--ukraine:after {
  -webkit-mask-image: url(img/icons/ukraine-icon.svg);
  mask-image: url(img/icons/ukraine-icon.svg);
  width: 90px;
  height: 64px;
}

.country-list__item--norway:after {
  -webkit-mask-image: url(img/icons/norway-icon.svg);
  mask-image: url(img/icons/norway-icon.svg);
  left: 32px;
  width: 91px;
  height: 58px;
}

.country-list__item--norway:after,
.country-list__item--usa:after {
  content: "";
  position: absolute;
  bottom: 35px;
  background-color: #323232;
}

.country-list__item--usa:after {
  -webkit-mask-image: url(img/icons/usa-icon.svg);
  mask-image: url(img/icons/usa-icon.svg);
  left: 37px;
  width: 80px;
  height: 91px;
}

.country-list__item--uk:after {
  content: "";
  -webkit-mask-image: url(img/icons/uk-icon.svg);
  mask-image: url(img/icons/uk-icon.svg);
  position: absolute;
  bottom: 34px;
  left: 30px;
  width: 82px;
  height: 92px;
  background-color: #323232;
}

.workable-block {
  width: 100%;
  max-width: 550px;
  background: #fff;
  -webkit-box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.07);
  padding: 45px 53px 50px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media screen and (max-width: 1190px) {
  .workable-block {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 425px) {
  .workable-block {
    padding: 45px 10px 50px;
  }
}

.workable-block__title {
  text-transform: uppercase;
  margin-bottom: 18px;
  text-align: center;
  width: 100%;
  max-width: 380px;
  margin: auto;
}

@media screen and (max-width: 425px) {
  .workable-block__title {
    font-size: 25px;
  }
}

.workable-block__lead {
  font-size: 15px;
  line-height: 1.67;
  text-align: center;
  color: #555;
  min-height: 100px;
  margin-bottom: 20px;
  width: 100%;
  margin-top: 20px;
}

.workable-block__button {
  min-width: 220px;
}

.workable-block__container {
  padding: 80px 0;
  background-image: url(img/map-gray.png);
  background-size: 55%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media screen and (max-width: 1024px) {
  .workable-block__container {
    background-size: contain;
  }
}

@media screen and (max-width: 600px) {
  .workable-block__container {
    background-size: cover;
  }
}

.workable-block__container .container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.workable-block__container .container:after {
  content: none;
}

@media screen and (max-width: 1190px) {
  .workable-block__container .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.documents-banner__container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0 20px;
}

@media screen and (max-width: 1024px) {
  .documents-banner__container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
  }
}

.documents-banner__picture {
  width: 60%;
  min-height: 340px;
  overflow: hidden;
}

.documents-banner__picture img {
  width: 100%;
  min-height: 360px;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .documents-banner__picture {
    width: 100%;
  }
}

.documents-banner__content {
  width: 40%;
  background: #1476f2;
  padding: 60px 0 0 80px;
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .documents-banner__content {
    padding: 19px 30px;
  }
}

@media screen and (max-width: 1024px) {
  .documents-banner__content {
    width: 100%;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 30px 10px;
  }
}

.documents-banner__content-text {
  color: #fff;
  width: 100%;
  max-width: 380px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.services {
  padding: 90px 0;
  background-color: #fafafa;
}

.services__title {
  margin: 0 auto;
  max-width: 490px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: #323232;
}

.services__description {
  margin: 30px auto;
  max-width: 790px;
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: #555;
}

.services .nowrap {
  white-space: nowrap;
}

.services__items {
  border-radius: 7px;
  overflow: hidden;
}

.services__items .items {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
}

.services__items .items .item {
  width: 100%;
  margin-bottom: 2px;
  position: relative;
  min-height: 210px;
  background-color: #fff;
  -webkit-transition: none;
  transition: none;
  cursor: pointer;
  margin-right: 0;
}

.services__items .items .item img {
  margin: 0 auto 30px;
}

.services__items .items .item h3 {
  margin: 0 auto;
  max-width: 180px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: #323232;
}

.services__items .items .item .item__description {
  display: block;
  padding-top: 70px;
}

@media screen and (max-width: 767px) {
  .services__items .items .item .item__description {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding-top: 0;
  }
}

.services__items .items .item .item__link {
  display: block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: -100%;
  width: 100%;
  height: 100%;
}

.services__items .items .item .item__link a {
  display: block;
  padding: 30px 40px;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .services__items .items .item .item__link a {
    padding: 15px;
  }
}

.services__items .items .item .item__link h3 {
  margin: 0;
  text-align: left;
  color: #fff;
}

.services__items .items .item .item__link p {
  margin: 10px 0 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: #fff;
}

.services__items .items .item .item__link .item__more {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
}

.services__items .items .item .item__link .item__more i {
  margin-left: 0;
  -webkit-transition: margin-left 0.3s ease 0.5s;
  transition: margin-left 0.3s ease 0.5s;
}

.services__items .items .item:hover {
  background-color: rgba(20, 118, 242, 0.8);
  -webkit-transition: background-color ease-ina-out 0.3s;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.services__items .items .item:hover .item__description {
  opacity: 0;
  -webkit-animation: shrink 0.35s ease-in;
  animation: shrink 0.35s ease-in;
}

@media screen and (max-width: 767px) {
  .services__items .items .item:hover .item__description {
    -webkit-animation: none;
    animation: none;
  }
}

.services__items .items .item:hover .item__link {
  display: block;
  visibility: visible;
  bottom: 0;
  opacity: 1;
  -webkit-animation: grow 0.5s ease;
  animation: grow 0.5s ease;
}

.services__items .items .item:hover .item__link i {
  margin-left: 5px;
}

@media (min-width: 570px) {
  .services__items .items {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row;
    flex-flow: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .services__items .items .item {
    min-height: 300px;
    margin-right: 1.99999px;
  }

  .services__items .items .item:last-child {
    margin-right: 0;
  }

  .services__items .items--col-3 .item {
    width: -webkit-calc((100% - 4px) / 3);
    width: calc((100% - 4px) / 3);
  }

  .services__items .items--col-sm-2 {
    margin-bottom: 2px;
  }

  .services__items .items--col-sm-2 .item {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: -webkit-calc((100% - 2px) / 2);
    width: calc((100% - 2px) / 2);
    margin-right: 0;
  }

  .services__items .items--col-sm-2 .item:nth-child(odd) {
    margin-right: 1.99999px;
  }
}

@media (min-width: 740px) {
  .services__items .items {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row;
    flex-flow: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .services__items .items .item {
    min-height: 300px;
    margin-right: 1.9999px;
  }

  .services__items .items .item:last-child {
    margin-right: 0;
  }

  .services__items .items--col-3 .item {
    width: -webkit-calc((100% - 4px) / 3);
    width: calc((100% - 4px) / 3);
  }

  .services__items .items--col-sm-2 {
    margin-bottom: 2px;
  }

  .services__items .items--col-sm-2 .item {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: -webkit-calc((100% - 2px) / 2);
    width: calc((100% - 2px) / 2);
    margin-right: 0;
  }

  .services__items .items--col-sm-2 .item:nth-child(odd) {
    margin-right: 2px;
  }

  .services__items .items--col-4 .item {
    width: -webkit-calc((100% - 6px) / 4);
    width: calc((100% - 6px) / 4);
    margin-right: 2px;
  }
}

.service-how__title-block {
  padding: 120px 0 150px;
}

@media screen and (max-width: 768px) {
  .service-how__title-block {
    padding: 90px 0 80px;
  }
}

.service-how__title {
  text-transform: uppercase;
  text-align: center;
  color: #323232;
  font-weight: 600;
  margin-bottom: 20px;
}

.service-how__title-lead {
  color: #555;
  text-align: center;
}

.service-how__stages {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-bottom: 120px;
}

.service-how__stages :last-child .service-how__stage-icon:after,
.service-how__stages :last-child:after {
  display: none;
}

@media screen and (max-width: 768px) {
  .service-how__stages {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .service-how__stages :nth-child(2) .service-how__stage-icon:after {
    display: none;
  }
}

.service-how__stages-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}

@media screen and (max-width: 768px) {
  .service-how__stages-item {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    margin-bottom: 60px;
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  .service-how__stages-item {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    margin-bottom: 65px;
    width: 100%;
  }

  .service-how__stages-item:after {
    display: block;
    content: "";
    height: 40px;
    width: 2px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -50px;
    position: absolute;
    background: #d8d8d8;
  }
}

.service-how__stage-icon {
  position: relative;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 60px;
  padding-bottom: 55px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .service-how__stage-icon {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 425px) {
  .service-how__stage-icon {
    padding-bottom: 25px;
  }
}

.service-how__stage-icon i {
  font-size: 60px;
  color: #1476f2;
}

.service-how__stage-icon .service-how__count {
  position: absolute;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #323232;
  left: -15px;
}

.service-how__stage-icon:after {
  display: block;
  content: "";
  position: absolute;
  width: 140px;
  height: 2px;
  background: #d8d8d8;
  left: 100px;
}

@media screen and (max-width: 1024px) {
  .service-how__stage-icon:after {
    width: 85px;
  }
}

@media screen and (max-width: 768px) {
  .service-how__stage-icon:after {
    width: 140px;
    left: 140px;
  }
}

@media screen and (max-width: 600px) {
  .service-how__stage-icon:after {
    width: 140px;
    left: 90px;
  }
}

@media screen and (max-width: 550px) {
  .service-how__stage-icon:after {
    width: 110px;
    left: 90px;
  }
}

@media screen and (max-width: 425px) {
  .service-how__stage-icon:after {
    display: none;
  }
}

.service-how__item-title {
  text-align: center;
  color: #323232;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.33;
  margin-bottom: 20px;
}

.service-how__item-text {
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.54;
  width: 100%;
  max-width: 230px;
  margin: auto;
  color: #323232;
}

@media screen and (max-width: 960px) {
  .service-how__item-text {
    max-width: 200px;
  }
}

@media screen and (max-width: 768px) {
  .service-how__item-text {
    max-width: 230px;
  }
}

.service-why {
  padding-top: 80px;
}

.service-why .service-why__title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 30px;
  z-index: 2;
  position: relative;
}

.service-why .service-why__title--team-extension {
  max-width: 540px;
  margin: 0 auto 30px;
  color: #323232;
}

.service-why .service-why__description {
  padding: 0 15px;
  margin: 0 auto 45px;
  max-width: 800px;
  text-align: center;
  font-size: 15px;
  line-height: 1.67;
  color: #555;
}

.service-why .service-why__points {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.service-why .service-why__title-bg {
  position: absolute;
  z-index: 1;
  font-size: 120px;
  opacity: 0.03;
  color: #323232;
  width: 100%;
  max-width: 725px;
  left: 0;
  right: 0;
  margin: auto;
  top: -60px;
  line-height: 0.83;
  overflow: hidden;
}

.service-why .service-why__point-block {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 630px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #fff;
  overflow: hidden;
}

@media (min-width: 1025px) {
  .service-why .service-why__point-block {
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
  }
}

.service-why .service-why__point-block:hover .service-why__mask {
  background: rgba(20, 118, 242, 0.8);
}

@media screen and (max-width: 1024px) {
  .service-why .service-why__point-block:hover .service-why__mask {
    background: rgba(50, 50, 50, 0.5);
  }
}

.service-why .service-why__point-block:hover .service-why__point-lead-content {
  width: 100%;
  max-height: 150px;
}

@media screen and (max-width: 1024px) {
  .service-why
  .service-why__point-block:hover
  .service-why__point-lead-content {
    max-height: 400px;
  }
}

@media screen and (max-width: 425px) {
  .service-why
  .service-why__point-block:hover
  .service-why__point-lead-content {
    max-height: none;
  }
}

.service-why .service-why__point-block:hover .service-why__point-lead {
  margin-top: 0;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

@media (min-width: 1025px) {
  .service-why .service-why__point-block:hover .service-why__point-lead {
    -webkit-transition: all 0.6s cubic-bezier(0, 0.62, 0.5, 0.99);
    transition: all 0.6s cubic-bezier(0, 0.62, 0.5, 0.99);
  }
}

@media screen and (max-width: 1024px) {
  .service-why .service-why__point-block {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-top: 140px;
  }
}

@media screen and (max-width: 768px) {
  .service-why .service-why__point-block {
    width: 50%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 0;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    height: 400px;
  }
}

@media screen and (max-width: 425px) {
  .service-why .service-why__point-block {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    height: 340px;
  }
}

.service-why .service-why__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 50, 50, 0.5);
  z-index: 1;
}

@media (min-width: 1025px) {
  .service-why .service-why__mask {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}

.service-why .service-why__point-lead {
  z-index: 5;
  word-wrap: break-word;
  padding: 0 30px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  line-height: 1.45;
}

@media (min-width: 650px) {
  .service-why .service-why__point-lead {
    line-height: 1.5;
  }
}

@media (min-width: 1025px) {
  .service-why .service-why__point-lead {
    -webkit-transition: all 0.6s cubic-bezier(0, 0.62, 0.5, 0.99);
    transition: all 0.6s cubic-bezier(0, 0.62, 0.5, 0.99);
  }
}

@media screen and (max-width: 1024px) {
  .service-why .service-why__point-lead {
    padding: 0 10px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.service-why .service-why__point-lead-content {
  max-height: 0;
  z-index: 5;
}

@media (min-width: 1025px) {
  .service-why .service-why__point-lead-content {
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
  }
}

@media screen and (max-width: 1025px) {
  .service-why .service-why__point-lead-content {
    max-height: none;
  }
}

.service-why .service-why__point-title {
  width: 95%;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.2;
  font-size: 20px;
}

@media (min-width: 360px) {
  .service-why .service-why__point-title {
    font-size: 24px;
  }
}

@media (min-width: 1025px) {
  .service-why .service-why__point-title {
    -webkit-transition: all 0.6s cubic-bezier(0, 0.62, 0.5, 0.99);
    transition: all 0.6s cubic-bezier(0, 0.62, 0.5, 0.99);
  }
}

.service-cta {
  height: 200px;
  width: 100vw;
  background: -webkit-linear-gradient(
          201deg,
          #343434,
          #5b5b5b,
          #2a2a2a,
          #525252
  );
  background: linear-gradient(249deg, #343434, #5b5b5b, #2a2a2a, #525252);
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-size: 400% 400%;
  -webkit-animation: Gradient 17s ease infinite;
  animation: Gradient 17s ease infinite;
}

@media screen and (max-width: 720px) {
  .service-cta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 280px;
  }
}

.service-cta--team-extension {
  background: url(img/services/team-extension/bg-service-cta.jpg) 50%/cover no-repeat;
  -webkit-animation: none;
  animation: none;
}

@media screen and (max-width: 748px) {
  .service-cta--team-extension {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 300px;
  }
}

.service-cta--technology {
  background: url(img/technologies/bg-technology-cta.jpg) 50%/cover no-repeat;
  -webkit-animation: none;
  animation: none;
}

@media screen and (max-width: 748px) {
  .service-cta--technology {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 300px;
  }
}

.service-cta--it-consulting {
  padding: 30px 0;
  height: auto;
  background: url(img/services/it-consulting/cta_mini.jpg) 50%/cover no-repeat;
  -webkit-animation: none;
  animation: none;
}

.service-cta--construction {
  background: url(./img/platforms/construction/construction-cta.jpg) 50%/cover no-repeat
}

.service-cta--about,
.service-cta--construction {
  padding: 56px 0;
  height: auto;
  -webkit-animation: none;
  animation: none;
}

.service-cta--about {
  background: url(./img/about-page/our-work.jpg) 50% / cover no-repeat * /;
}

.service-cta__text-area--construction,
.service-cta__text-area--it-consulting {
  text-align: center;
  width: 90% !important;
  margin: 0 !important;
  padding: 20px !important;
  max-width: 460px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
}

@media (min-width: 768px) {
  .service-cta__text-area--construction,
  .service-cta__text-area--it-consulting {
    padding: 40px !important;
  }
}

.service-cta__text-area--construction h3,
.service-cta__text-area--it-consulting h3 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #323232;
}

.service-cta__text-area--construction p,
.service-cta__text-area--it-consulting p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.56;
  font-weight: 400;
  text-transform: none;
  color: #323232;
}

.service-cta__text-area--construction .btn,
.service-cta__text-area--it-consulting .btn {
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .service-cta__text-area--construction .btn,
  .service-cta__text-area--it-consulting .btn {
    font-size: 13px;
  }
}

.service-cta__text-area--careers {
  text-align: center;
  width: 90% !important;
  margin: 0 !important;
  padding: 35px 80px 45px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  max-width: 855px;
}

@media (max-width: 767px) {
  .service-cta__text-area--careers {
    padding: 20px !important;
  }
}

.service-cta__text-area {
  font-size: 24px;
  text-transform: uppercase;
  margin: 0 50px 0 0;
  line-height: 1.25;
}

.service-cta__text-area--team-extension {
  max-width: 640px;
  margin: 0 119px 0 0;
}

@media screen and (max-width: 748px) and (orientation: landscape) {
  .service-cta__text-area--team-extension {
    margin: 0 auto;
  }
}

@media screen and (max-width: 720px) {
  .service-cta__text-area {
    margin: 0 auto 20px;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 475px) {
  .service-cta__text-area {
    padding: 0 20px;
  }
}

.service-cta__gray,
.service-cta__white {
  font-size: 24px;
  color: #fff;
  line-height: 1.25;
}

.service-cta__gray {
  opacity: 0.5;
}

.service-cta__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  color: #323232;
}

.service-cta__text {
  color: rgba(50, 50, 50, 0.84);
  font-size: 16px;
  line-height: 1.56;
  padding: 10px 0 20px;
  text-transform: none;
}

.service-cta__link {
  font-size: 16px;
  text-decoration: none;
  color: #1476f2;
  text-transform: none;
}

@media screen and (max-width: 475px) {
  .service-cta__link {
    font-size: 14px;
  }
}

.service-cta__link:hover {
  color: #005ed7;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

.service-gives {
  padding: 90px 0 80px;
  overflow: hidden;
}

.service-gives .service-gives__title {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: #323232;
  z-index: 2;
}

.service-gives .service-gives__title .title__title-bg {
  max-width: 660px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: -90px;
  left: 0;
  font-size: 90px;
  line-height: 90px;
  opacity: 0.03;
  color: #323232;
  z-index: 1;
}

@media (min-width: 768px) {
  .service-gives {
    padding: 120px 0 150px;
  }

  .service-gives .service-gives__title {
    margin-bottom: 90px;
  }

  .service-gives .service-gives__title .title__title-bg {
    top: -160px;
    font-size: 150px;
    line-height: 150px;
  }
}

.service-gives .service-gives__items {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.service-gives .service-gives__items .items__item {
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
}

@media (min-width: 425px) {
  .service-gives .service-gives__items .items__item {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .service-gives .service-gives__items .items__item {
    width: 25%;
    margin-bottom: 0;
  }
}

.service-gives .service-gives__items .items__item .item__icon {
  margin-bottom: 30px;
}

.service-gives .service-gives__items .items__item .item__icon i {
  font-size: 60px;
  color: #323232;
}

.service-gives .service-gives__items .items__item .item__title {
  margin: 0 auto;
  max-width: 180px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: #323232;
}

.service-dedicated {
  padding: 50px 0 70px;
  background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#525252),
          to(#343434)
  );
  background: -webkit-linear-gradient(top, #525252, #343434);
  background: linear-gradient(180deg, #525252, #343434);
}

@media (min-width: 768px) {
  .service-dedicated {
    padding: 120px 0 90px;
  }
}

.service-dedicated .dedicated__title {
  margin-bottom: 35px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

@media (min-width: 768px) {
  .service-dedicated .dedicated__title {
    margin-bottom: 20px;
    font-size: 40px;
  }
}

.service-dedicated .dedicated__description {
  margin: 0 auto 45px;
  max-width: 750px;
  font-size: 15px;
  line-height: 1.67;
  text-align: center;
  color: #fff;
  opacity: 0.65;
}

@media (min-width: 768px) {
  .service-dedicated .dedicated__description {
    margin-bottom: 80px;
  }
}

.service-dedicated .dedicated__teamwork .teamwork__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.service-dedicated .dedicated__teamwork .teamwork__title .double-arrow i {
  display: block;
  margin: 15px auto 30px;
  font-size: 42px;
  opacity: 0.2;
}

@media (min-width: 768px) {
  .service-dedicated .dedicated__teamwork .teamwork__title {
    display: none;
  }
}

.service-dedicated .dedicated__teamwork > .teamwork__title {
  display: none;
}

@media (min-width: 768px) {
  .service-dedicated .dedicated__teamwork > .teamwork__title {
    width: 100%;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .service-dedicated .dedicated__teamwork > .teamwork__title h3 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.17;
  }

  .service-dedicated .dedicated__teamwork > .teamwork__title h3:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 150px;
    flex: 0 150px;
  }

  .service-dedicated .dedicated__teamwork > .teamwork__title h3:last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 290px;
    flex: 0 290px;
  }
}

@media (min-width: 920px) {
  .service-dedicated .dedicated__teamwork > .teamwork__title h3:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 170px;
    flex: 0 170px;
  }

  .service-dedicated .dedicated__teamwork > .teamwork__title h3:last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 360px;
    flex: 0 360px;
  }
}

.service-dedicated .teamwork__section {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .service-dedicated .teamwork__section {
    margin-bottom: 60px;
  }
}

.service-dedicated .teamwork__section .section__row {
  margin-bottom: 90px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (min-width: 768px) {
  .service-dedicated .teamwork__section .section__row {
    margin-bottom: 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

.service-dedicated .teamwork__section .section__row .step {
  position: relative;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.service-dedicated .teamwork__section .section__row .step i {
  display: none;
}

@media (min-width: 768px) {
  .service-dedicated .teamwork__section .section__row .step {
    margin: 0 30px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .service-dedicated .teamwork__section .section__row .step i {
    position: absolute;
    display: block;
    top: 7px;
    font-size: 16px;
    color: #fff;
    opacity: 0.7;
  }

  .service-dedicated .teamwork__section .section__row .step i.isoi-arrow_left {
    left: -2px;
  }

  .service-dedicated .teamwork__section .section__row .step i.isoi-arrow_right {
    right: -2px;
  }
}

@media (min-width: 920px) {
  .service-dedicated .teamwork__section .section__row .step {
    margin: 0 50px;
  }
}

@media (min-width: 1200px) {
  .service-dedicated .teamwork__section .section__row .step {
    margin: 0 70px;
  }
}

.service-dedicated .teamwork__section .section__row .step__number {
  display: block;
  margin: 0 auto 15px;
  padding: 3px;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  color: #fff;
}

@media (min-width: 768px) {
  .service-dedicated .teamwork__section .section__row .step__number:before {
    position: absolute;
    content: "";
    display: block;
    top: 14px;
    left: 0;
    right: -webkit-calc(50% + 14px);
    right: calc(50% + 14px);
    background-color: #fff;
    height: 2px;
    opacity: 0.7;
  }

  .service-dedicated .teamwork__section .section__row .step__number:after {
    position: absolute;
    content: "";
    display: block;
    top: 14px;
    left: -webkit-calc(50% + 14px);
    left: calc(50% + 14px);
    right: 0;
    background-color: #fff;
    height: 2px;
    opacity: 0.7;
  }
}

.service-dedicated .teamwork__section .section__row .step__title {
  max-width: 235px;
  margin: 0 auto 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.service-dedicated .teamwork__section .section__row .row__col {
  margin: 0 auto;
  width: 100%;
}

@media (min-width: 460px) {
  .service-dedicated .teamwork__section .section__row .row__col {
    width: 420px;
  }
}

@media (min-width: 768px) {
  .service-dedicated .teamwork__section .section__row .row__col {
    margin: 0;
  }

  .service-dedicated .teamwork__section .section__row .row__col:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 150px;
    flex: 0 150px;
  }

  .service-dedicated .teamwork__section .section__row .row__col:last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 290px;
    flex: 0 290px;
  }
}

@media (min-width: 920px) {
  .service-dedicated .teamwork__section .section__row .row__col:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 170px;
    flex: 0 170px;
  }

  .service-dedicated .teamwork__section .section__row .row__col:last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 360px;
    flex: 0 360px;
  }
}

.service-dedicated .teamwork__section .section__row .row__col .items__item {
  margin-bottom: 20px;
  height: 170px;
  padding: 30px 15px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
}

@media (min-width: 768px) {
  .service-dedicated .teamwork__section .section__row .row__col .items__item {
    margin-bottom: 0;
    padding: 40px 15px 30px;
    height: 100%;
  }
}

.service-dedicated
.teamwork__section
.section__row
.row__col
.items__item
.item__icon {
  margin-bottom: 15px;
  font-size: 40px;
  color: #323232;
}

.service-dedicated
.teamwork__section
.section__row
.row__col
.items__item
.item__title {
  margin: 0 auto;
  max-width: 200px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  color: #323232;
}

.service-dedicated
.teamwork__section
.section__row
.row__col:last-child
.items__item
.item__icon {
  color: #1476f2;
}

.service-dedicated
.teamwork__section
.section__row:last-child
.row__col:last-child
.items__item
.item__icon {
  margin: 0 auto 40px;
  width: 70%;
  position: relative;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.service-dedicated
.teamwork__section
.section__row:last-child
.row__col:last-child
.items__item
.item__icon:after {
  content: "";
  position: absolute;
  display: block;
  height: 1px;
  bottom: -25px;
  left: 20px;
  right: 20px;
  background-color: #323232;
}

.service-dedicated
.teamwork__section
.section__row:last-child
.row__col:last-child
.items__item
.item__icon
i {
  position: relative;
}

.service-dedicated
.teamwork__section
.section__row:last-child
.row__col:last-child
.items__item
.item__icon
i:after {
  content: "";
  position: absolute;
  display: block;
  height: 10px;
  width: 1px;
  bottom: -25px;
  left: 50%;
  background-color: #323232;
}

.service-dedicated .service-cta {
  height: auto;
  padding: 45px 10px;
  border-radius: 10px;
}

.service-dev-team {
  padding: 60px 0 80px;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.service-dev-team .dev-team__title {
  margin: 0 auto 80px;
  max-width: 650px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  line-height: 1.25;
  color: #323232;
}

.service-dev-team .dev-team__description {
  margin: -50px auto 80px;
  max-width: 870px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 1.67;
  color: #555;
}

.service-dev-team .dev-team__steps {
  margin: 0 auto 18px;
  padding: 0 10%;
  width: 100%;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 0;
}

.service-dev-team .dev-team__steps .step__dotted-line {
  margin: 0 25px 0 30px;
  max-width: 260px;
  overflow: hidden;
}

.service-dev-team .dev-team__steps .step__dotted-line iframe {
  height: 3px;
}

.service-dev-team .dev-team__row {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  counter-reset: col-header;
}

.service-dev-team .dev-team__row .col ~ .col {
  margin-top: 100px;
}

.service-dev-team .dev-team__row .col {
  width: 100%;
}

.service-dev-team .dev-team__row .col .col-header {
  margin-top: 10px;
  margin-bottom: 35px;
  padding: 20px 10px;
}

.service-dev-team .dev-team__row .col .col-header .header-title {
  position: relative;
  margin-bottom: 21px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: #323232;
}

.service-dev-team .dev-team__row .col .col-header .header-title:before {
  counter-increment: col-header;
  content: counter(col-header);
  position: absolute;
  top: -75px;
  left: 50%;
  display: block;
  height: 50px;
  width: 50px;
  padding: 7px;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  color: #1476f2;
  border: 2px solid #1476f2;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s;
}

@media (min-width: 768px) {
  .service-dev-team .dev-team__row .col .col-header .header-title:before {
    opacity: 0;
  }
}

.service-dev-team
.dev-team__row
.col
.col-header
.header-title.animated:before {
  opacity: 1;
}

.service-dev-team .dev-team__row .col .col-header .header-title i {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 13px;
  color: #323232;
  opacity: 0.1;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.service-dev-team .dev-team__row .col .col-header .header-description {
  margin: 0 auto;
  max-width: 280px;
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.33;
  text-align: center;
  color: #323232;
}

.service-dev-team .dev-team__row .col .col-footer {
  padding: 40px;
  background-color: #fafafa;
}

.service-dev-team .dev-team__row .col .col-footer p {
  font-size: 15px;
  line-height: 1.53;
  color: #555;
}

.service-dev-team .dev-team__row .col .col-footer .footer-title {
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #323232;
}

.service-dev-team .dev-team__row .col .col-footer .footer-list li ~ li {
  margin-top: 20px;
}

.service-dev-team .dev-team__row .col .col-footer .footer-list li {
  margin-left: 32px;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.33;
  color: #555;
}

.service-dev-team .dev-team__row .col .col-footer .footer-list li:before {
  content: "";
  display: inline-block;
  margin-left: -32px;
  margin-right: 20px;
  width: 6px;
  height: 6px;
  background-color: #1476f2;
}

@media (min-width: 768px) {
  .service-dev-team {
    padding: 110px 0;
  }

  .service-dev-team .dev-team__description {
    margin: -80px auto 80px;
  }

  .service-dev-team .dev-team__steps {
    opacity: 1;
  }

  .service-dev-team .dev-team__title {
    margin: 0 auto 110px;
    font-size: 32px;
  }

  .service-dev-team .dev-team__row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .service-dev-team .dev-team__row .col ~ .col {
    margin-top: 0;
    margin-left: 30px;
  }

  .service-dev-team .dev-team__row .col-3 ~ .col-3 {
    margin-left: 20px;
  }

  .service-dev-team .dev-team__row .col {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: -webkit-calc(33.333% - 30px);
    width: calc(33.333% - 30px);
  }

  .service-dev-team .dev-team__row .col .col-header .header-title i {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .service-dev-team .dev-team__row .col .col-footer {
    padding: 25px;
  }

  .service-dev-team .dev-team__row .col-3 {
    width: -webkit-calc(25% - 20px);
    width: calc(25% - 20px);
  }
}

@media (min-width: 1024px) {
  .service-dev-team .dev-team__steps {
    opacity: 1;
  }

  .service-dev-team .dev-team__row .col ~ .col {
    margin-left: 60px;
  }

  .service-dev-team .dev-team__row .col-3 ~ .col-3 {
    margin-left: 20px;
  }

  .service-dev-team .dev-team__row .col {
    width: -webkit-calc(33.333% - 60px);
    width: calc(33.333% - 60px);
  }

  .service-dev-team .dev-team__row .col .col-header {
    padding: 20px;
  }

  .service-dev-team .dev-team__row .col .col-header .header-title {
    font-size: 20px;
  }

  .service-dev-team .dev-team__row .col .col-footer {
    padding: 35px;
  }

  .service-dev-team .dev-team__row .col-3 {
    width: -webkit-calc(25% - 20px);
    width: calc(25% - 20px);
  }
}

@media (min-width: 1244px) {
  .service-dev-team .dev-team__steps {
    opacity: 1;
  }

  .service-dev-team .dev-team__row .col .col-footer {
    padding: 40px;
  }
}

.our-qa-block {
  padding: 60px 0 80px;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.our-qa-block .our-qa__title {
  margin: 0 auto 80px;
  max-width: 650px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  line-height: 1.25;
  color: #323232;
}

.our-qa-block .our-qa__description {
  margin: -50px auto 80px;
  max-width: 870px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 1.67;
  color: #555;
}

.our-qa-block .our-qa__section {
  counter-reset: oval;
}

.our-qa-block .container {
  position: relative;
}

.our-qa-block .container .our-qa__steps {
  padding: 0 !important;
  position: absolute;
  top: 333px;
}

@media (min-width: 1024px) and (orientation: portrait) {
  .our-qa-block .container .our-qa__steps {
    top: 347px;
  }
}

@media (max-width: 1025px) and (orientation: landscape) {
  .our-qa-block .container .our-qa__steps {
    top: 347px;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  .our-qa-block .container .our-qa__steps {
    top: 333px;
  }
}

.our-qa-block .container li {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .our-qa-block .container li {
    display: block;
  }
}

.our-qa-block .our-qa__steps {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .our-qa-block .our-qa__steps {
    opacity: 0;
    top: 345px;
  }
}

.our-qa-block .our-qa__steps .vertical__dotted-line {
  padding-bottom: 100px;
}

@media (max-width: 1000px) {
  .our-qa-block .our-qa__steps .vertical__dotted-line {
    padding-bottom: 107px;
  }
}

.our-qa-block .our-qa__steps .vertical__dotted-line .last-dops {
  height: 88px;
}

.our-qa-block .our-qa__steps .vertical__dotted-line iframe {
  width: 4px;
  height: 67px;
}

@media (max-width: 1000px) and (max-width: 800px) {
  .our-qa-block .our-qa__steps .vertical__dotted-line {
    padding-bottom: 112px;
  }
}

@media (max-width: 768px) {
  .our-qa-block .oval {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 30px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.our-qa-block .oval:before {
  counter-increment: oval;
  content: counter(oval);
  display: block;
  height: 50px;
  width: 50px;
  padding: 7px;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  color: #1476f2;
  border: 2px solid #1476f2;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s;
  opacity: 0;
}

@media (max-width: 768px) {
  .our-qa-block .oval:before {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.our-qa-block .oval.animated:before {
  opacity: 1;
}

.our-qa-block .circle-description {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fafafa;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .our-qa-block .circle-description {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.our-qa-block .circle-description li:before {
  width: 6px;
  height: 6px;
  content: "\25a0";
  color: #1476f2;
  position: relative;
  bottom: 7px;
  padding-right: 30px;
}

@media (max-width: 768px) {
  .our-qa-block .circle-description li:before {
    bottom: 1px;
    padding-right: 10px;
  }
}

.our-qa-block .circle-description .circle-title {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  color: #323232;
  padding-left: 40px;
}

@media (max-width: 768px) {
  .our-qa-block .circle-description .circle-title {
    background-color: #fff;
    display: block;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    padding-left: 0;
  }
}

.our-qa-block .circle-description .circle-list {
  width: 50%;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #555;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 16px 0;
}

@media (max-width: 768px) {
  .our-qa-block .circle-description .circle-list {
    width: 100%;
  }
}

.our-qa-block .circle-description .circle-list li {
  margin: 10px;
}

@media (max-width: 768px) {
  .our-qa-block .circle-description .circle-list li {
    padding-left: 50px;
    list-style-position: inside;
    text-indent: -1em;
  }
}

.qa-technologies {
  margin-left: 120px;
}

.qa-technologies li {
  padding-left: 3em;
  margin-bottom: 15px !important;
}

.qa-technologies li:before {
  font-size: 12px;
  content: "\25A0";
  color: #0072f6;
  position: relative;
  bottom: 2px;
  padding-right: 18px;
}

.numerals {
  padding: 90px 0;
}

.numerals__item {
  font-size: 80px;
  color: #1476f2;
  line-height: 0.9;
}

.numerals__heading,
.numerals__item {
  font-family: Montserrat;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
}

.numerals__heading {
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.5;
  color: #323232;
}

.numerals__line {
  margin: 23px auto 25px;
  width: 30px;
  height: 2px;
  border: 2px solid #1476f2;
}

.numerals__text {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #1476f2;
}

.numerals .numerals__title {
  color: #323232;
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 30px;
}

.numerals .numerals__description {
  padding: 0 15px;
  margin: 0 auto 45px;
  max-width: 800px;
  text-align: center;
  line-height: 1.67;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #323232;
}

.numerals .container {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.numerals .col-4 {
  width: 25%;
  float: left;
  padding-right: 20px;
}

@media (max-width: 1215px) {
  .numerals .col-4 {
    width: 50%;
    padding-right: 0;
  }

  .numerals .col-4:nth-child(3),
  .numerals .col-4:nth-child(4) {
    margin-top: 50px;
  }
}

@media (max-width: 1215px) and (max-width: 1023px) {
  .numerals .col-4 {
    padding-right: 0;
  }

  .numerals .col-4:nth-child(2),
  .numerals .col-4:nth-child(4) {
    margin-right: 0;
  }
}

@media (max-width: 1215px) and (max-width: 767px) {
  .numerals .col-4 {
    width: 100%;
    float: none;
    margin: 50px 0 0;
  }
}

.service-title {
  color: #323232;
  font-weight: 600;
}

.design-services {
  padding: 90px 0;
  background-color: #fff;
}

.design-services .design-service-title {
  margin-bottom: 100px;
  text-align: center;
  font-size: 32px;
  line-height: 1.25;
  font-weight: 600;
  text-transform: uppercase;
  color: #323232;
}

@media (max-width: 768px) {
  .design-services .design-service-title {
    margin-bottom: 30px;
  }
}

.design-services .design-items {
  text-align: center;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .design-services .design-items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.design-services .design-items .design-item {
  width: 100%;
}

@media (max-width: 768px) {
  .design-services .design-items .design-item {
    margin-bottom: 30px;
  }
}

.design-services .design-items .design-item:last-child {
  margin-bottom: 0;
}

.design-services .design-items .design-item .design-icon {
  margin-bottom: 25px;
  height: 70px;
}

.design-services .design-items .design-title {
  padding: 0 22px;
  margin-bottom: 23px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  text-transform: none;
  color: #323232;
  text-align: center;
}

@media (max-width: 768px) {
  .design-services .container {
    width: 100%;
  }
}

.ux-block {
  padding-bottom: 0 !important;
}

.ui-block,
.ux-block {
  padding: 90px 0;
  background-color: #fff;
}

@media (max-width: 900px) {
  .ui-block,
  .ux-block {
    display: none;
  }
}

.ui-block .ux__title,
.ux-block .ux__title {
  margin: 0 auto 80px;
  max-width: 650px;
  width: 100%;
  text-align: center;
  font-size: 32px;
  line-height: 1.25;
  color: #323232;
}

.ui-block .ux__description,
.ux-block .ux__description {
  margin: -50px auto 80px;
  max-width: 870px;
  width: 100%;
  text-align: center;
  letter-spacing: normal;
  font-size: 18px;
  line-height: 1.67;
  color: #323232;
}

.ui-block .ui__points,
.ui-block .ux__points,
.ux-block .ui__points,
.ux-block .ux__points {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.ui-block .ui__points .row,
.ui-block .ux__points .row,
.ux-block .ui__points .row,
.ux-block .ux__points .row {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ui-block .ui__points .row .point,
.ui-block .ux__points .row .point,
.ux-block .ui__points .row .point,
.ux-block .ux__points .row .point {
  display: block;
  position: relative;
  height: 50px;
  width: 50px;
  padding: 7px;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  color: #1476f2;
  border: 2px solid #1476f2;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s;
}

.ui-block .ui__points .row .point.animated,
.ui-block .ux__points .row .point.animated,
.ux-block .ui__points .row .point.animated,
.ux-block .ux__points .row .point.animated {
  opacity: 1;
}

.ui-block .ui__points .row .point.animated .text,
.ui-block .ux__points .row .point.animated .text,
.ux-block .ui__points .row .point.animated .text,
.ux-block .ux__points .row .point.animated .text {
  position: absolute;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.9px;
  text-align: center;
  color: #323232;
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s;
}

.ui-block .ui__points .row .point.animated .third-text,
.ui-block .ux__points .row .point.animated .third-text,
.ux-block .ui__points .row .point.animated .third-text,
.ux-block .ux__points .row .point.animated .third-text {
  bottom: 65px;
  left: -75px;
  width: 200px;
}

.ui-block .ui__points .row .point.animated .second-text,
.ui-block .ux__points .row .point.animated .second-text,
.ux-block .ui__points .row .point.animated .second-text,
.ux-block .ux__points .row .point.animated .second-text {
  width: 100px;
  text-align: right;
  top: 0;
  right: 65px;
}

.ui-block .ui__points .row .point.animated .fourth-text,
.ui-block .ux__points .row .point.animated .fourth-text,
.ux-block .ui__points .row .point.animated .fourth-text,
.ux-block .ux__points .row .point.animated .fourth-text {
  width: 100px;
  left: 65px;
  text-align: left;
  top: 0;
}

.ui-block .ui__points .row .point.animated .first-text,
.ui-block .ux__points .row .point.animated .first-text,
.ux-block .ui__points .row .point.animated .first-text,
.ux-block .ux__points .row .point.animated .first-text {
  width: 100px;
  top: 15px;
  right: 40px;
}

.ui-block .ui__points .row .point.animated .five-text,
.ui-block .ux__points .row .point.animated .five-text,
.ux-block .ui__points .row .point.animated .five-text,
.ux-block .ux__points .row .point.animated .five-text {
  width: 100px;
  top: 15px;
  left: 40px;
}

.ui-block .ui__points .row .point.animated .six-text,
.ui-block .ux__points .row .point.animated .six-text,
.ux-block .ui__points .row .point.animated .six-text,
.ux-block .ux__points .row .point.animated .six-text {
  width: 100px;
  top: 4px;
  right: 60px;
  text-align: right;
}

.ui-block .ui__points .row .point.animated .seven-text,
.ui-block .ux__points .row .point.animated .seven-text,
.ux-block .ui__points .row .point.animated .seven-text,
.ux-block .ux__points .row .point.animated .seven-text {
  width: 100px;
  text-align: right;
  top: 0;
  right: 65px;
}

.ui-block .ui__points .row .point.animated .eight-text,
.ui-block .ux__points .row .point.animated .eight-text,
.ux-block .ui__points .row .point.animated .eight-text,
.ux-block .ux__points .row .point.animated .eight-text {
  width: 200px;
  top: 62px;
  left: -75px;
}

.ui-block .ui__points .row .point.animated .nine-text,
.ui-block .ux__points .row .point.animated .nine-text,
.ux-block .ui__points .row .point.animated .nine-text,
.ux-block .ux__points .row .point.animated .nine-text {
  width: 125px;
  text-align: left;
  top: 0;
  left: 65px;
}

.ui-block .ui__points .row .point.animated .ten-text,
.ui-block .ux__points .row .point.animated .ten-text,
.ux-block .ui__points .row .point.animated .ten-text,
.ux-block .ux__points .row .point.animated .ten-text {
  width: 100px;
  top: 4px;
  left: 65px;
  text-align: left;
}

.ui-block .row-1,
.ux-block .row-1 {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  top: 20px;
}

.ui-block .ui-1,
.ux-block .ui-1 {
  position: relative;
  top: -10px;
}

.ui-block .row-2,
.ux-block .row-2 {
  width: 28%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 901px) and (max-width: 1000px) {
  .ui-block .row-2,
  .ux-block .row-2 {
    width: 38%;
  }
}

@media (min-width: 1001px) and (max-width: 1170px) {
  .ui-block .row-2,
  .ux-block .row-2 {
    width: 33%;
  }
}

.ui-block .row-3,
.ux-block .row-3 {
  width: 38%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 901px) and (max-width: 1000px) {
  .ui-block .row-3,
  .ux-block .row-3 {
    width: 51%;
  }
}

@media (min-width: 1001px) and (max-width: 1170px) {
  .ui-block .row-3,
  .ux-block .row-3 {
    width: 44%;
  }
}

.ui-block .row-4,
.ux-block .row-4 {
  width: 55%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 901px) and (max-width: 1000px) {
  .ui-block .row-4,
  .ux-block .row-4 {
    width: 73%;
  }
}

@media (min-width: 1001px) and (max-width: 1170px) {
  .ui-block .row-4,
  .ux-block .row-4 {
    width: 64%;
  }
}

.ui-block .row-5,
.ux-block .row-5 {
  width: 61%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 901px) and (max-width: 1000px) {
  .ui-block .row-5,
  .ux-block .row-5 {
    width: 85%;
  }
}

@media (min-width: 1001px) and (max-width: 1170px) {
  .ui-block .row-5,
  .ux-block .row-5 {
    width: 72%;
  }
}

.ui-block .first-frame,
.ux-block .first-frame {
  width: 89px;
  height: 96px;
}

.ui-block .second-frame,
.ux-block .second-frame {
  width: 125px;
  height: 35px;
}

.ui-block .third-frame,
.ux-block .third-frame {
  width: 124px;
  height: 34px;
}

.ui-block .fourth-frame,
.ux-block .fourth-frame {
  width: 89px;
  height: 95px;
}

.ui-block .five-frame,
.ux-block .five-frame {
  width: 89px;
  height: 96px;
}

.ui-block .seven-frame,
.ui-block .six-frame,
.ux-block .seven-frame,
.ux-block .six-frame {
  width: 125px;
  height: 35px;
}

.ui-block .eight-frame,
.ux-block .eight-frame {
  width: 89px;
  height: 95px;
}

.separating-line {
  margin: 90px auto 0;
  width: 931px;
  height: 2px;
  opacity: 0.1;
  border: 1px solid #323232;
  background-color: #fff;
}

.title-process {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #323232;
  text-transform: uppercase;
  width: 200px;
  opacity: 0;
}

.title-process.animation {
  opacity: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.ui-block-mobile,
.ux-block-mobile {
  display: none;
}

@media (max-width: 900px) {
  .ui-block-mobile,
  .ux-block-mobile {
    display: block;
  }
}

.ui-block-mobile .ux-block-mobile__title,
.ux-block-mobile .ux-block-mobile__title {
  margin: 80px auto 30px;
  max-width: 650px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  line-height: 1.25;
  color: #323232;
}

.ui-block-mobile .ux-block-mobile__description,
.ux-block-mobile .ux-block-mobile__description {
  padding: 0 20px;
  max-width: 870px;
  width: 100%;
  text-align: center;
  letter-spacing: normal;
  font-size: 18px;
  line-height: 1.67;
  color: #323232;
}

.ui-block-mobile .title-process-ui,
.ui-block-mobile .title-process-ux,
.ux-block-mobile .title-process-ui,
.ux-block-mobile .title-process-ux {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #323232;
  text-transform: uppercase;
  width: 100%;
  padding: 80px 0 20px;
}

.ui-block-mobile .title-process-ui,
.ux-block-mobile .title-process-ui {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
}

.ui-block-mobile .column-ui__points,
.ui-block-mobile .column-ux__points,
.ux-block-mobile .column-ui__points,
.ux-block-mobile .column-ux__points {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}

.ui-block-mobile .column-ui__points .mobile-point,
.ui-block-mobile .column-ux__points .mobile-point,
.ux-block-mobile .column-ui__points .mobile-point,
.ux-block-mobile .column-ux__points .mobile-point {
  display: block;
  position: relative;
  height: 50px;
  width: 50px;
  padding: 7px;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  color: #1476f2;
  border: 2px solid #1476f2;
  border-radius: 50%;
  margin: 20px 0;
}

.ui-block-mobile .column-ui__points .mobile-point.animated,
.ui-block-mobile .column-ux__points .mobile-point.animated,
.ux-block-mobile .column-ui__points .mobile-point.animated,
.ux-block-mobile .column-ux__points .mobile-point.animated {
  opacity: 1;
}

.ui-block-mobile .column-ui__points .mobile-point .text,
.ui-block-mobile .column-ux__points .mobile-point .text,
.ux-block-mobile .column-ui__points .mobile-point .text,
.ux-block-mobile .column-ux__points .mobile-point .text {
  position: absolute;
  top: 12px;
  left: 60px;
  width: 153px;
  text-align: left;
  line-height: 1.31;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.ui-block-mobile .column-ui__points .mobile-point .big-text,
.ui-block-mobile .column-ux__points .mobile-point .big-text,
.ux-block-mobile .column-ui__points .mobile-point .big-text,
.ux-block-mobile .column-ux__points .mobile-point .big-text {
  position: absolute;
  top: 4px;
}

.ui-block-mobile .column-ui__points .mobile-point .ui,
.ui-block-mobile .column-ux__points .mobile-point .ui,
.ux-block-mobile .column-ui__points .mobile-point .ui,
.ux-block-mobile .column-ux__points .mobile-point .ui {
  position: absolute;
  top: 4px;
  width: 105px;
}

.ui-block-mobile .column-ui__points .mobile-frame,
.ui-block-mobile .column-ux__points .mobile-frame,
.ux-block-mobile .column-ui__points .mobile-frame,
.ux-block-mobile .column-ux__points .mobile-frame {
  width: 4px;
  height: 46px;
}

.ui-block-mobile .separating-mobile-line,
.ux-block-mobile .separating-mobile-line {
  margin: 65px auto;
  width: 278px;
  height: 2px;
  opacity: 0.1;
  border: 1px solid #323232;
}

.service-statistic {
  background-image: url(img/services/services-extension-statistic-bg.jpg);
  background-size: cover;
  background-position: 50%;
  min-height: 800px;
  padding: 90px 0 60px;
}

.service-statistic__title {
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: #323232;
}

@media screen and (max-width: 768px) {
  .service-statistic__title {
    padding: 0 40px;
  }
}

@media screen and (max-width: 500px) {
  .service-statistic__title {
    padding: 0;
  }
}

.service-statistic__title-lead {
  color: #555;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 90px;
  max-width: 630px;
}

@media screen and (max-width: 425px) {
  .service-statistic__title-lead {
    margin-bottom: 40px;
  }
}

.service-statistic__stats-cards {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.service-statistic__card {
  width: 100%;
  max-width: 290px;
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.05);
  height: 400px;
  margin: 15px auto;
  padding: 40px 50px;
}

.service-statistic__card.cloud {
  padding: 0;
  overflow: hidden;
}

.service-statistic__charts-area {
  width: 140px;
  height: 140px;
  margin: auto;
}

.service-statistic__charts-area .ct-label {
  display: none;
}

.service-statistic__chart-title {
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 35px;
  font-weight: 600;
  line-height: 1.33;
}

.service-statistic__legend-area {
  margin-top: 25px;
  width: 100%;
}

.service-statistic__legend-item {
  width: 100%;
  font-size: 15px;
  line-height: 2;
  position: relative;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 26px;
}

.service-statistic__legend-item:before {
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.service-statistic__legend-item.blue:before {
  background: #1476f2;
}

.service-statistic__legend-item.pink:before {
  background: #baa8ec;
}

.service-statistic__legend-item.yellow:before {
  background: #f6da70;
}

.service-statistic__legend-item.sky-blue:before {
  background: #97ebf9;
}

.service-statistic__legend-name {
  color: #555;
}

.service-statistic__legend-value {
  font-weight: 600;
  color: #000;
}

.blue {
  stroke: #1476f2;
}

.pink {
  stroke: #baa8ec;
}

.yellow {
  stroke: #f6da70;
}

.sky-blue {
  stroke: #97ebf9;
}

.augmented-reality {
  position: relative;
  padding: 370px 0 100px;
}

@media screen and (max-width: 768px) {
  .augmented-reality {
    padding-top: 270px;
  }
}

@media screen and (max-width: 600px) {
  .augmented-reality {
    padding-top: 170px;
  }
}

@media screen and (max-width: 425px) {
  .augmented-reality {
    padding-bottom: 50px;
  }
}

.augmented-reality .container {
  position: relative;
  z-index: 2;
}

.augmented-reality--iot {
  padding-top: 0;
  padding-bottom: 120px;
}

.augmented-reality--iot .augmented-reality__capabilities {
  padding-left: 0;
  padding-top: 0;
}

.augmented-reality--iot
.augmented-reality__capabilities
.augmented-reality__capabilities-item,
.augmented-reality--iot
.augmented-reality__capabilities
.augmented-reality__title {
  color: #fff;
}

.augmented-reality--iot .augmented-reality__hardware-content {
  border: 1px solid hsla(0, 0%, 59%, 0.5);
}

.augmented-reality--iot
.augmented-reality__hardware-content
.augmented-reality__title {
  color: #fff;
}

.augmented-reality--iot .augmented-reality__hardware-item {
  color: #fff;
  width: 33.33%;
}

.augmented-reality--iot .augmented-reality__hardware-item i {
  color: hsla(0, 0%, 100%, 0.7);
}

.augmented-reality--iot .augmented-reality__hardware-item p.small-text {
  color: #fff;
}

.augmented-reality--iot .augmented-reality__hardware-item:first-child {
  border-right: 1px solid hsla(0, 0%, 59%, 0.5);
}

.augmented-reality--iot .augmented-reality__hardware-item:first-child,
.augmented-reality--iot .augmented-reality__hardware-item:nth-child(2),
.augmented-reality--iot .augmented-reality__hardware-item:nth-child(3) {
  border-bottom: 1px solid hsla(0, 0%, 59%, 0.5);
  border-top: 1px solid hsla(0, 0%, 59%, 0.5);
}

.augmented-reality--iot .augmented-reality__hardware-item:nth-child(3) {
  border-left: 1px solid hsla(0, 0%, 59%, 0.5);
  border-right: none;
}

.augmented-reality--iot .augmented-reality__hardware-item:nth-child(4) {
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid hsla(0, 0%, 59%, 0.5);
}

.augmented-reality--iot .augmented-reality__hardware-item:nth-child(6) {
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: 1px solid hsla(0, 0%, 59%, 0.5);
}

@media screen and (max-width: 375px) {
  .augmented-reality--iot .augmented-reality__hardware-item {
    width: 50%;
  }

  .augmented-reality--iot .augmented-reality__hardware-item:first-child {
    border-top: 1px solid hsla(0, 0%, 59%, 0.5);
    border-bottom: none;
    border-left: none;
    border-right: 1px solid hsla(0, 0%, 59%, 0.5);
  }

  .augmented-reality--iot .augmented-reality__hardware-item:nth-child(2) {
    border-bottom: none;
    border-top: 1px solid hsla(0, 0%, 59%, 0.5);
    border-right: none;
    border-left: none;
  }

  .augmented-reality--iot .augmented-reality__hardware-item:nth-child(3) {
    border-top: 1px solid hsla(0, 0%, 59%, 0.5);
    border-bottom: none;
    border-left: none;
    border-right: 1px solid hsla(0, 0%, 59%, 0.5);
  }

  .augmented-reality--iot .augmented-reality__hardware-item:nth-child(4) {
    border-top: 1px solid hsla(0, 0%, 59%, 0.5);
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .augmented-reality--iot .augmented-reality__hardware-item:nth-child(5) {
    border-top: 1px solid hsla(0, 0%, 59%, 0.5);
    border-bottom: none;
    border-right: 1px solid hsla(0, 0%, 59%, 0.5);
    border-left: none;
  }

  .augmented-reality--iot .augmented-reality__hardware-item:nth-child(6) {
    border-top: 1px solid hsla(0, 0%, 59%, 0.5);
    border-bottom: none;
    border-right: none;
    border-left: none;
  }
}

.augmented-reality--iot .augmented-reality__hardware {
  padding-left: 0;
  width: 50%;
  max-width: 540px;
}

@media screen and (max-width: 600px) {
  .augmented-reality--iot .augmented-reality__hardware {
    width: 100%;
  }
}

.augmented-reality--iot .augmented-reality__hardware-content {
  width: 100%;
  max-width: none;
}

.augmented-reality__absolute-picture {
  position: absolute;
  width: 100%;
  max-width: 880px;
  margin: auto;
  top: -70px;
  height: 550px;
  z-index: 1;
  left: 0;
  right: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);
  -webkit-transition: all 2s;
  transition: all 2s;
}

.augmented-reality__absolute-picture.animated {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

@media screen and (max-width: 768px) {
  .augmented-reality__absolute-picture {
    width: 90%;
  }
}

@media screen and (max-width: 425px) {
  .augmented-reality__absolute-picture {
    background-size: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .augmented-reality__absolute-picture {
    width: 100%;
    height: 315px;
    background-size: 150%;
  }
}

.augmented-reality__content {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

@media screen and (max-width: 600px) {
  .augmented-reality__content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.augmented-reality__capabilities {
  width: 50%;
  padding-left: 230px;
  padding-top: 75px;
}

@media screen and (max-width: 1024px) {
  .augmented-reality__capabilities {
    padding-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .augmented-reality__capabilities {
    width: 100%;
    padding-top: 0;
    margin-bottom: 60px;
  }
}

.augmented-reality__hardware {
  width: 50%;
  padding-left: 115px;
}

@media screen and (max-width: 768px) {
  .augmented-reality__hardware {
    padding-left: 25px;
  }
}

@media screen and (max-width: 600px) {
  .augmented-reality__hardware {
    width: 100%;
    padding-left: 0;
  }
}

.augmented-reality__hardware-content {
  width: 100%;
  max-width: 360px;
  border: 1px solid hsla(0, 0%, 59%, 0.3);
  border-radius: 14px;
}

.augmented-reality__hardware-content .augmented-reality__title {
  text-align: center;
  padding: 20px;
}

@media screen and (max-width: 600px) {
  .augmented-reality__hardware-content {
    max-width: none;
  }
}

.augmented-reality__hardware-items {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.augmented-reality__hardware-item,
.augmented-reality__hardware-items {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.augmented-reality__hardware-item {
  width: 50%;
  height: 135px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 30px;
}

.augmented-reality__hardware-item i {
  font-size: 40px;
  margin-bottom: 15px;
  opacity: 0.7;
  color: #323232;
}

.augmented-reality__hardware-item p.small-text {
  font-weight: 600;
  line-height: 1.15;
  text-align: center;
  color: #323232;
  text-transform: uppercase;
  width: 100%;
  max-width: 135px;
}

.augmented-reality__hardware-item:first-child {
  border-right: 1px solid hsla(0, 0%, 59%, 0.3);
}

.augmented-reality__hardware-item:first-child,
.augmented-reality__hardware-item:nth-child(2) {
  border-bottom: 1px solid hsla(0, 0%, 59%, 0.3);
  border-top: 1px solid hsla(0, 0%, 59%, 0.3);
}

.augmented-reality__hardware-item:nth-child(3) {
  border-right: 1px solid hsla(0, 0%, 59%, 0.3);
}

.augmented-reality__title {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  color: #323232;
  padding: 24px 0;
}

.augmented-reality__capabilities-item {
  position: relative;
  font-size: 15px;
  line-height: 1.15;
  text-align: left;
  color: #323232;
  padding: 8px;
  padding-left: 25px;
}

.augmented-reality__capabilities-item:before {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  width: 5px;
  height: 5px;
  display: block;
  content: "";
  background: #1476f2;
}

.iot-container {
  width: 100%;
  background: #232323;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 70%;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 425px) {
  .iot-container.animated .iot-absolute-cover {
    -webkit-animation: growAnimation 10s 1;
    animation: growAnimation 10s 1;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@media screen and (max-width: 425px) {
  .iot-container.animated .iot-absolute-cover {
    -webkit-animation: bigGrowAnimation 10s 1;
    animation: bigGrowAnimation 10s 1;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
  }
}

.iot-absolute-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url(img/iot-container-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

@-webkit-keyframes growAnimation {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes growAnimation {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@-webkit-keyframes bigGrowAnimation {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  to {
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

@keyframes bigGrowAnimation {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  to {
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

.notice-block__content {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.notice-block__header {
  font-size: 15px;
  font-weight: 600;
  line-height: 2;
  text-align: center;
  color: #323232;
  text-transform: uppercase;
  padding: 0 20px;
  background: #fafafa;
  width: 100%;
  max-width: 215px;
  margin: 0 auto -21px;
  z-index: 2;
}

.notice-block__lead {
  border: 1px solid #ededed;
  padding: 28px 60px;
  font-weight: 300;
  z-index: 1;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .notice-block__lead {
    padding: 28px 20px;
    font-size: 13px;
  }
}

.notice-block__lead a {
  color: #1476f2;
}

.notice-block__lead a:hover {
  color: #0b5ec7;
}

.seo-about {
  padding: 85px 0 35px;
}

.seo-about__list {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.seo-about__list-item {
  width: 100%;
  max-width: 450px;
  position: relative;
  padding-left: 20px;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .seo-about__list-item {
    max-width: none;
  }
}

.seo-about__list-item:before {
  position: absolute;
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  left: 0;
  top: 7px;
  background: #1476f2;
}

.seo-about__item-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
  text-transform: uppercase;
}

.seo-about__item-lead,
.seo-about__item-title {
  font-family: Montserrat;
  text-align: left;
  color: #323232;
}

.seo-about__item-lead {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.54;
}

.fixed-ebook-form--mobile-relative {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .fixed-ebook-form--mobile-relative {
    width: 405px;
    margin: 0;
  }
}

@media (min-width: 1040px) {
  .fixed-ebook-form--mobile-relative {
    width: 455px;
  }
}

.fixed-ebook-form--mobile-relative.fixed-form {
  position: fixed;
  top: 90px;
  right: 40px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .fixed-ebook-form--mobile-relative.fixed-form {
    right: 10px;
  }
}

.fixed-ebook-form--mobile-relative.stop-form {
  position: absolute;
  top: 30px;
  right: 40px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .fixed-ebook-form--mobile-relative.stop-form {
    right: 10px;
  }
}

@media (min-width: 1280px) {
  .fixed-ebook-form--mobile-relative.fixed-form,
  .fixed-ebook-form--mobile-relative.stop-form {
    right: 50%;
    -webkit-transform: translateX(600px);
    -ms-transform: translateX(600px);
    transform: translateX(600px);
  }
}

.fixed-ebook-form {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 45px 30px;
  background: #fff;
  width: 100%;
  margin: 0 auto;
  -webkit-box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.07);
}

@media (min-width: 768px) {
  .fixed-ebook-form {
    width: 95%;
  }
}

@media (min-width: 1024px) {
  .fixed-ebook-form {
    width: 455px;
  }
}

.fixed-ebook-form__title {
  max-width: 286px;
  margin: 0 auto 20px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
  color: #323232;
  text-transform: uppercase;
}

.js-thx__tick {
  background-image: url(img/success-form.svg);
  width: 90px;
  height: 90px;
  background-size: cover;
  margin: 0 auto 30px;
}

@media screen and (max-width: 768px) {
  .js-thx__tick {
    display: none;
  }
}

.js-thx__title {
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #323232;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.js-thx__subtitle {
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25;
  text-align: center;
  color: #323232;
  margin-bottom: 75px;
}

@media screen and (max-width: 768px) {
  .js-thx__subtitle {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 320px) {
  .js-thx__subtitle {
    font-size: 21px;
  }
}

.js-thx__lead {
  font-family: Montserrat, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.67;
  text-align: center;
  color: #777;
  margin-bottom: 42px;
}

.js-thx__link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.form-input--two,
.js-thx__link {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-input--two .form-group {
  width: 50%;
}

.form-input--two .form-group:last-child {
  margin-bottom: 20px;
}

.form-input--two .form-group:after {
  content: "";
  position: absolute;
  -webkit-transform: translate(150%, -50%);
  -ms-transform: translate(150%, -50%);
  transform: translate(150%, -50%);
  -webkit-transition: -webkit-transform 0.1s ease;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
  right: 15px;
  top: 50%;
}

.form-input--two .form-group.has-error {
  color: #e74c3c;
}

.form-input--two .form-group.has-error:before {
  display: none;
}

.form-input--two .form-group.has-error:after {
  background-image: url(img/icons/has-error.svg);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 17px;
  width: 20px;
}

.form-input--two .form-group.has-error .upload__label--white {
  color: #e74c3c;
}

.form-input--two .form-group.has-error.has-success:not(.js-emptyFiles) {
  color: #26ad5f;
}

.form-input--two .form-group.has-error.has-success:not(.js-emptyFiles):before {
  display: none;
}

.form-input--two .form-group.has-error.has-success:not(.js-emptyFiles):after {
  background-image: url(img/icons/has-success.svg);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 12px;
  width: 16px;
}

.form-input--two
.form-group.has-error.has-success:not(.js-emptyFiles)
.upload__label--white {
  color: #26ad5f;
}

.form-input--two .form-group:first-child {
  margin-right: 20px;
}

@media screen and (max-width: 465px) {
  .form-input--two {
    display: block;
  }

  .form-input--two .form-group {
    width: 100%;
  }
}

.registration {
  border-radius: 8px;
}

@media screen and (max-width: 465px) {
  .registration {
    padding: 20px;
  }
}

.registration__img-wrapper {
  width: 88px;
  height: 88px;
  margin: 0 auto 35px;
}

.registration__img {
  max-width: 100%;
  max-height: 100%;
}

.registration__title {
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #323232;
  text-transform: uppercase;
  margin-bottom: 50px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .registration__title {
    font-size: 28px;
  }
}

@media screen and (max-width: 465px) {
  .registration__title {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.registration__link {
  border: none;
  display: inherit;
  height: 50px;
  width: 230px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  padding: 18px 0;
  border-radius: 25px;
  background-image: -webkit-repeating-linear-gradient(
          20deg,
          #9a59c1,
          #3fb1fd 50%,
          #9a59c1 80%,
          #9a59c1
  );
  background-image: repeating-linear-gradient(
          70deg,
          #9a59c1,
          #3fb1fd 50%,
          #9a59c1 80%,
          #9a59c1
  );
  background-position: 100% 0;
  background-size: 200% 100%;
  -webkit-transition: 0.5s cubic-bezier(0.365, 0.335, 0, 1) !important;
  transition: 0.5s cubic-bezier(0.365, 0.335, 0, 1) !important;
  margin: 0 auto;
}

.registration__link:hover {
  background-position: 0;
  color: #fff;
}

@media screen and (max-width: 465px) {
  .registration__link {
    padding: 15px 0;
  }
}

.about-webinar {
  background-color: #fafafa;
}

.about-webinar .about-webinar__container {
  width: 100%;
}

.about-webinar .about-webinar__container h2 {
  text-align: left;
}

@media (min-width: 768px) {
  .about-webinar .about-webinar__container h2 {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .about-webinar .about-webinar__container ul {
    margin-bottom: 60px;
  }
}

@media (min-width: 1024px) {
  .about-webinar .about-webinar__container {
    max-width: 50%;
  }
}

.participants__title {
  text-align: center;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #323232;
  margin-bottom: 35px;
}

.participant__picture {
  width: 130px;
  height: 130px;
  background: #1472fc;
  margin-bottom: 35px;
}

.participants__members {
  width: 100%;
}

.participant,
.participants__members {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.participant {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid hsla(0, 0%, 80%, 0.3);
}

.participant:last-child {
  border-right: none;
}

.participant__name {
  font-weight: 600;
  color: #323232;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.participant__name,
p.participant__position {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  text-align: center;
}

p.participant__position {
  line-height: 1.33;
  color: #555;
  margin: 0 auto;
}

.participant__social {
  width: 20px;
  height: 20px;
  margin: 15px auto 0;
}

.bottom-text-webinar .text {
  width: 100%;
  max-width: 600px;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25;
  text-align: center;
  color: #4a90e2;
  margin: auto;
  padding: 50px 0 30px;
}

@media (min-width: 768px) {
  p.participant__position {
    max-width: inherit;
  }
}

@media screen and (max-width: 465px) {
  .about-webinar__title {
    text-align: center;
  }

  .participants__members {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: block;
  }

  .participant {
    padding: 20px 0;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .participant__picture {
    margin-bottom: 0;
    width: 100%;
    max-width: 120px;
    height: 120px;
    margin-right: 10px;
    object-fit: cover;
    -ms-flex-item-align: normal;
    -ms-grid-row-align: normal;
    align-self: normal;
  }

  .participant {
    border-bottom: 1px solid hsla(0, 0%, 80%, 0.3);
    border-right: none;
  }

  .participant:last-child {
    border-bottom: none;
  }

  .participant__name,
  p.participant__position {
    text-align: left;
  }

  .participant__social {
    margin: 15px 0 0;
  }
}

.company {
  padding-top: 100px;
}

@media screen and (max-width: 465px) {
  .company {
    padding-top: 40px;
  }
}

.company__container {
  padding-bottom: 55px;
  color: #323232;
}

.company__container:last-child {
  padding-bottom: 0;
}

.company h3.company__title {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 20px;
  margin: 0;
}

.company p.company__text {
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 25px;
  margin: 0;
}

.company__link {
  position: relative;
  font-size: 14px;
  color: #1476f2;
  padding-left: 30px;
}

.company__link:before {
  content: "";
  background: url(img/company-link-icon.svg) no-repeat;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
}

.company__link:hover {
  color: #171717;
}

.latest-teach {
  padding: 50px 0;
  background-color: #fff;
}

.latest-teach .teach-title {
  margin-bottom: 15px;
  text-align: center;
  font-size: 32px;
  line-height: 1.56;
  font-weight: 600;
  text-transform: uppercase;
  color: #323232;
}

.latest-teach .teach-description {
  margin: 0 auto 50px;
  max-width: 770px;
  text-align: center;
  font-size: 18px;
  line-height: 1.67;
  color: #555;
}

.latest-teach .teach-items {
  text-align: center;
  margin-bottom: 30px;
}

.latest-teach .teach-items .teach-item {
  margin-bottom: 60px;
}

.latest-teach .teach-items .teach-item:last-child {
  margin-bottom: 0;
}

.latest-teach .teach-items .teach-item .teach-icon {
  margin-bottom: 12px;
}

.latest-teach .teach-items .teach-item .teach-icon:hover svg g path {
  stroke: #3f7fe7;
}

.latest-teach .teach-items .item-title {
  margin-bottom: 23px;
  font-size: 20px;
  line-height: 1.35;
  font-weight: 600;
  text-transform: uppercase;
  color: #323232;
}

.latest-teach .teach-items .more-info .item-title {
  display: none;
}

.latest-teach .teach-items .more-info .item-description {
  font-size: 16px;
  line-height: 1.88;
  font-weight: 300;
  color: #323232;
}

@media (min-width: 768px) {
  .latest-teach {
    padding: 90px 0;
  }

  .latest-teach .container {
    overflow: hidden;
  }

  .latest-teach .teach-description {
    margin: 0 auto 100px;
  }

  .latest-teach .teach-items {
    position: relative;
    margin-bottom: 0;
    text-align: left;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .latest-teach .teach-items .teach-item {
    margin-bottom: 0;
    width: 100%;
    border-bottom: 1px solid rgba(50, 50, 50, 0.1);
  }

  .latest-teach .teach-items .teach-item .preview {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 30px;
    text-align: center;
    cursor: pointer;
  }

  .latest-teach .teach-items .teach-item .teach-icon {
    margin-bottom: 35px;
  }

  .latest-teach .teach-items .teach-item .item-title {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 400;
    text-transform: none;
    color: #323232;
  }

  .latest-teach .teach-items .teach-item .more-info {
    position: absolute;
    margin-top: 50px;
    left: 110%;
    width: 100%;
    -webkit-transition: left 0s;
    transition: left 0s;
  }

  .latest-teach .teach-items .teach-item .more-info .item-title {
    display: block;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 600;
    text-transform: uppercase;
    color: #323232;
  }

  .latest-teach .teach-items .teach-item .more-info .item-description {
    font-size: 16px;
    line-height: 1.56;
    font-weight: 400;
    color: #323232;
  }

  .latest-teach .teach-items .teach-item.active {
    border-bottom: 1px solid #3f7fe7;
  }

  .latest-teach .teach-items .teach-item.active .more-info {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: 0;
    width: 100%;
    -webkit-transition: left 0.5s;
    transition: left 0.5s;
  }

  .latest-teach .teach-items .teach-item.active .more-info .item-description,
  .latest-teach .teach-items .teach-item.active .more-info .item-title {
    width: 50%;
  }
}

canvas {
  display: block;
  vertical-align: bottom;
}

.section-offers {
  position: relative;
}

@media screen and (max-width: 767px) {
  .section-offers .container {
    padding: 0;
  }
}

#particles {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(./img/home-offers/bg-offers.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -999;
}

.offers {
  padding: 80px 0 120px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.offers-title {
  margin-bottom: 70px;
  line-height: 0.94;
  text-align: center;
}

.p-tabview-nav {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 100%;
  mmax-width: 930px;
  margin-bottom: 70px;
  overflow: hidden;
}

.p-tabview {
}

.offers-tablinks {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 33.3333%;
  flex: 0 1 33.3333%;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(50, 50, 50, 0.1);
  color: #323232;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

@media screen and (max-width: 1023px) {
  .offers-tablinks {
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  .offers-tablinks {
    font-size: 12px;
  }
}

.offers-tablinks:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #3f7fe7;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.offers-tablinks.active,
.offers-tablinks:hover {
  color: #3f7fe7;
}

.offers-tablinks.active:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.p-tabview-panel {
  margin: 0 auto;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  max-width: 1120px;

  background-color: rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.03);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.03);

  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

@media screen and (max-width: 1023px) {
  .p-tabview-panel {
    max-width: 602px;
  }
}

@media screen and (max-width: 659px) {
  .p-tabview-panel {
    max-width: 300px;
  }
}

.p-tabview-panel .active {
  max-height: none;
  height: 100%;
  width: 100%;
  opacity: 1;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.p-tabview-panel .tabcontent-item {
  padding: 30px 20px 0 0;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 33.3333%;
  flex: 0 1 33.3333%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  max-width: 372px;
  min-width: 300px;
  min-height: 190px;
  height: 100%;
  background-color: hsla(0, 0%, 100%, 0.8);
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.p-tabview-panel .tabcontent-item:not(:nth-child(n + 4)) {
  margin-bottom: 2px;
}

.p-tabview-panel .tabcontent-item:first-child {
  border-top-left-radius: 7px;
}

.p-tabview-panel .tabcontent-item:last-child {
  border-bottom-right-radius: 7px;
}

.p-tabview-panel .tabcontent-item:nth-child(3) {
  border-top-right-radius: 7px;
}

.p-tabview-panel .tabcontent-item:nth-child(4) {
  border-bottom-left-radius: 7px;
}

@media screen and (max-width: 1023px) {
  .p-tabview-panel .tabcontent-item {
    padding-top: 25px;
  }

  .p-tabview-panel .tabcontent-item:not(:nth-child(n + 5)) {
    margin-bottom: 2px;
  }

  .p-tabview-panel .tabcontent-item:nth-child(5) {
    border-bottom-left-radius: 7px;
  }

  .p-tabview-panel .tabcontent-item:nth-child(2) {
    border-top-right-radius: 7px;
  }

  .p-tabview-panel .tabcontent-item:nth-child(3) {
    border-top-right-radius: 0;
  }

  .p-tabview-panel .tabcontent-item:nth-child(4) {
    border-bottom-left-radius: 0;
  }

  .p-tabview-panel .tabcontent-item:nth-child(odd) {
    margin-right: 2px;
  }
}

@media screen and (max-width: 767px) {
  .p-tabview-panel .tabcontent-item {
    padding-top: 20px;
  }

  .p-tabview-panel .tabcontent-item:nth-child(odd) {
    margin-right: 2px;
  }

  .p-tabview-panel .tabcontent-item:not(:nth-child(n + 5)) {
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 659px) {
  .p-tabview-panel .tabcontent-item:nth-child(odd) {
    margin-right: 0;
  }

  .p-tabview-panel .tabcontent-item:not(:last-child) {
    margin-bottom: 2px;
  }

  .p-tabview-panel .tabcontent-item:first-child {
    border-top-right-radius: 7px;
  }

  .p-tabview-panel .tabcontent-item:last-child {
    border-bottom-left-radius: 7px;
  }

  .p-tabview-panel .tabcontent-item:nth-child(5) {
    border-bottom-left-radius: 0;
  }

  .p-tabview-panel .tabcontent-item:nth-child(2) {
    border-top-right-radius: 0;
  }
}

.p-tabview-panel .tabcontent-item .offer-image {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 25%;
  flex: 0 1 25%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding-left: 10px;
}

.p-tabview-panel .tabcontent-item .offer-image g > path {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.p-tabview-panel .tabcontent-item .offer-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 70%;
  flex: 0 1 70%;
  max-width: 260px;
  padding-left: 3px;
}

.p-tabview-panel .tabcontent-item .offer-content-title {
  max-width: 260px;
  margin-bottom: 13px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  color: #323232;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media screen and (max-width: 767px) {
  .p-tabview-panel .tabcontent-item .offer-content-title {
    font-size: 14px;
    max-width: 180px;
  }
}

.p-tabview-panel .tabcontent-item .offer-content-description {
  margin: 0 0 12px;
  font-size: 13.5px;
  line-height: 1.43;
  text-align: left;
  color: rgba(50, 50, 50, 0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1023px) {
  .p-tabview-panel .tabcontent-item .offer-content-description {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .p-tabview-panel .tabcontent-item .offer-content-description {
    font-size: 13px;
    max-width: 180px;
  }
}

.p-tabview-panel .tabcontent-item .offer-content-link-more {
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.p-tabview-panel .tabcontent-item .offer-content-link-more i {
  font-size: 11px;
  margin-left: -3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.p-tabview-panel .tabcontent-item:hover {
  background-color: rgba(20, 118, 242, 0.8);
}

.p-tabview-panel .tabcontent-item:hover .offer-content-title {
  color: #fff;
}

.p-tabview-panel .tabcontent-item:hover .offer-content-description {
  color: hsla(0, 0%, 100%, 0.85);
}

.p-tabview-panel .tabcontent-item:hover .offer-content-link-more {
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.p-tabview-panel .tabcontent-item:hover .offer-content-link-more i {
  margin-left: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.p-tabview-panel .tabcontent-item:hover .offer-image {
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.p-tabview-panel .tabcontent-item:hover .offer-image g > path {
  stroke: hsla(0, 0%, 100%, 0.3);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

@media screen and (max-width: 767px) {
  .offices .container {
    padding: 0;
  }
}

.offices__title {
  padding-top: 40px;
  padding-bottom: 40px;
}

.offices__title h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 0.94;
  text-align: center;
  color: #323232;
}

.offices__title p {
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: #555;
}

.offices-slider {
  position: relative;
  display: block !important;
  height: 360px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #f6f6f6;
}

.offices-slider .offices-slider__item {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  height: 360px;
  position: relative;
  width: 100%;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  overflow: hidden;
}
.item1 {
  background-image: url(./img/home-offices/mautritius-cybercity-EBENE-4.jpg);
}
.item2 {
  background-image: url(./img/home-offices/usa-palo-alto.jpg);
}
.item3 {
  background-image: url(./img/home-offices/lagos.jpeg);
}

.offices-slider
.offices-slider__item:hover
.offices-slider__content
.offices-slider__overlay {
  opacity: 1;
}

.offices-slider
.offices-slider__item:hover
.offices-slider__content
.offices-slider__text {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.offices-slider
.offices-slider__item:hover
.offices-slider__content
.offices-slider__text
.offices-slider__address {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.offices-slider .offices-slider__item .offices-slider__content {
  padding: 15px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  color: #fff;
  background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(transparent),
          to(rgba(0, 0, 0, 0.45))
  );
  background-image: -webkit-linear-gradient(
          top,
          transparent,
          rgba(0, 0, 0, 0.45)
  );
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.45));
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 1023px) {
  .offices-slider .offices-slider__item .offices-slider__content {
    padding: 15px;
  }
}

@media screen and (max-width: 767px) {
  .offices-slider .offices-slider__item .offices-slider__content {
    padding: 30px;
    max-width: 100%;
  }
}

@media screen and (min-width: 1600px) {
  .offices-slider .offices-slider__item .offices-slider__content {
    padding: 0;
  }
}

.offices-slider
.offices-slider__item
.offices-slider__content
.offices-slider__overlay {
  opacity: 0;
  background-color: rgba(20, 118, 242, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: -1;
}

.offices-slider
.offices-slider__item
.offices-slider__content
.offices-slider__text {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 38%;
  flex: 0 1 38%;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(70%);
  -ms-transform: translateY(70%);
  transform: translateY(70%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.offices-slider
.offices-slider__item
.offices-slider__content
.offices-slider__text
.offices-slider__city {
  margin: 0 auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
}

.offices-slider
.offices-slider__item
.offices-slider__content
.offices-slider__text
.offices-slider__address {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s linear 0.5s, opacity 0.8s linear;
  transition: visibility 0s linear 0.5s, opacity 0.8s linear;
}

.offices-slider
.offices-slider__item
.offices-slider__content
.offices-slider__text
.offices-slider__address
p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
}

.offices-slider
.offices-slider__item
.offices-slider__content
.offices-slider__text
.offices-slider__address
p
a {
  color: #fff;
}

.offices-slider
.offices-slider__item
.offices-slider__content
.offices-slider__text
.offices-slider__address
p
a:hover {
  text-decoration: underline;
}

.offices-slider .owl-next,
.offices-slider .owl-prev {
  font-size: 0;
  position: absolute;
  background: hsla(0, 0%, 100%, 0) url(img/arrow-left-dark.svg) no-repeat 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 80px;
  width: 40px;
}

.offices-slider .owl-next.disabled,
.offices-slider .owl-prev.disabled {
  display: none;
}

.offices-slider .owl-prev {
  left: 8px;
}

.offices-slider .owl-prev:hover {
  background: hsla(0, 0%, 100%, .06) url(img/arrow-right-light.svg) no-repeat 50%
}

.offices-slider .owl-next,
.offices-slider .owl-prev:hover {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.offices-slider .owl-next {
  right: 8px;
}

.offices-slider .owl-next:hover {
  /*background: hsla(0, 0%, 100%, .06) url(img/arrow-right-light.svg) no-repeat 50%;*/
  -webkit-transform: translateY(-50%) rotate(1turn);
  -ms-transform: translateY(-50%) rotate(1turn);
  transform: translateY(-50%) rotate(1turn);
}

@media only screen and (max-width: 1024px) {
  .enterprise-core-mob {
    position: relative;
    padding-bottom: 40px;
    width: 100%;
  }

  .enterprise-core-mob * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .enterprise-core-mob #particles {
    height: 2920px;
  }

  .enterprise-core-mob .tech-container {
    margin: 60px auto;
    width: 276px;
    border: 1px solid #aac7ed;
    border-top: none;
    border-bottom: none;
    background-color: #fff;
  }

  .enterprise-core-mob .tech-item {
    position: relative;
    margin-top: 40px;
    padding-top: 40px;
  }

  .enterprise-core-mob .tech-item.main {
    margin-top: 0;
    padding-top: 32px;
    min-height: 140px;
    border: 1px solid #1476f2;
    background-color: #1476f2;
  }

  .enterprise-core-mob .tech-item.main,
  .enterprise-core-mob .tech-item.main .read-more-wrap {
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .enterprise-core-mob .tech-item.main .tech-triangle {
    position: absolute;
    left: -2px;
    bottom: -41px;
  }

  .enterprise-core-mob .tech-item.main .tech-triangle.up {
    top: -41px;
  }

  .enterprise-core-mob .tech-item.main h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.67;
  }

  .enterprise-core-mob .tech-item.main p {
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    color: #fff;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }

  .enterprise-core-mob .tech-item.main label.main {
    color: #fff;
    z-index: 1;
  }

  .enterprise-core-mob .tech-item.main label.main:before {
    bottom: -87px;
  }

  .enterprise-core-mob .tech-item.main label.main:after {
    bottom: -92px;
    border-color: #fff;
  }

  .enterprise-core-mob .tech-item.main .bg-cube {
    position: absolute;
    height: 180px;
    width: 180px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(./img/about-page/cube.png);
  }

  .enterprise-core-mob .tech-item img {
    margin: 0 auto;
    width: 85px;
  }

  .enterprise-core-mob .tech-item h3 {
    font-size: 16px;
    text-align: center;
  }

  .enterprise-core-mob .tech-item p {
    font-size: 0;
    opacity: 0;
    max-height: 0;
  }

  .enterprise-core-mob .tech-item .item-list {
    display: block;
    margin: 0 auto;
    padding: 10px 0 5px 24px;
    width: 180px;
    list-style-type: none;
    pointer-events: none;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .enterprise-core-mob .tech-item .item-list li {
    font-size: 14px;
    line-height: 1.43;
    color: #323232;
    opacity: 0;
    max-height: 0;
    font-size: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .enterprise-core-mob .tech-item .item-list li:not(:last-child):before {
    content: "";
    display: inline-block;
    margin-left: -16px;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    background-color: #1476f2;
  }

  .enterprise-core-mob .tech-item .item-list li a {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    padding: 0;
    margin-top: 15px;
    font-weight: 600;
    line-height: 1.88;
    color: #1476f2;
    text-transform: uppercase;
    bottom: 5px;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: auto;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .enterprise-core-mob .tech-item .item-list li a i {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    margin-left: 8px;
    line-height: 2;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .enterprise-core-mob .tech-item .read-more-state {
    display: none;
  }

  .enterprise-core-mob .tech-item .read-more-state:checked ~ .read-more-wrap {
    padding-bottom: 28px;
  }

  .enterprise-core-mob .tech-item .read-more-state:checked ~ .read-more-wrap li,
  .enterprise-core-mob .tech-item .read-more-state:checked ~ .read-more-wrap p {
    opacity: 1;
    font-size: inherit;
    max-height: 290px;
  }

  .enterprise-core-mob
  .tech-item
  .read-more-state:checked
  ~ .read-more-wrap
  li
  a,
  .enterprise-core-mob
  .tech-item
  .read-more-state:checked
  ~ .read-more-wrap
  p
  a {
    opacity: 1;
    font-size: 16px;
    max-height: 290px;
  }

  .enterprise-core-mob
  .tech-item
  .read-more-state:checked
  ~ .read-more-wrap
  li
  a
  i,
  .enterprise-core-mob
  .tech-item
  .read-more-state:checked
  ~ .read-more-wrap
  p
  a
  i {
    max-height: 290px;
    opacity: 1;
    font-size: 11px;
  }

  .enterprise-core-mob .tech-item .read-more-state ~ .read-more-trigger:before {
    content: "See more";
    position: absolute;
    width: 72px;
    bottom: -18px;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }

  .enterprise-core-mob .tech-item .read-more-state:checked ~ .bg-cube {
    background-image: none;
  }

  .enterprise-core-mob
  .tech-item
  .read-more-state:checked
  ~ .read-more-trigger:before {
    content: "Close";
    bottom: -30px;
  }

  .enterprise-core-mob
  .tech-item
  .read-more-state:checked
  ~ .read-more-trigger:after {
    bottom: -1px;
    -webkit-transform: translateX(-50%) rotate(135deg);
    -ms-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
  }

  .enterprise-core-mob .tech-item .read-more-trigger {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 72px;
    font-size: 14px;
    line-height: 2.14;
    text-align: center;
    color: #323232;
    cursor: pointer;
  }

  .enterprise-core-mob .tech-item .read-more-trigger:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -23px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(-45deg);
    -ms-transform: translateX(-50%) rotate(-45deg);
    transform: translateX(-50%) rotate(-45deg);
    width: 5px;
    height: 5px;
    border-bottom: 1px solid #323232;
    border-left: 1px solid #323232;
  }

  .enterprise-core-mob .tech-item .tech-triangle {
    position: absolute;
    left: -1px;
    bottom: -41px;
  }

  .enterprise-core,
  .enterprise-core * {
    display: none;
  }
}

@media only screen and (min-width: 1025px) {
  .enterprise-core-mob,
  .enterprise-core-mob * {
    display: none;
  }

  .enterprise-core {
    height: 850px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .enterprise-core * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .enterprise-core .hexa-container {
    display: block;
    position: relative;
    width: 350px;
    height: 350px;
    top: 250px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: -webkit-transform 0.7s linear;
    transition: -webkit-transform 0.7s linear;
    transition: transform 0.7s linear;
    transition: transform 0.7s linear, -webkit-transform 0.7s linear;
    border-radius: 50%;
  }

  .enterprise-core .item {
    position: absolute;
    height: 67.64705%;
    width: 67.64705%;
    border-radius: 50%;
  }

  .enterprise-core .item-center {
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }

  .enterprise-core .item-center .hex {
    width: 100%;
    height: 100%;
    fill: #1476f2;
  }

  .enterprise-core .item-top {
    top: -61%;
    right: 16%;
  }

  .enterprise-core .item-right-top {
    top: -22%;
    right: -51%;
  }

  .enterprise-core .item-left-top {
    top: -22%;
    right: 83%;
  }

  .enterprise-core .item-right-bottom {
    top: 55%;
    right: -51%;
  }

  .enterprise-core .item-left-bottom {
    top: 55%;
    right: 83%;
  }

  .enterprise-core .item .item-list {
    position: absolute;
    top: 5%;
    list-style-type: none;
    pointer-events: none;
    margin: 0;
    padding: 0;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    opacity: 0;
  }

  .enterprise-core .item .item-list li {
    font-size: 14px;
    line-height: 1.43;
    color: #323232;
  }

  .enterprise-core .item .item-list li:before {
    content: "";
    display: inline-block;
    margin-left: -16px;
    margin-right: 12px;
    width: 4px;
    height: 4px;
    background-color: #1476f2;
  }

  .enterprise-core .item .item-list-top {
    width: 300px;
    top: 10%;
    left: 140%;
  }

  .enterprise-core .item .item-list-top-right {
    width: 130px;
    top: 15%;
    left: 140%;
  }

  .enterprise-core .item .item-list-bottom-right {
    width: 160px;
    top: 15%;
    left: 140%;
  }

  .enterprise-core .item .item-list-bottom-left,
  .enterprise-core .item .item-list-top-left {
    width: 170px;
    top: 15%;
    right: 125%;
  }

  .enterprise-core .item:hover > .hex-inner {
    width: 98%;
    height: 98%;
  }

  .enterprise-core .item:hover h3 a {
    color: #1476f2;
  }

  .enterprise-core .item:hover h3 a i {
    right: -24px;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    opacity: 1;
  }

  .enterprise-core .item:hover .item-list {
    opacity: 1;
  }

  .enterprise-core .item:hover .item-list-bottom-right,
  .enterprise-core .item:hover .item-list-top,
  .enterprise-core .item:hover .item-list-top-right {
    left: 120%;
  }

  .enterprise-core .item:hover .item-list-bottom-left,
  .enterprise-core .item:hover .item-list-top-left {
    right: 105%;
  }

  .enterprise-core .hex {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    fill: #fff;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
  }

  .enterprise-core .hex-outer {
    width: 100%;
    height: 100%;
  }

  .enterprise-core .hex-media {
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 38%;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
  }

  .enterprise-core .hex-media-center {
    width: 100%;
    height: 100%;
    background-size: 70%;
    background-image: url(./img/about-page/cube.png);
  }

  .enterprise-core .hex-media-center figcaption {
    width: 240px;
  }

  .enterprise-core .hex-media-center figcaption h3 {
    width: 240px;
    position: absolute;
    top: 150px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
    -webkit-transition: none;
    transition: none;
  }

  .enterprise-core .hex-media-center figcaption p {
    width: 240px;
    position: absolute;
    opacity: 0;
    bottom: -180px;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    text-align: center;
    color: #fff;
    pointer-events: none;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
  }

  .enterprise-core .hex-media-center:hover {
    background-image: none;
  }

  .enterprise-core .hex-media-center:hover figcaption h3 {
    top: 60px;
    font-size: 14px;
    opacity: 0;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
  }

  .enterprise-core .hex-media-center:hover figcaption p {
    bottom: 75px;
    opacity: 1;
  }

  .enterprise-core img {
    margin-bottom: 10px;
    width: 100%;
  }

  .enterprise-core h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.03;
    letter-spacing: normal;
    text-transform: none;
    text-align: center;
    color: #323232;
  }

  .enterprise-core h3 a {
    position: relative;
    color: #323232;
  }

  .enterprise-core h3 a i {
    position: absolute;
    width: 23px;
    top: 2px;
    right: -18px;
    font-size: 13px;
    font-weight: 600;
    color: #1476f2;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    opacity: 0;
  }
}

@media only screen and (max-width: 1024px) and (max-width: 1024px) {
  li {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1024px) {
  .hexa-container {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1024px) {
  li {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1024px) {
  -bottom-right,
  -top,
  -top-right {
    left: 132%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1024px) {
  -bottom-left,
  -top-left {
    right: 118%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1024px) {
  -bottom-right,
  -top,
  -top-right {
    left: 112%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1024px) {
  -bottom-left,
  -top-left {
    right: 88%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1024px) {
  h3 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1024px) {
  h3,
  p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1024px) {
  i {
    top: 3px;
    font-size: 11px;
  }
}

.about-news {
  background: #f9f9f9;
}

.our-work-wrapper {
  background-color: #fff;
}

.our-work {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 747px) {
  .our-work {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.our-work__item {
  padding: 8px 80px;
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 1023px) {
  .our-work__item {
    padding: 4px 40px;
  }
}

@media only screen and (max-width: 747px) {
  .our-work__item {
    width: 280px;
    padding: 2px 20px;
  }
}

.our-work__item ~ .our-work__item {
  border-left: 2px solid rgba(50, 50, 50, 0.1);
}

@media only screen and (max-width: 747px) {
  .our-work__item ~ .our-work__item {
    border: none;
    margin-top: 20px;
  }
}

.our-work__item h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #323232;
}

.our-work__item p {
  padding: 20px 0;
  font-size: 14px;
  line-height: 1.71;
  color: #323232;
}

@media only screen and (max-width: 747px) {
  .our-work__item p {
    padding: 8px 0;
  }
}

.our-work__item a {
  font-size: 13px;
  font-weight: 600;
  line-height: 2.31;
  color: #1476f2;
  text-transform: uppercase;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

.our-work__item a:hover {
  color: #005ed7;
}

.our-work__item a:hover i {
  margin-left: 12px;
}

.our-work__item a i {
  margin-left: 3px;
  font-size: 10px;
  font-weight: 600;
  -webkit-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
}

.map-scroll {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  /*background-image: url(./img/about-page/bg-about-map.jpg);*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.map-scroll .container {
  height: unset;
}

@media screen and (max-width: 868px) and (orientation: landscape) {
  .map-scroll {
    background-size: 150%;
    background-position: 0 0;
  }
}

@media screen and (max-width: 768px) {
  .map-scroll {
    background-size: 200%;
    background-position: 0 0;
  }
}

@media screen and (max-width: 400px) {
  .map-scroll {
    background-size: 300%;
    background-position: 0 0;
  }
}

/*.map-scroll .mission-vision {*/
/*  padding: 330px 0 55px;*/
/*}*/

/*@media screen and (max-width: 868px) and (orientation: landscape) {*/
/*  .map-scroll .mission-vision {*/
/*    padding: 260px 0 0;*/
/*  }*/
/*}*/

/*NEW*/
.map-scroll .mission-vision {
  padding: 110px 0 55px;
}

@media screen and (max-width: 868px) and (orientation: landscape) {
  .map-scroll .mission-vision {
    padding: 60px 0 0;
  }
}

.map-scroll .cemetery__title--center p {
  font-size: 18px;
}

.transportation__management {
  padding: 25px 0 15px;
}

.transportation__management .container {
  width: auto;
  padding: 0 0 0 4%;
}

@media screen and (max-width: 767px) {
  .transportation__management .container {
    padding: 0;
  }

  .transportation__management .container .phone__column:first-child {
    padding: 0 30px;
  }
}

.transportation__management .container .phone__title {
  padding-top: 25px;
  font-size: 32px;
}

.transportation__management .container .phone__lead {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .transportation__management .container .phone__lead {
    text-align: justify;
  }
}

.transportation__management .container .phone__list li {
  padding-right: 0;
}

.transportation__management .container .phone__image {
  background-position: 100% 0;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .transportation__management .container .phone__image {
    background-size: contain;
  }
}

@media screen and (max-width: 767px) {
  .transportation__management .container .phone__image {
    background-position: 100%;
  }
}

.transportation__management .container .list--text {
  font-size: 16px;
  line-height: 1.25;
}

.transportation__management .container .isoi-blockchain {
  margin-right: 30px;
}

.transportation__management .container .isoi-real-time-vehicle {
  margin-right: 15px;
}

.transportation__management .container .isoi-smart-warehousing {
  margin-right: 25px;
}

@media screen and (max-width: 767px) {
  .transportation__management .container .isoi-custom-iot {
    margin-right: 33px;
  }

  .transportation__management .container .isoi-smart-warehousing {
    margin-right: 35px;
  }
}

.transportation__data {
  padding: 75px 0 60px;
}

@media screen and (max-width: 767px) {
  .transportation__data {
    padding: 35px 0;
  }
}

.transportation__data .container {
  width: auto;
  padding: 0 4% 0 0;
}

@media screen and (max-width: 767px) {
  .transportation__data .container {
    padding: 0;
  }
}

.transportation__data .container .phone__column:first-child {
  padding: 0 0 0 30px;
}

@media screen and (max-width: 767px) {
  .transportation__data .container .phone__column:first-child {
    padding: 0 30px;
  }
}

.transportation__data .container .phone__title {
  padding-top: 15px;
  font-size: 32px;
}

.transportation__data .container .phone__lead {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .transportation__data .container .phone__lead {
    text-align: justify;
  }
}

.transportation__data .container .phone__list li {
  padding-right: 0;
}

.transportation__data .container .phone__column--height {
  min-height: 325px;
}

.transportation__data .container .phone__image {
  background-position: 0 0;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .transportation__data .container .phone__image {
    background-size: contain;
  }
}

@media screen and (max-width: 767px) {
  .transportation__data .container .phone__image {
    background-position: 0;
  }
}

.transportation__data .container .list--text {
  font-size: 16px;
  line-height: 1.25;
}

.transportation__data .container .isoi-artificial-intelligence {
  margin-right: 22px;
}

.transportation__data .container .isoi-higher-software-quality {
  margin-right: 18px;
}

.transportation__data .container .isoi-custom-reporting {
  margin-right: 12px;
}

@media screen and (max-width: 767px) {
  .transportation__connect {
    padding-top: 50px;
  }
}

.transportation__connect .phone__title {
  font-size: 32px;
}

.transportation__connect .phone__lead {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .transportation__connect .phone__lead {
    text-align: justify;
  }
}

.transportation__connect .phone__list li {
  padding-right: 0;
}

.transportation__connect .list--text {
  font-size: 16px;
  line-height: 1.25;
}

.transportation__connect .phone__column--height {
  min-height: 410px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .transportation__connect .phone__image {
    background-size: contain;
  }
}

.transportation__connect .isoi-apple-carplay {
  margin-right: 24px;
}

.transportation__connect .isoi-api,
.transportation__connect .isoi-iot-chip {
  margin-right: 24px;
}

@media screen and (max-width: 767px) {
  .transportation__connect .isoi-ford-sync {
    margin-right: 15px;
  }

  .transportation__connect .isoi-api,
  .transportation__connect .isoi-iot-chip {
    margin-right: 20px;
  }
}

.solutions-page__title {
  color: #323232;
  text-transform: uppercase;
  text-align: center;
  max-width: 615px;
  margin: 0 auto 30px;
}

.solutions-page__descr {
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: #555;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 70px;
}

@media screen and (max-width: 1023px) {
  .solutions-page__descr {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .solutions-page__descr {
    font-size: 14px;
  }
}

.tech-solution {
  background-color: #fafafa;
  padding: 80px 0 60px;
}

.tech-solution .tech {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 1200px) {
  .tech-solution .tech {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .tech-solution .tech {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.tech-solution .tech__item {
  position: relative;
  height: 320px;
  background-color: #fff;
  margin: 0 3px 3px 0;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.tech-solution .tech__item:first-child {
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.tech-solution .tech__item:nth-child(5) {
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}

@media screen and (max-width: 1200px) {
  .tech-solution .tech__item:first-child {
    border-bottom-left-radius: 0;
  }

  .tech-solution .tech__item:nth-child(3) {
    border-top-right-radius: 7px;
  }

  .tech-solution .tech__item:nth-child(4) {
    border-bottom-left-radius: 7px;
  }

  .tech-solution .tech__item:nth-child(5) {
    border-top-right-radius: 0;
  }
}

@media screen and (max-width: 767px) {
  .tech-solution .tech__item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 7px;
  }

  .tech-solution .tech__item:nth-child(5) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 7px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .tech-solution .tech__item--iot {
    -ms-grid-column: 1;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.tech-solution .tech__item:hover {
  background-color: rgba(20, 118, 242, 0.8);
}

.tech-solution .tech__item:hover .tech__img-wrapper,
.tech-solution .tech__item:hover .tech__title {
  opacity: 0;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-animation: shrink 0.35s ease-in;
  animation: shrink 0.35s ease-in;
}

.tech-solution .tech__item:hover .tech__descr-wrapper {
  display: block;
}

.tech-solution .tech__img-wrapper {
  width: 90px;
  height: 85px;
  margin: 65px auto;
}

.tech-solution .tech__img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.tech-solution .tech__title {
  text-align: center;
  padding: 0 31px 45px;
  font-size: 16px;
  font-weight: 600;
  color: #323232;
}

.tech-solution .tech__descr-wrapper {
  display: none;
  width: 100%;
  height: 100%;
}

.tech-solution .tech__link {
  display: block;
  height: 100%;
  padding: 30px 40px;
  position: absolute;
  visibility: visible;
  bottom: 0;
  opacity: 1;
  -webkit-animation: grow 0.5s ease;
  animation: grow 0.5s ease;
}

.tech-solution .tech__link-title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.tech-solution .tech__text {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  margin: 10px 0 12px;
}

.tech-solution .tech__more {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
}

.tech-solution .tech__more i {
  margin-left: 5px;
  -webkit-animation: marginLeft 0.3s ease 0.35s;
  animation: marginLeft 0.3s ease 0.35s;
}

@-webkit-keyframes marginLeft {
  0% {
    margin-left: 0;
  }
  to {
    margin-left: 5px;
  }
}

@keyframes marginLeft {
  0% {
    margin-left: 0;
  }
  to {
    margin-left: 5px;
  }
}

@-webkit-keyframes shrink {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

@keyframes shrink {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

@-webkit-keyframes grow {
  0% {
    visibility: hidden;
    bottom: -100%;
    opacity: 0;
  }
  50% {
    visibility: visible;
    bottom: -30%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes grow {
  0% {
    visibility: hidden;
    bottom: -100%;
    opacity: 0;
  }
  50% {
    visibility: visible;
    bottom: -30%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.stack-block {
  background-color: #fff;
  padding: 90px 0 110px;
}

@media screen and (max-width: 767px) {
  .stack-block {
    padding-bottom: 0;
  }
}

.stack-block__wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-column-align: center;
  justify-items: center;
}

@media screen and (max-width: 767px) {
  .stack-block__wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.stack-block__wrapper .stack {
  border-top: 1px solid rgba(50, 50, 50, 0.1);
  padding: 25px 0 50px;
  max-width: 420px;
}

.stack-block__wrapper .stack__title {
  text-transform: uppercase;
  padding-bottom: 40px;
  max-width: 310px;
}

.stack-block__wrapper .stack__title--web {
  padding-bottom: 77px;
}

@media screen and (max-width: 767px) {
  .stack-block__wrapper .stack__title--web {
    padding-bottom: 40px;
  }
}

.stack-block__wrapper .stack__item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 75px 65%;
  grid-template-columns: 75px 65%;
  grid-column-gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.stack-block__wrapper .stack__item:last-child {
  margin-bottom: 0;
}

.stack-block__wrapper .stack__img-wrapper {
  width: 75px;
  height: 60px;
}

.stack-block__wrapper .stack__img {
  max-width: 100%;
  max-height: 100%;
}

.stack-block__wrapper .stack__name {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
}

.stack-block + .cemetery {
  background-color: #fafafa;
}

.video-section {
  height: 100vh;
  padding: 0;
}

.video-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
  min-height: 350px;
}

.video-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.video-wrapper .video-bg {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: auto;
  height: auto;
  background-size: cover;
}

.about-header {
  position: absolute;
  padding: 0 25px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.services-ios-app-development-services #contact-section,
.services-ios-app-development-services .title__preheader {
  display: none;
}

.services-ios-app-development-services .technology {
  padding: 80px 0 40px;
}

@media screen and (max-width: 767px) {
  .services-ios-app-development-services .technology {
    padding: 40px 0 0;
  }
}

.services-ios-app-development-services .technology .macintosh {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  -ms-grid-column-align: center;
  justify-items: center;
  text-align: center;
  padding: 50px 0 90px;
}

@media screen and (max-width: 1023px) {
  .services-ios-app-development-services .technology .macintosh {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 100px;
  }
}

@media screen and (max-width: 480px) {
  .services-ios-app-development-services .technology .macintosh {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.services-ios-app-development-services .technology .macintosh__device {
  position: relative;
}

.services-ios-app-development-services
.technology
.macintosh__device:hover
.macintosh__img {
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
}

.services-ios-app-development-services
.technology
.macintosh__device:hover
.macintosh__name {
  color: #fff;
  background-color: #1476f2;
}

.services-ios-app-development-services .technology .macintosh__img-wrapper {
  max-width: 265px;
  max-height: 230px;
  overflow: hidden;
}

.services-ios-app-development-services .technology .macintosh__img {
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 0.5s ease-out;
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}

.services-ios-app-development-services .technology .macintosh__name {
  position: absolute;
  left: 50%;
  bottom: -30%;
  font-size: 18px;
  line-height: 1.33;
  color: #323232;
  background-color: #fff;
  min-width: 210px;
  padding: 50px 20px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.06);
}

@media screen and (max-width: 1023px) {
  .services-ios-app-development-services .technology .macintosh__name {
    min-width: 190px;
    padding: 40px 0;
  }
}

.services-ios-app-development-services .development {
  padding: 80px 0 100px;
}

@media screen and (max-width: 767px) {
  .services-ios-app-development-services .development {
    padding: 40px 0;
  }
}

.services-ios-app-development-services .development .stages {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-column-align: center;
  justify-items: center;
  padding-top: 50px;
}

@media screen and (max-width: 767px) {
  .services-ios-app-development-services .development .stages {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }
}

.services-ios-app-development-services .development .stages__item {
  position: relative;
}

.services-ios-app-development-services .development .stages__item:before {
  content: "";
  position: absolute;
  top: 60px;
  left: 180px;
  width: 110%;
  height: 1px;
  background-color: rgba(50, 50, 50, 0.22);
  z-index: -1;
  -webkit-transition: background-color 1s ease-in-out;
  transition: background-color 1s ease-in-out;
}

.services-ios-app-development-services
.development
.stages__item:last-child:before {
  content: none;
}

@media screen and (max-width: 1023px) {
  .services-ios-app-development-services .development .stages__item:before {
    width: 90%;
    left: 175px;
  }
}

@media screen and (max-width: 767px) {
  .services-ios-app-development-services .development .stages__item:before {
    content: none;
  }
}

.services-ios-app-development-services
.development
.stages__item--active
.stages__img-wrapper
svg {
  opacity: 1;
}

.services-ios-app-development-services
.development
.stages__item--active
.stages__list {
  opacity: 1;
  height: auto;
}

.services-ios-app-development-services
.development
.stages__item--active:before,
.services-ios-app-development-services
.development
.stages__item--active:first-child:before {
  background-color: #1476f2;
}

.services-ios-app-development-services
.development
.stages__item--activeList
.stages__list {
  opacity: 1;
  height: auto;
}

.services-ios-app-development-services
.development
.stages__item--activeBorder:before,
.services-ios-app-development-services
.development
.stages__item--activeBorder:first-child:before {
  background-color: #1476f2;
}

.services-ios-app-development-services .development .stages__img-wrapper {
  border-radius: 60px;
  background-color: #fff;
  margin: 0 auto;
  padding: 25px 0;
  width: 120px;
  height: 120px;
  -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.06);
}

.services-ios-app-development-services .development .stages__img-wrapper svg {
  width: 100%;
  margin: 0 auto;
  opacity: 0;
  -webkit-transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.services-ios-app-development-services .development .stages__title {
  text-transform: uppercase;
  text-align: center;
  padding: 25px 0 40px;
  color: #1476f2;
  font-size: 18px;
  font-weight: 400;
}

.services-ios-app-development-services .development .stages__list {
  color: #323232;
  opacity: 0;
  height: 0;
  -webkit-transition: opacity 0.7s ease-in-out;
  transition: opacity 0.7s ease-in-out;
}

.services-ios-app-development-services .development .stages__list-item {
  position: relative;
  font-size: 16px;
  line-height: 1.25;
  padding: 0 20px 20px;
}

.services-ios-app-development-services
.development
.stages__list-item:last-child {
  padding-bottom: 0;
}

.services-ios-app-development-services .development .stages__list-item:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: #1476f2;
}

.services-ios-app-development-services .technology-stack {
  background-color: #fff;
  padding: 120px 0 70px;
}

@media screen and (max-width: 767px) {
  .services-ios-app-development-services .technology-stack {
    padding: 40px 0;
  }
}

.services-ios-app-development-services .technology-stack .tech {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px;
  text-align: center;
  max-width: 930px;
  margin: 0 auto;
  padding-top: 50px;
}

@media screen and (max-width: 767px) {
  .services-ios-app-development-services .technology-stack .tech {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .services-ios-app-development-services
  .technology-stack
  .tech
  .stack:nth-child(3) {
    border-bottom: none;
  }
}

.services-ios-app-development-services .technology-stack .stack {
  cursor: pointer;
  border-bottom: 2px solid rgba(50, 50, 50, 0.1);
}

.services-ios-app-development-services .technology-stack .stack:first-child {
  border-top: 2px solid rgba(50, 50, 50, 0.1);
}

.services-ios-app-development-services
.technology-stack
.stack--active
.stack__title,
.services-ios-app-development-services
.technology-stack
.stack:hover
.stack__title {
  color: #1476f2;
}

.services-ios-app-development-services
.technology-stack
.stack--active
.stack__title:after {
  top: 50%;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.services-ios-app-development-services .technology-stack .stack__title {
  position: relative;
  padding: 40px 20px 40px 0;
  text-align: left;
  color: #323232;
  font-size: 16px;
  line-height: 1.25;
  text-transform: uppercase;
}

.services-ios-app-development-services .technology-stack .stack__title:after {
  content: "";
  opacity: 0.6;
  position: absolute;
  top: 40%;
  right: 0;
  border: solid;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
}

.services-ios-app-development-services .technology-stack .stack__item-wrapper {
  display: none;
}

.services-ios-app-development-services .technology-stack .stack__item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 77px 65%;
  grid-template-columns: 77px 65%;
  grid-column-gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-column-align: left;
  justify-items: left;
  cursor: auto;
  padding-bottom: 20px;
  text-align: left;
}

.services-ios-app-development-services
.technology-stack
.stack__item:last-child {
  padding-bottom: 50px;
}

.services-ios-app-development-services .technology-stack .stack__name {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #323232;
}

.services-ios-app-development-services .faq {
  background-color: #fff;
  padding: 80px 0 100px;
}

@media screen and (max-width: 767px) {
  .services-ios-app-development-services .faq {
    padding: 40px 0;
  }
}

.services-ios-app-development-services .faq__wrapper {
  max-width: 740px;
  margin: 0 auto;
  padding: 50px 0 0;
}

.services-ios-app-development-services .faq__item {
  cursor: pointer;
}

.services-ios-app-development-services .faq__item:first-child .faq__title {
  border-top: 2px solid rgba(50, 50, 50, 0.1);
}

.services-ios-app-development-services .faq__item:hover .faq__title {
  color: #1476f2;
}

.services-ios-app-development-services .faq__item--active .faq__title {
  border-bottom: none;
  color: #1476f2;
}

.services-ios-app-development-services .faq__item--active .faq__title:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.services-ios-app-development-services .faq__title {
  position: relative;
  color: #323232;
  font-size: 16px;
  line-height: 1.5;
  text-transform: none;
  padding: 20px 20px 20px 0;
  border-bottom: 2px solid rgba(50, 50, 50, 0.1);
  margin: 0;
}

.services-ios-app-development-services .faq__title:after {
  content: "";
  opacity: 0.6;
  position: absolute;
  top: 40%;
  right: 0;
  border: solid;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
}

.services-ios-app-development-services .faq__text {
  display: none;
  cursor: text;
  color: #323232;
  margin: 0;
  padding: 15px 0;
  border-bottom: 2px solid rgba(50, 50, 50, 0.1);
}

@media screen and (max-width: 767px) {
  .services-ios-app-development-services .faq__text {
    padding: 5px 0;
  }
}

article.clients {
  float: left;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 203px;
  height: 150px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid #4d4d4d;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

article.clients * {
  vertical-align: middle;
}

article.clients span {
  display: inline-block;
  height: 100%;
  width: 1px;
}
