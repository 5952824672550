:root {
    --alternate-color-lighter: #1e90ff;
    --alternate-color: rgba(0, 114, 246, 0.8);
    --alternate-color-darker: #0157AC;
}

.tecsla-navbar {
    display: flex;
    flex-direction: column;
}

.tecsla-navbar .tecsla-navbar-brand-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tecsla-navbar .tecsla-navbar-brand-container .tecsla-navbar-brand {
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
    cursor: pointer;
    width: auto;
}

.tecsla-navbar .tecsla-navbar-brand-container .tecsla-navbar-brand .tecsla-navbar-logo {
    width: 133.8px;
    height: 40px;
}

.tecsla-navbar .tecsla-navbar-brand-container .tecsla-menu-toggle-btn {
    background: var(--alternate-color);
    border: 0;
    padding: 7px;
}

.tecsla-navbar .tecsla-navbar-brand-container .tecsla-menu-toggle-btn:hover {
    background: var(--alternate-color-darker);
}

.tecsla-navbar .tecsla-nav__list-wrapper {
    display: none;
}

.tecsla-navbar .tecsla-nav__list {
    display: flex;
    flex-direction: column;
}

.tecsla-navbar .tecsla-nav__list li.tecsla-menu-item {
    display: inline-block;
    line-height: 75px;
}


.tecsla-navbar .tecsla-nav__list li.tecsla-menu-item * {
    color: #000;
    text-decoration: none;
    font-size: 12px;
    line-height: 45px;
    padding: 0 20px;
    text-transform: uppercase;
    font-weight: 700;
    transition: all .2s;
    vertical-align: middle;
    cursor: pointer;
}

.tecsla-navbar .tecsla-nav__list li.tecsla-menu-item *:hover {
    color: var(--alternate-color);
}

.tecsla-navbar .tecsla-nav__list li.tecsla-menu-item *.btn {
    background: var(--alternate-color);
    /*border-radius: 7px;*/
    color: #fff;
    text-align: center;
}
.tecsla-navbar .tecsla-nav__list li.tecsla-menu-item *.btn:hover {
    background: var(--alternate-color-darker);
}

@media (min-width: 1200px) {
    .tecsla-navbar {
        flex-direction: row;
    }

    .tecsla-navbar .tecsla-navbar-brand-container {
        /*display: inline-block;*/
    }
    /*.tecsla-navbar-brand-container */
    .tecsla-navbar .tecsla-menu-toggle-btn {
        display: none;
    }
    .tecsla-navbar .tecsla-nav__list-wrapper {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
    .tecsla-navbar .tecsla-nav__list {
        display: flex;
        flex-direction: row;
    }
    .tecsla-navbar li.tecsla-menu-item {
        display: block;
    }

}


/* nav-icon-5 */
.nav-icon-5{
    width: 35px;
    height: 30px;
    /*margin: 10px 10px;*/
    position: relative;
    cursor: pointer;
    display: inline-block;
}
.nav-icon-5 span{
    background-color:#fff;
    position: absolute;
    border-radius: 2px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    width:100%;
    height: 4px;
    transition-duration: 500ms
}
.nav-icon-5 span:nth-child(1){
    top:0px;
    left: 0px;
}
.nav-icon-5 span:nth-child(2){
    top:13px;
    left: 0px;
    opacity:1;
}
.nav-icon-5 span:nth-child(3){
    bottom:0px;
    left: 0px;
}
.nav-icon-5:not(.open):hover span:nth-child(1){
    transform: rotate(-3deg) scaleY(1.1);
}
.nav-icon-5:not(.open):hover span:nth-child(2){
    transform: rotate(3deg) scaleY(1.1);
}
.nav-icon-5:not(.open):hover span:nth-child(3){
    transform: rotate(-4deg) scaleY(1.1);
}
.nav-icon-5.open span:nth-child(1){
    transform: rotate(45deg);
    top: 13px;
}
.nav-icon-5.open span:nth-child(2){
    opacity:0;
}
.nav-icon-5.open span:nth-child(3){
    transform: rotate(-45deg);
    top: 13px;
}