body {
  font-family: Helvetica, Arial, Verdana; }

.tabs-container {
  display: block;
  margin-bottom: 20px;
  line-height: 21px;
  color: #999; }
.tabs-container .wrapper {
  /*overflow: hidden;
   contain the panel to the size of the nav */ }
.tabs-container .wrapper.content-wrap {
  display: table; }
.tabs-container .wrapper.content-wrap .tab-nav {
  white-space: nowrap; }
.tabs-container .wrapper.content-wrap .tab-nav .tab-nav-item {
  display: inline-table; }
.tabs-container .wrapper.content-wrap > .tab-panel {
  display: table-caption;
  caption-side: bottom; }
.tabs-container .wrapper.bottom-nav .tab-nav {
  caption-side: bottom; }
.tabs-container .wrapper.bottom-nav .tab-panel {
  caption-side: top; }
.tabs-container .tab-nav {
  position: relative;
  top: 1px;
  z-index: 1; }
.tabs-container .tab-nav-item {
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: none;
  border-left-color: transparent;
  padding: 8px 12px; }
.tabs-container .tab-nav-item.active {
  border-radius: 5px 5px 0 0;
  background: white;
  border: 1px solid #BBB;
  border-bottom: 1px solid transparent;
  color: #333; }
.tabs-container .tab-panel {
  position: relative;
  border-top: 1px solid #BBB;
  z-index: 0; }
.tabs-container .bottom-nav .tab-nav-item.active {
  border-radius: 0 0 5px 5px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #BBB; }
.tabs-container .bottom-nav .tab-panel {
  border-top: none;
  border-bottom: 1px solid #BBB; }
.tabs-container .bottom-nav .tab-nav {
  top: -1px; }
.tabs-container .tab-panel-item {
  display: none;
  overflow: hidden;
  padding: 10px; }
.tabs-container .tab-panel-item.active {
  display: block; }
.tabs-container.fade .tab-panel-item {
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  opacity: 0;
  display: block;
  position: absolute; }
.tabs-container.fade.fadeOut .tab-panel-item.active {
  position: relative;
  opacity: 0; }
.tabs-container.fade.fadeIn .tab-panel-item.active {
  position: relative;
  opacity: 1; }
.tabs-container .img-left {
  float: left;
  padding: 0 12px 0px 0; }
.tabs-container p {
  margin-top: 0;
  font-size: 13px; }
.tabs-container .tab-icon {
  width: 20px;
  height: 15px;
  position: relative;
  top: 4px;
  left: -2px; }

/* Theme folder */
.tabs-container.theme-folder > .wrapper > .tab-nav .tab-nav-item {
  background: none;
  color: black;
  opacity: 0.5; }
.tabs-container.theme-folder > .wrapper > .tab-nav .tab-nav-item.active {
  opacity: 1;
  border: none;
  background-color: #AAA;
  border-radius: 5px 5px 0 0; }
.tabs-container.theme-folder > .wrapper > .tab-nav .tab-nav-item.active .tab-icon {
  display: inline-block; }
.tabs-container.theme-folder > .wrapper > .tab-nav .tab-nav-item .tab-icon {
  display: none; }

.tabs-container.theme-folder > .wrapper > .tab-panel {
  color: black;
  border: none;
  background: none; }
.tabs-container.theme-folder > .wrapper > .tab-panel .tab-panel-item.active {
  background: #AAA;
  border-radius: 0 0 5px 5px; }

.tabs-container.theme-folder > .wrapper.bottom-nav > .tab-nav {
  top: 0; }
.tabs-container.theme-folder > .wrapper.bottom-nav > .tab-nav .tab-nav-item.active {
  border-radius: 0 0 5px 5px; }

.tabs-container.theme-folder > .wrapper.bottom-nav > .tab-panel .tab-panel-item.active {
  border-radius: 5px 5px 0 0; }

/* Theme box */
.tabs-container.theme-box > .wrapper > .tab-nav .tab-nav-item {
  border: 1px solid #BBB;
  border-bottom: none;
  border-left-color: transparent;
  border-radius: 0;
  background: #CCC;
  color: white;
  position: relative;
  top: 1px; }
.tabs-container.theme-box > .wrapper > .tab-nav .tab-nav-item.active {
  background-color: #FFFFFF;
  color: #333;
  border-bottom: 1px solid white;
  border-top: 3px solid red;
  border-left-color: #BBB; }
.tabs-container.theme-box > .wrapper > .tab-nav .tab-nav-item:first-child {
  border-left: 1px solid #BBB; }

.tabs-container.theme-box > .wrapper > .tab-panel {
  background: #FFFFFF;
  border: 1px solid #BBB;
  border-top: none; }

/* Theme two rows */
.tabs-container.two-rows > .wrapper > .tab-nav .tab-nav-item {
  line-height: 15px; }
.tabs-container.two-rows > .wrapper > .tab-nav .tab-nav-item sub {
  display: block;
  font-size: 75%; }
